var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenu"},[_c('div',{staticClass:"bloc-legend",attrs:{"id":"info"}},[(this.type === 'accueil')?_c('ul',{staticClass:"legend"},[_c('li',[_vm._v("Légende :")]),_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(this.type === 'affecter')?_c('ul',{staticClass:"legend"},[_c('li',[_vm._v("Légende :")]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e(),(this.type === 'transferer')?_c('ul',{staticClass:"legend"},[_c('li',[_vm._v("Légende :")]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)]):_vm._e()]),_c('div',{staticClass:"map",attrs:{"id":"mapcaau"}}),_c('div',{staticClass:"ol-popup",attrs:{"id":"popup"}},[_c('div',{staticClass:"popup-detail",attrs:{"id":"popup-content"}},[(_vm.infoCommune.properties)?_c('div',[_c('h1',{staticClass:"titre-info"},[_vm._v(" "+_vm._s(_vm.infoCommune.properties.nom_commune)),_c('br'),_vm._v(" "+_vm._s(_vm.infoCommune.properties.codeInsee)),_c('br')]),_c('span',{staticClass:"categorie-info"},[_vm._v("Dépt.")]),_c('span',{staticClass:"detail-info"},[_vm._v(_vm._s(_vm.infoCommune.properties.nom_departement))]),_c('br'),_c('span',{staticClass:"categorie-info"},[_vm._v("Centre")]),_c('span',{staticClass:"detail-info"},[_vm._v(_vm._s(_vm.infoCommune.properties.identifiant_caau))])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"red"}),_vm._v("Communes affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"grey"}),_vm._v("Communes non affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"black"}),_vm._v("Emplacement du CAAU")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"red"}),_vm._v("Communes affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"blue"}),_vm._v("Nouvelles communes affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"grey"}),_vm._v("Communes non affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"black"}),_vm._v("Emplacement du CAAU")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"red"}),_vm._v("Communes affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"blue"}),_vm._v("Communes transféres")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"grey"}),_vm._v("Communes non affectées")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"black"}),_vm._v("Emplacement du CAAU")])
}]

export { render, staticRenderFns }
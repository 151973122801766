<template>
    <div class="referenceModule">
        <div class="titrePage" v-html="this.texte"></div>
    </div>
</template>

<script>
export default {
    name: 'Reference',
    data() {
        return {}
    },
    props: {
        texte: {
            type: String,
        },
    },
}
</script>

<style scoped>
.referenceModule {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 32px;
}

.titrePage {
    display: inline-flex;
    justify-content: left;
    font-family: 'Marianne Medium';
    font-size: inherit;
    width: auto;
    height: 40px;
    text-transform: uppercase;
}
</style>

<template>
    <div>
        <button
            class="bouton no-border"
            @click="goto()"
            :class="{ pointer: this.enable }"
            :style="btnStyle"
            v-if="this.smallButton">
            <img
                :src="require(`../../../assets/img/tnaau/${this.logo}`)"
                class="tailleIcone_20px margin-right-10-px" />
            {{ this.text }}
        </button>

        <button
            class="bouton big no-border"
            @click="goto()"
            :class="{ pointer: this.enable }"
            :style="btnStyle"
            v-if="!this.smallButton">
            <img
                :src="require(`../../../assets/img/tnaau/${this.logo}`)"
                class="tailleIcone_20px margin-right-10-px" />
            {{ this.text }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'ButtonWidget',
    data() {
        return {
            img: 'Icon_carto',
        }
    },
    props: {
        smallButton: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        logo: {
            type: String,
            default: '',
        },
        bgcolor: {
            type: String,
            default: '#00FF00',
        },
        txtcolor: {
            type: String,
            default: '#FFFFFF',
        },
        enable: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        goto() {
            if (this.url !== '') {
                window.location.href = this.url
            }
        },
    },
    computed: {
        btnStyle() {
            if (this.enable) {
                return {
                    'background-color': this.bgcolor,
                    color: this.txtcolor,
                }
            } else {
                return {
                    'background-color':
                        this.bgcolor === 'transparent'
                            ? 'transparent'
                            : '#B0AFDE',
                    opacity: this.bgcolor === 'transparent' ? '0.4' : '1',
                    color: this.txtcolor,
                }
            }
        },
        btnTxt() {
            return {
                color: this.txtcolor,
            }
        },
    },
}
</script>

<style scoped>
.big {
    min-height: 64px;
    font-family: 'Marianne Medium';
    font-size: 14px;
}
</style>

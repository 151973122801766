<template>
    <div class="container">
        <TitreReference :reference="'Nouveau'" :texte="'CAAU'" />
        <div class="titreCaauCreation uppercase margin-top-20-px">
            Informations
        </div>
        <div class="padding-top-10-bottom-30 font-label-input">
            Département
            <label id="nomDepartementSelection" class="texte_bleu" />
        </div>
        <div class="flex flex-wrap gap-1">
            <div>
                <div class="font-label-input padding-bottom-10-px">
                    N° d'urgence
                </div>
                <SelectForm
                    :nomVarSelect="'selectNumeroUrgence'"
                    :listeAffiche="this.listNumerosUrgence"
                    :width="90"
                    ref="selectNumUrgence" />
            </div>
            <div>
                <div class="font-label-input flex padding-bottom-10-px">
                    N° long
                    <help-widget
                        class="help-widget"
                        :titre="'Format des numéros de téléphone'"
                        :texte="
                            'France : +33000000000 <br /> ' +
                            'SMS : 114 <br /> ' +
                            'DROM : +000000000000 <br /> ' +
                            'Saint-Pierre-et-Miquelon : +508000000 <br /> ' +
                            'Monaco : +37700000000 <br /> '
                        " />
                </div>
                <input
                    class="inputForm inputTel"
                    type="text"
                    placeholder="Ex : +33155667788"
                    v-model="numeroLongActuel"
                    ref="numeroLongActuel" />
            </div>
            <div>
                <div class="font-label-input flex padding-bottom-10-px">
                    N° long alternatif
                    <help-widget
                        class="help-widget"
                        :titre="'Format des numéros de téléphone'"
                        :texte="
                            'France : +33000000000 <br /> ' +
                            'SMS : 114 <br /> ' +
                            'DROM : +000000000000 <br /> ' +
                            'Saint-Pierre-et-Miquelon : +508000000 <br /> ' +
                            'Monaco : +377000000000 <br /> '
                        " />
                </div>
                <input
                    class="inputForm inputTel"
                    type="text"
                    placeholder="Ex : +33155667788"
                    v-model="numeroCommunicable"
                    ref="numeroCommunicable" />
            </div>
            <div>
                <div class="font-label-input padding-bottom-10-px">
                    Type de CAAU
                </div>
                <SelectForm
                    :nomVarSelect="'idTypeCaau'"
                    :listeAffiche="this.listTypesCaau"
                    :width="200"
                    ref="selectTypeCaau" />
            </div>
            <div>
                <div class="font-label-input padding-bottom-20-px">
                    Identifiant CAAU
                </div>
                <label class="texte_bleu font-label-input">{{
                    this.identifiantCaau
                }}</label>
            </div>
        </div>
        <div id="divCentreNational" class="flex margin-top-10-px">
            <div class="font-label-input margin-top-10-px">Centre National</div>
            <switch-button
                :color="'#00ACA8'"
                :callback-id="'centreNational'"
                :state="this.centreNational"
                class="switchButtonCaau"
                ref="isCentreNational" />
        </div>
        <div class="font-label-input informations-commentaire">Commentaire</div>
        <textarea
            class="textAreaForm margin-bottom-20-px"
            type="text"
            placeholder="Entrez votre commentaire ici"
            v-model="commentaire"
            ref="commentaire"
            cols="20"
            rows="5" />
        <div class="titreCaauCreation uppercase margin-top-20-px">Adresse</div>
        <div class="flex flex-wrap gap-1">
            <div>
                <div class="font-label-input">Numéro et nom de la voie</div>
                <input
                    class="inputForm margin-bottom-20-px"
                    type="text"
                    placeholder="24 Nom de la voie"
                    v-model="adresseNomVoie"
                    ref="adresseNomVoie" />
            </div>
            <div>
                <div class="font-label-input">CP</div>
                <input
                    class="inputForm adresse-cp"
                    type="text"
                    placeholder="CP"
                    v-model="adresseCp"
                    ref="adresseCp" />
            </div>
            <div>
                <div class="font-label-input">Ville</div>
                <autocomplete
                    class="inputSearch background_gris_clair"
                    :items="this.listeCommunes"
                    @item-clicked="selectionCom"
                    item-text="Ville"
                    item-value="idCommune"
                    v-model="adresseCommune"
                    :input-attrs="{
                        autofocus: false,
                        placeholder: 'Ville',
                    }"
                    @change="checkCommune"
                    :component-item="template"
                    :auto-select-one-item="false"
                    ref="adresseCommune" />
            </div>
        </div>

        <div class="titreCaauCreation uppercase margin-top-20-px">
            Contact local
        </div>
        <div class="flex flex-wrap gap-1">
            <div>
                <div class="font-label-input">Fonction</div>
                <input
                    class="inputForm margin-bottom-20-px"
                    type="text"
                    placeholder="Fonction"
                    v-model="contactFonction"
                    ref="contactFonction" />
            </div>
            <div>
                <div class="font-label-input">Email</div>
                <input
                    class="inputForm margin-bottom-20-px"
                    type="text"
                    placeholder="nom@mail.com"
                    v-model="contactEmail"
                    ref="contactEmail" />
            </div>
            <div>
                <div class="font-label-input flex padding-bottom-10-px">
                    Téléphone
                    <help-widget
                        class="help-widget"
                        :titre="'Format des numéros de téléphone'"
                        :texte="
                            'France : +33000000000 <br /> ' +
                            'SMS : 114 <br /> ' +
                            'DROM : +000000000000 <br /> ' +
                            'Saint-Pierre-et-Miquelon : +000000000 <br /> ' +
                            'Monaco : +377000000000 <br /> '
                        " />
                </div>
                <input
                    class="inputForm inputTel margin-bottom-20-px"
                    type="text"
                    placeholder="Ex : +33155667788"
                    v-model="contactTelephone"
                    ref="contactTelephone" />
            </div>
        </div>
        <div class="flex padding-top-35-px">
            <button
                class="
                    bouton
                    background_page
                    texte_bleu_fonce
                    no-border
                    margin-bottom-10-px margin-left-30-px
                "
                @click="goto('/#/')">
                Annuler
            </button>
            <button
                class="
                    bouton
                    boutonValidation
                    background_bleu_tres_fonce
                    texte_blanc
                    no-border
                    margin-left-20-px
                "
                @click="creationCaau">
                <img
                    src="../../assets/img/tnaau/Icon_check_mark.svg"
                    class="margin-right-10-px" />
                Valider la création
            </button>
        </div>
    </div>
</template>

<script>
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import axios from 'axios'
import * as eventTypes from '@/bus/event-types'
import EventBus from '@/bus'
import {
    emailCheck,
    codePostalCheck,
    checkNumeroTel,
    checkInput,
    notNullCheck,
    isNotHtml,
} from '../../tools/inputCheck'
import * as Roles from '@/tools/Roles'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import SelectForm from '@/components/widgets/selectForm/SelectForm'
import { notificationLogout } from '@/tools/authent'
import ItemTemplate from '@/components/widgets/template/CommuneTemplate.vue'
import Autocomplete from 'v-autocomplete'
import roles from '@/tools/Roles'

export default {
    name: 'Caau',
    components: {
        HelpWidget,
        TitreReference,
        SwitchButton,
        SelectForm,
        Autocomplete,
    },
    data() {
        return {
            listeCommunes: [],
            listNumerosUrgence: [],
            listTypesCaau: [],
            identifiantCaau: '',
            numeroUrgence: '',
            numeroLongActuel: '',
            numeroCommunicable: '',
            idTypeCaau: '',
            centreNational: false,
            commentaire: '',
            adresseNomVoie: '',
            adresseCp: '',
            adresseCommune: null,
            adresseCommuneId: '',
            contactFonction: '',
            contactEmail: '',
            contactTelephone: '',
            template: ItemTemplate,
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        listeTypeCaau() {
            // On récupère la liste des types de CAAU
            axios
                .get('/typecaau?idNumUrgence=' + this.numeroUrgence, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    this.listTypesCaau = []
                    if (response.status === 200) {
                        if (response.data !== null) {
                            // On indique le premier élément comme étant sélectionné
                            response.data.data.forEach((element) => {
                                this.listTypesCaau.push({
                                    nom: element.code,
                                    id: element.idTypeCaau,
                                })
                                if (
                                    element.code === 'ORLY' &&
                                    this.$store.state.dptSelect.codeInsee !==
                                        '94'
                                ) {
                                    this.listTypesCaau.splice(
                                        this.listTypesCaau.indexOf('ORLY'),
                                        1
                                    )
                                }
                                if (
                                    element.code === 'BSPP' &&
                                    this.$store.state.dptSelect.codeInsee !==
                                        '75' &&
                                    this.$store.state.dptSelect.codeInsee !==
                                        '92' &&
                                    this.$store.state.dptSelect.codeInsee !==
                                        '93' &&
                                    this.$store.state.dptSelect.codeInsee !==
                                        '94'
                                ) {
                                    this.listTypesCaau.splice(
                                        this.listTypesCaau.indexOf('BSPP'),
                                        1
                                    )
                                }
                            })
                            this.idTypeCaau = this.listTypesCaau[0].id
                            this.$refs.selectTypeCaau.vmodel = this.idTypeCaau
                        }

                        // On met à jour l'identifiant du caau
                        if (
                            this.$store.state.dptSelect != null &&
                            this.$store.state.dptSelect.idDepartement != null
                        ) {
                            this.creationIdentifiantCaau()
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: 'Un problème est survenu lors de la récupération des types caau',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer la liste des types caau",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        listeNumeroUrgence() {
            // On récupère la liste des numéros d'urgence
            axios
                .get('/numerourgence/0', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            // On indique le premier élément comme étant sélectionné
                            this.numeroUrgence =
                                response.data.data[0].idNumeroUrgence
                            this.$refs.selectNumUrgence.vmodel =
                                this.numeroUrgence
                            // si le profil est un adminstrateur, on affiche tous les numéros courts. Sinon, on affiche que les 4 numéros courts (15, 17, 18, 112)
                            response.data.data.forEach((element) => {
                                if (
                                    this.$store.state.user.profil ===
                                    roles.ADMINISTRATEUR
                                ) {
                                    this.listNumerosUrgence.push({
                                        nom: element.numeroCourt,
                                        id: element.idNumeroUrgence,
                                    })
                                } else {
                                    if (
                                        element.numeroCourt == 15 ||
                                        element.numeroCourt == 17 ||
                                        element.numeroCourt == 18 ||
                                        element.numeroCourt == 112 ||
                                        element.numeroCourt == 115 ||
                                        element.numeroCourt == 116117
                                    ) {
                                        this.listNumerosUrgence.push({
                                            nom: element.numeroCourt,
                                            id: element.idNumeroUrgence,
                                        })
                                    }
                                }
                            })
                        }
                        this.listeTypeCaau()
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: "Un problème est survenu lors de la récupération des numéros d'urgence",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer la liste des numéros d'urgence",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        creationCaau() {
            // Vérification des champs obligatoires
            let isNumeroUrgence = checkInput(
                this.$refs.selectNumUrgence.$el.firstChild,
                notNullCheck(this.$refs.selectNumUrgence.vmodel)
            )
            let isNumeroLong = checkInput(
                this.$refs.numeroLongActuel,
                checkNumeroTel(
                    this.numeroLongActuel,
                    this.$store.state.dptSelect.codeInsee
                )
            )
            if (!isNumeroLong) {
                this.$notify({
                    group: 'general-notification',
                    title:
                        'Le numéro de téléphone ' +
                        this.numeroLongActuel +
                        " n'est pas au bon format.",
                    text: 'Cliquez sur ? pour les informations.',
                    type: 'error',
                    duration: 5000,
                })
            }

            let isNumeroCommunicable = checkInput(
                this.$refs.numeroCommunicable,
                isNotHtml(this.numeroCommunicable)
            )

            let isTypeCaau = checkInput(
                this.$refs.selectTypeCaau.$el.firstChild,
                notNullCheck(this.$refs.selectTypeCaau.vmodel)
            )
            let isAdresseNomVoie = checkInput(
                this.$refs.adresseNomVoie,
                notNullCheck(this.adresseNomVoie)
            )
            let isAdresseCommune = checkInput(
                this.$refs.adresseCommune.$el.firstChild,
                notNullCheck(this.adresseCommune)
            )
            let isFonction = checkInput(
                this.$refs.contactFonction,
                notNullCheck(this.contactFonction)
            )
            let isEmail = checkInput(
                this.$refs.contactEmail,
                emailCheck(this.contactEmail)
            )
            let isCommentaire = checkInput(
                this.$refs.commentaire,
                isNotHtml(this.commentaire)
            )
            let isCodePostal = checkInput(
                this.$refs.adresseCp,
                codePostalCheck(this.adresseCp)
            )
            let isContactTel = checkInput(
                this.$refs.contactTelephone,
                this.contactTelephone
            )
            if (!isContactTel) {
                this.$notify({
                    group: 'general-notification',
                    title:
                        'Le numéro de téléphone ' +
                        this.contactTelephone +
                        " n'est pas au bon format.",
                    text: 'Cliquez sur ? pour les informations.',
                    type: 'error',
                    duration: 5000,
                })
            }

            // Création du CAAU
            if (
                isNumeroUrgence &&
                isNumeroLong &&
                isNumeroCommunicable &&
                isTypeCaau &&
                isAdresseNomVoie &&
                isAdresseCommune &&
                isFonction &&
                isEmail &&
                isCommentaire &&
                isCodePostal &&
                isContactTel
            ) {
                axios
                    .post(
                        '/caau/create/?idDepartement=' +
                            this.$store.state.dptSelect.idDepartement,
                        {
                            idNumeroUrgence: this.numeroUrgence,
                            numeroLongActuel: this.numeroLongActuel,
                            numeroCommunicable: this.numeroCommunicable,
                            idTypeCaau: this.idTypeCaau,
                            estCentreNational:
                                this.$refs.isCentreNational.status,
                            estConnexionPflau: true,
                            commentaire: this.commentaire,
                            adresseNomVoie: this.adresseNomVoie,
                            adresseCp: this.adresseCp,
                            adresseCommune: this.adresseCommuneId,
                            contactFonction: this.contactFonction,
                            contactEmail: this.contactEmail,
                            contactTelephone: this.contactTelephone,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Création CAAU réussie',
                                text: '',
                                type: 'success',
                                duration: 5000,
                            })
                            this.goto(
                                '/#/caaudetail?id=' +
                                    response.data.id +
                                    '&newcaau=true'
                            )
                        }
                    })
                    .catch((e) => {
                        console.log(e.response)
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: e.response.data
                                    ? e.response.data
                                    : "Vous n'avez pas l'autorisation de créer un caau",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        checkCommune(text) {
            if (text.length > 2) {
                axios
                    .get(
                        '/commune?nomCommune=' +
                            text +
                            '&idDepartement=' +
                            this.$store.state.dptSelect.idDepartement,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.listeCommunes = response.data
                        } else {
                            this.listeCommunes = []
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de récupérer la liste des communes",
                                type: 'error',
                            })
                        }
                        notificationLogout(this, e)
                    })
            } else {
                this.listeCommunes = []
            }
        },
        selectionCom(item) {
            // On récupère le nom et l'id de la commune sélectionné et on remet le border-radius en bas de l'input Ville
            if (item.idCommune !== null && item.nom !== null) {
                this.adresseCommune = item.nom
                this.adresseCommuneId = item.idCommune
            }
        },
        creationIdentifiantCaau() {
            // On vérifie qu'un département et qu'un type de Caau aient été sélectionné
            if (this.idTypeCaau && this.idTypeCaau !== undefined) {
                axios
                    .get(
                        '/caau/identifiantcaau?idDepartement=' +
                            this.$store.state.dptSelect.idDepartement +
                            '&idTypeCaau=' +
                            this.idTypeCaau,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            // On récupère l'identifiant retourné par l'api
                            if (response.data !== null) {
                                this.identifiantCaau =
                                    response.data.identifiant_caau
                            }
                        }
                    })
                    .catch((e) => {
                        if (e.response !== null) {
                            if (e.response.status === 403) {
                                this.$notify({
                                    group: 'general-notification',
                                    title: 'Interdiction',
                                    text: "Vous n'avez pas l'autorisation de récupérer l'identifiant caau",
                                    type: 'error',
                                    duration: 5000,
                                })
                            }
                            notificationLogout(this, e)
                        }
                    })
            } else {
                this.$notify({
                    group: 'general-notification',
                    title: 'Erreur création identifiant Caau',
                    text: 'Département ou type caau non renseigné',
                    type: 'error',
                    duration: 5000,
                })
            }
        },
        majNomDepartement() {
            var nomDepartementSelection = document.getElementById(
                'nomDepartementSelection'
            )
            nomDepartementSelection.innerHTML = this.$store.state.dptSelect.nom
        },
    },
    mounted() {
        // On cache la case à cocher "Centre national" si l'utilisateur n'est pas un administrateur
        if (this.$store.state.user.profil !== Roles.ADMINISTRATEUR) {
            var divCentreNational = document.getElementById('divCentreNational')
            divCentreNational.style.display = 'none'
        }

        // Initialisation des listes de départements/types CAAU/numéros d'urgence
        this.listeNumeroUrgence()

        // On récupère le nom du département sélectionné
        this.majNomDepartement()

        // Permet de savoir si le département a été changé
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            if (this.$store.state.dptSelect.idDepartement === 0) return
            // On met à jour le nom du département
            this.majNomDepartement()
            this.listeTypeCaau()
            // On met à jour l'identifiant du caau
            this.creationIdentifiantCaau()
            // On vide la liste des communes et on la cache
            this.adresseCommune = ''
            this.listeCommunes = []
        })

        // Permet de mettre à jour les valeurs des select
        EventBus.$on(eventTypes.CREATION_IDENTIFIANT_CAAU, (args) => {
            let nomSelect = args.nomSelect

            // Pour le type de CAAU
            if (nomSelect === 'idTypeCaau') {
                this.idTypeCaau = args.idItem

                // On met à jour l'identifiant du CAAU
                this.creationIdentifiantCaau()
            }

            // Pour le numéro d'urgence
            if (nomSelect === 'selectNumeroUrgence') {
                this.numeroUrgence = args.idItem
                this.listeTypeCaau()
            }
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
        EventBus.$off(eventTypes.CREATION_IDENTIFIANT_CAAU)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.boutonValidation {
    width: 200px;
}

.textAreaForm {
    width: 400px;
}

.help-widget {
    margin-bottom: -10px;
}

.informations {
    width: 360px;
    padding-right: 15px;
}

.titreCaauCreation {
    font-family: 'Marianne Medium';
    min-height: 25px;
}

.padding-top-10-bottom-30 {
    padding: 10px 0 30px 0;
}

.inputTel {
    width: 190px;
}

.informations-commentaire {
    margin: 20px 0 0px 0;
}

.adresse-cp {
    width: 100px;
}

.switchButtonCaau {
    margin: 8px 0px 10px 25px;
}

::placeholder {
    color: #8b8da4;
    font-family: 'Marianne Medium';
    font-size: 15px;
}
</style>

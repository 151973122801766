<template>
    <!-- CONTENT -->
    <div class="container">
        <TitreReference
            :reference="'PDAA'"
            :texte="'Plan départemental d\'acheminement des appels'"
            class="margin-bottom-50-px" />
        <PdaaGrid />
    </div>
</template>

<script>
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types.js'
import PdaaGrid from '@/components/widgets/pdaaGrid/PdaaGrid'
import TitreReference from '@/components/widgets/titreReference/TitreReference'

export default {
    name: 'Pdaa',
    components: { TitreReference, PdaaGrid },
    data() {
        return {}
    },
    mounted() {
        this.$store.state.search = ''
        this.$store.commit('saveStore')
        EventBus.$emit(eventTypes.LOADED_TABLE_PDAA, {
            offset: 0,
            limit: 10,
            search: '',
        })

        //Sélection du département via DepartementSelector
        EventBus.$on(eventTypes.DEPT_CHANGE, (args) => {
            let obj = args.departement
            this.dept = obj.idDepartement

            EventBus.$emit(eventTypes.LOADED_TABLE_PDAA, {
                offset: 0,
                limit: 10,
                search: '',
            })
        })
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

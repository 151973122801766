<template>
    <!-- CONTENT -->
    <div class="container width-100-percent">
        <div class="flex justify-end items-center width-100-percent">
            <TitreReference :reference="'Historique des publications'" />
            <button
                @click="openClosePopupAideSuivi"
                class="
                    bouton-help
                    margin-left-auto
                    bouton
                    no-border
                    plain--green
                ">
                <span>Aide</span>
                <img src="@/assets/img/tnaau/Icon_video-blanc.svg" alt="Aide" />
            </button>
            <div class="backgroundModal" v-show="this.isAideSuiviOpen">
                <div class="windowModal">
                    <div class="headerModal">
                        <div
                            class="close-button pointer"
                            @click="openClosePopupAideSuivi">
                            X
                        </div>
                    </div>
                    <video width="800" ref="videoAideSuivi" controls>
                        <source
                            :src="
                                require(`@/assets/video/Video-5-Suivi-des-publications.webm`)
                            "
                            type="video/webm" />
                    </video>
                </div>
            </div>
        </div>
        <Reference :texte="'des pdaau'" />
        <div class="uppercase titre-tableau">Dernier PDAAU publié</div>
        <div v-if="this.date_publication !== ''" class="inline-flex-center">
            <table class="shadowBlueOneSide cornerRight scrollTable">
                <thead>
                    <tr>
                        <td class="headerId"></td>
                        <td
                            colspan="2"
                            class="headerdata colspan cornerLeft pointer">
                            % de récupération du pdaau
                            <help-widget
                                class="texte_bleu_fonce"
                                :texte="'Indique le pourcentage d\'opérateurs ayant <br> téléchargé le nouveau PDAAU'" />
                        </td>
                        <td
                            v-if="isIntegration"
                            colspan="2"
                            class="
                                headerdata
                                colspan
                                innerBorderBlackLeft
                                pointer
                            ">
                            % d'intégration du pdaau
                            <help-widget
                                class="texte_bleu_fonce"
                                :texte="'Indique le pourcentage d\'opérateurs ayant <br> déclaré avoir intégré les changements du <br> nouveau PDAAU dans leurs équipements <br> et systèmes.'" />
                        </td>
                        <td
                            :class="{ cornerRight: !isAuthorize }"
                            class="
                                headerdata
                                columnWidthPdaa
                                pointer
                                innerBorderBlackLeft
                            ">
                            PDAAU
                        </td>
                        <td
                            v-if="isAuthorize"
                            class="
                                headerdata
                                columnWidth
                                cornerRight
                                pointer
                                innerBorderBlackLeft
                            ">
                            Relance
                        </td>
                    </tr>
                </thead>
                <tbody class="shadowBlue cornerLeft">
                    <tr>
                        <td class="cornerLeft headerId Right">
                            Date de publication
                        </td>
                        <td v-if="isIntegration" class="headerId">
                            Opérateurs mobiles
                            <help-widget
                                class="help_margin"
                                :texte="'Désigne les 4 opérateurs mobiles de <br> téléphonie mobile et la PFLAU'" />
                        </td>
                        <td v-if="isIntegration" class="headerId">
                            Autres opérateurs
                        </td>
                        <td class="headerId innerBorderBlackLeft">
                            Opérateurs mobiles
                            <help-widget
                                class="help_margin"
                                :texte="'Désigne les 4 opérateurs mobiles de <br> téléphonie mobile et la PFLAU'" />
                        </td>
                        <td class="headerId">Autres opérateurs</td>

                        <td
                            class="
                                headerId
                                columnWidthPdaa
                                innerBorderBlackLeft
                            "></td>
                        <td
                            v-if="isAuthorize"
                            class="headerId columnWidth innerBorderBlackLeft">
                            <help-widget
                                :titre="'Mail de relance'"
                                :texte="`Ce bouton permet d'envoyer directement <br> une notification de relance à tous les OCE <br> qui n'ont pas encore téléchargé cette <br> version du PDAAU`" />
                        </td>
                    </tr>
                    <tr class="publication">
                        <td
                            class="
                                subHeader
                                Left
                                Right
                                pointer
                                underline_dotted
                            "
                            @click="getModifPdaauPublies(id_publication)">
                            {{ this.date_publication }}
                        </td>
                        <td class="subHeader">
                            <span
                                class="pointer underline_dotted"
                                @click="
                                    modificationPositionTooltip(
                                        'downloadPrincipaux'
                                    )
                                ">
                                <div class="progress">
                                    <progress
                                        id="avancement"
                                        class="progressBar"
                                        :value="
                                            this.pourcentOperateursPrincipaux
                                        "
                                        max="100"></progress>
                                    <div class="progressValue">
                                        {{ this.pourcentOperateursPrincipaux }}%
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td v-if="isIntegration" class="subHeader">
                            <span
                                class="pointer underline_dotted"
                                @click="
                                    modificationPositionTooltip('downloadAutre')
                                ">
                                <div class="progress">
                                    <progress
                                        class="progressBar"
                                        :value="
                                            this.pourcentOperateursNonPrincipaux
                                        "
                                        max="100"></progress>
                                    <div class="progressValue">
                                        {{
                                            this
                                                .pourcentOperateursNonPrincipaux
                                        }}%
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td
                            v-if="isIntegration"
                            class="subHeader innerBorderBlackLeft">
                            <span
                                class="pointer underline_dotted"
                                @click="
                                    modificationPositionTooltip(
                                        'integratePrincipaux'
                                    )
                                ">
                                <div class="progress">
                                    <progress
                                        class="progressBar"
                                        :value="
                                            this
                                                .pourcentageIntegrationOperateursPrincipaux
                                        "
                                        max="100"></progress>
                                    <div class="progressValue">
                                        {{
                                            this
                                                .pourcentageIntegrationOperateursPrincipaux
                                        }}%
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td class="subHeader">
                            <span
                                class="pointer underline_dotted"
                                @click="
                                    modificationPositionTooltip(
                                        'integrateAutre'
                                    )
                                ">
                                <div class="progress">
                                    <progress
                                        class="progressBar"
                                        :value="
                                            this
                                                .pourcentageIntegrationOperateursNonPrincipaux
                                        "
                                        max="100"></progress>
                                    <div class="progressValue">
                                        {{
                                            this
                                                .pourcentageIntegrationOperateursNonPrincipaux
                                        }}%
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td
                            class="
                                subHeader
                                columnWidthPdaa
                                texte_rouge_fonce
                                bold
                                innerBorderBlackLeft
                                pointer
                                flex-row
                            "
                            @click="getCurrentPublication()">
                            <img
                                src="../../assets/img/tnaau/Icon_telechargement_rouge.svg"
                                class="padding-right-10-px" />
                            {{ nomFichierLast }}
                        </td>
                        <td
                            v-if="isAuthorize"
                            class="
                                subHeader
                                columnWidth
                                texte_rouge_fonce
                                bold
                                innerBorderBlackLeft
                                pointer
                            ">
                            <div @click="openPopupRelance">
                                <img
                                    src="../../assets/img/tnaau/Icon_mail.svg"
                                    class="relance margin-right-10-px" />
                                Envoyer une relance
                            </div>
                            <div
                                v-if="isIntegration"
                                @click="envoiRelanceIntegrationMail">
                                <img
                                    src="../../assets/img/tnaau/Icon_mail.svg"
                                    class="relance margin-right-10-px" />
                                Intégration
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Tooltip Téléchargement Principaux -->
        <PopupInformation
            :titre="'Téléchargement des opérateurs mobiles'"
            ref="downloadPrincipaux">
            <div class="infoOperateur">
                <ul>
                    <li
                        class="texte_vert"
                        v-for="item in operateursPrincipauxDownload"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                    <li
                        class="texte_rouge"
                        v-for="item in operateursPrincipauxNotDownload"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                </ul>
            </div>
        </PopupInformation>
        <!-- Tooltip Téléchargement Autre -->
        <PopupInformation
            :titre="'Téléchargement des autres opérateurs'"
            ref="downloadAutre">
            <div class="infoOperateur" style="">
                <ul>
                    <li
                        class="texte_vert"
                        v-for="item in operateursNonPrincipauxDownload"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                    <li
                        class="texte_rouge"
                        v-for="item in operateursNonPrincipauxNotDownload"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                </ul>
            </div>
        </PopupInformation>
        <!-- Tooltip Intégration Principaux -->
        <PopupInformation
            :titre="'Intégration des opérateurs mobiles'"
            ref="integratePrincipaux">
            <div class="infoOperateur">
                <ul>
                    <li
                        class="texte_vert"
                        v-for="item in operateursPrincipauxIntegrate"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                    <li
                        class="texte_rouge"
                        v-for="item in operateursPrincipauxNotIntegrate"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                </ul>
            </div>
        </PopupInformation>
        <!-- Tooltip Intégration Autre -->
        <PopupInformation
            :titre="'Intégration des autres opérateurs'"
            ref="integrateAutre">
            <div class="infoOperateur">
                <ul>
                    <li
                        class="texte_vert"
                        v-for="item in operateursNonPrincipauxIntegrate"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                    <li
                        class="texte_rouge"
                        v-for="item in operateursNonPrincipauxNotIntegrate"
                        :key="item.idOperateur">
                        {{ item.nom }} - {{ item.contactEmail }}
                    </li>
                </ul>
            </div>
        </PopupInformation>
        <div
            v-if="this.date_publication === ''"
            class="font-titre-tableau text-align-center">
            Aucun PDAAU publié
        </div>

        <div
            class="
                uppercase
                titre-tableau
                padding-top-70-px padding-bottom-30-px
            ">
            PDAAU archivés
        </div>
        <div v-if="this.pdaauArchives.length > 0" class="inline-flex-center">
            <table class="shadowBlueOneSide cornerRight">
                <thead>
                    <tr>
                        <td class="headerId Right">Date de publication</td>
                        <td class="headerdata corner columnWidthPdaa pointer">
                            PDAAU
                        </td>
                    </tr>
                </thead>
                <tbody class="shadowBlue cornerLeft">
                    <tr
                        v-for="(item, index) in this.pdaauArchives"
                        :key="item.id">
                        <td
                            @click="getModifPdaauPublies(item.idPublication)"
                            class="
                                subHeader
                                Left
                                Right
                                pointer
                                underline_dotted
                            "
                            :class="{ cornerLeft: index === 0 }">
                            {{ item.datePublication }}
                        </td>
                        <td
                            class="
                                subHeader
                                columnWidthPdaa
                                texte_rouge_fonce
                                bold
                                pointer
                                flex-row
                            "
                            @click="getPublicationZip(item.idPublication)">
                            <img
                                src="../../assets/img/tnaau/Icon_telechargement_rouge.svg"
                                class="padding-right-10-px" />
                            {{ item.nomFichier }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            v-if="this.pdaauArchives.length === 0"
            class="font-titre-tableau text-align-center">
            Aucun PDAAU archivé
        </div>

        <PopupInformation
            :titre="'Modification apportée'"
            ref="lastModifPublication">
            <div class="ScrollPubHistory">
                <div
                    class="
                        uppercase
                        titre-tableau
                        font-titre-tableau
                        center_titre_popup
                    ">
                    CAAU
                </div>
                <div
                    v-if="this.lastModifPublicationCaau.length > 0"
                    class="no-select">
                    <table>
                        <tr>
                            <td class="headerId">ID CAAU</td>
                            <td class="headerdata cornerLeft">N° court</td>
                            <td class="headerdata">Nouveau n° long</td>
                            <td class="headerdata">À compter du</td>
                            <td class="headerdata">Ancien numéro long</td>
                            <td class="headerdata cornerRight">Jusqu'au</td>
                        </tr>
                        <tr
                            v-for="item in this.lastModifPublicationCaau"
                            :key="item.id">
                            <td class="subHeader">
                                {{ item.identifiant_caau }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_court }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_long_nouveau }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_nouveau_acompter_du }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_long_ancien }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_ancien_jusqu_au }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    v-if="this.lastModifPublicationCaau.length == 0"
                    class="font-titre-tableau text-align-center">
                    Aucune modification n'a été apportée pour cette publication
                </div>
                <div
                    class="
                        center_titre_popup
                        titre-tableau
                        font-titre-tableau
                        padding-top-50-px padding-bottom-20-px
                    ">
                    PDAA
                </div>
                <div
                    v-if="this.lastModifPublicationPdau.length > 0"
                    class="font-titre-tableau text-align-center no-select">
                    <table>
                        <tr>
                            <td class="headerId">Commune</td>
                            <td class="headerdata cornerLeft">N° court</td>
                            <td class="headerdata">Nouveau CAAU</td>
                            <td class="headerdata">À compter du</td>
                            <td class="headerdata">Ancien CAAU</td>
                            <td class="headerdata cornerRight">Jusqu'au</td>
                        </tr>
                        <tr
                            v-for="item in this.lastModifPublicationPdau"
                            :key="item.id">
                            <td class="subHeader alignLeft">
                                {{ item.commune }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_court }}
                            </td>
                            <td class="subHeader">
                                {{ item.identifiant_caau_nouveau }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_nouveau_a_compter_du }}
                            </td>
                            <td class="subHeader">
                                {{ item.identifiant_caau_ancien }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_ancien_jusqu_au }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    v-if="this.lastModifPublicationPdau.length == 0"
                    class="font-titre-tableau text-align-center">
                    Aucune modification n'a été apportée pour cette publication
                </div>
            </div>
        </PopupInformation>

        <PopupConfirmation
            :titre="'Souhaitez-vous valider l\'envoi d\'une relance mail aux opérateurs n\'ayant pas téléchargé le dernier PDAAU ?'"
            ref="popupRelance" />
    </div>
</template>

<script>
import axios from 'axios'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import Reference from '@/components/widgets/titreReference/Reference'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import * as eventTypes from '@/bus/event-types'
import EventBus from '@/bus'
import { notificationLogout } from '../../tools/authent'
import download from 'downloadjs'
import PopupInformation from '@/components/widgets/popupInformation/PopupInformation'
import roles from '../../tools/Roles'
import PopupConfirmation from '@/components/widgets/popupConfirmation/PopupConfirmation.vue'

export default {
    name: 'Historique',
    components: {
        PopupConfirmation,
        HelpWidget,
        TitreReference,
        Reference,
        PopupInformation,
    },
    data() {
        return {
            id_publication: '',
            date_publication: '',
            pourcentOperateursNonPrincipaux: '',
            pourcentOperateursPrincipaux: '',
            pourcentageIntegrationOperateursPrincipaux: '',
            pourcentageIntegrationOperateursNonPrincipaux: '',
            operateursPrincipauxDownload: [],
            operateursPrincipauxNotDownload: [],
            operateursNonPrincipauxDownload: [],
            operateursNonPrincipauxNotDownload: [],
            operateursPrincipauxIntegrate: [],
            operateursPrincipauxNotIntegrate: [],
            operateursNonPrincipauxIntegrate: [],
            operateursNonPrincipauxNotIntegrate: [],
            pdaauArchives: [],
            lastModifPublicationCaau: [],
            lastModifPublicationPdau: [],
            nomFichierLast: '',
            isAideSuiviOpen: false,
        }
    },
    mounted() {
        this.getInfoPdaauPublies()
        this.getPdaauArchives()

        // Permet de savoir si le département a été changé
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            if (this.$store.state.dptSelect.idDepartement === 0) return

            this.getInfoPdaauPublies()
            this.getPdaauArchives()
        })
        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP, (args) => {
            if (args) {
                this.envoiRelanceRecuperationMail()
            }
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP)
    },
    methods: {
        openClosePopupAideSuivi() {
            this.$refs.videoAideSuivi.pause()
            this.$refs.videoAideSuivi.currentTime = 0
            this.isAideSuiviOpen = !this.isAideSuiviOpen
        },
        modificationPositionTooltip(nameComponent) {
            switch (nameComponent) {
                case 'downloadPrincipaux':
                    this.$refs.downloadPrincipaux.modal = true
                    break
                case 'downloadAutre':
                    this.$refs.downloadAutre.modal = true
                    break
                case 'integratePrincipaux':
                    this.$refs.integratePrincipaux.modal = true
                    break
                case 'integrateAutre':
                    this.$refs.integrateAutre.modal = true
                    break
                case 'lastModifPublication':
                    this.$refs.lastModifPublication.modal = true
                    break
            }
        },
        reset() {
            this.id_publication = ''
            this.date_publication = ''
            this.pourcentOperateursNonPrincipaux = ''
            this.pourcentOperateursPrincipaux = ''
            this.pourcentageIntegrationOperateursPrincipaux = ''
            this.pourcentageIntegrationOperateursNonPrincipaux = ''
        },
        getInfoPdaauPublies() {
            axios
                .get(
                    '/publication/last/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (
                            response.data.message === null ||
                            response.data.message === undefined
                        ) {
                            this.id_publication = response.data.id_publication
                            this.nomFichierLast = response.data.nomFichier
                            this.date_publication =
                                response.data.date_publication
                            this.pourcentOperateursNonPrincipaux =
                                response.data.pourcentOperateursNonPrincipaux
                            this.pourcentOperateursPrincipaux =
                                response.data.pourcentOperateursPrincipaux
                            this.pourcentageIntegrationOperateursPrincipaux =
                                response.data.pourcentageIntegrationOperateursPrincipaux
                            this.pourcentageIntegrationOperateursNonPrincipaux =
                                response.data.pourcentageIntegrationOperateursNonPrincipaux
                            this.operateursPrincipauxDownload =
                                response.data.operateursPrincipauxDownload
                            this.operateursPrincipauxNotDownload =
                                response.data.operateursPrincipauxNotDownload
                            this.operateursNonPrincipauxDownload =
                                response.data.operateursNonPrincipauxDownload
                            this.operateursNonPrincipauxNotDownload =
                                response.data.operateursNonPrincipauxNotDownload
                            this.operateursPrincipauxIntegrate =
                                response.data.operateursPrincipauxIntegrate
                            this.operateursPrincipauxNotIntegrate =
                                response.data.operateursPrincipauxNotIntegrate
                            this.operateursNonPrincipauxIntegrate =
                                response.data.operateursNonPrincipauxIntegrate
                            this.operateursNonPrincipauxNotIntegrate =
                                response.data.operateursNonPrincipauxNotIntegrate
                        } else {
                            this.reset()
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Un problème est survenu durant la récupération des infos des pdaau publiés',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    this.reset()
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer les infos des pdaau publiés",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        getModifPdaauPublies(idPublication) {
            this.lastModifPublicationCaau = []
            this.lastModifPublicationPdau = []
            axios
                .get('/publication/archive/' + idPublication, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (
                            response.data.message === null ||
                            response.data.message === undefined
                        ) {
                            this.lastModifPublicationCaau = response.data.caau
                            this.lastModifPublicationPdau = response.data.pdaau
                            this.modificationPositionTooltip(
                                'lastModifPublication'
                            )
                        } else {
                            this.reset()
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Un problème est survenu durant la récupération des infos des pdaau publiés',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    this.reset()
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer les infos des pdaau publiés",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        envoiRelanceIntegrationMail() {
            axios
                .get(
                    '/publication/relance/integration/' +
                        this.$store.state.dptSelect.idDepartement +
                        '?idPublication=' +
                        this.id_publication,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text: "L'email de relance a bien été envoyé ",
                            type: 'success',
                            duration: 5000,
                        })
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: "Un problème est survenu durant l'envoi du mail de relance",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation d'envoyer un mail de relance",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        openPopupRelance() {
            this.$refs.popupRelance.modal = true
        },
        envoiRelanceRecuperationMail() {
            axios
                .get(
                    '/publication/relance/recuperation/' +
                        this.$store.state.dptSelect.idDepartement +
                        '?idPublication=' +
                        this.id_publication,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text: "L'email de relance a bien été envoyé ",
                            type: 'success',
                            duration: 5000,
                        })
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: "Un problème est survenu durant l'envoi du mail de relance",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation d'envoyer un mail de relance",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        getPdaauArchives() {
            axios
                .get(
                    '/pdaau_archives/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (
                            response.data.message === null ||
                            response.data.message === undefined
                        ) {
                            var i
                            this.pdaauArchives = []
                            for (i = 0; i < response.data.length; i++) {
                                this.pdaauArchives.push({
                                    idPublication:
                                        response.data[i].idPublication,
                                    datePublication:
                                        response.data[i].datePublication,
                                    nomFichier: response.data[i].nomFichier,
                                })
                            }
                        } else {
                            this.pdaauArchives = []
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Un problème est survenu durant la récupération de la liste des pdaau archivés',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    this.pdaauArchives = []
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer la liste des pdaau archivés",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        getCurrentPublication() {
            this.getPublicationZip(this.id_publication)
        },
        getPublicationZip(idPublication) {
            axios
                .get('/publicationZipId/' + idPublication, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                    responseType: 'blob',
                })
                .then((response) => {
                    if (response.status === 200) {
                        var filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        filenameBrut = filenameBrut.replace('filename=', '')
                        var filename = filenameBrut.split('"').join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/octet-stream'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: "Un problème est survenu durant le téléchargement de l'archive",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de télécharger la publication",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    computed: {
        isAuthorize() {
            return (
                this.$store.state.user.profil === roles.ADMINISTRATEUR ||
                this.$store.state.user.profil === roles.PREFECTURE_MODIFICATION
            )
        },
        isIntegration() {
            return this.$store.state.integration
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollTable {
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.underline_dotted {
    text-decoration: underline #000 dotted;
}

.tooltip {
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 999;
    width: 400px;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    box-shadow: 5px 3px 6px #00000029;
}

.infoOperateur {
    flex-shrink: 1;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;
}

ul {
    list-style: none;
    padding-inline-start: 0;
}

.columnWidth {
    width: 230px;
}

.Right {
    border-right: 1px #eeeeee solid;
}

.headerId {
    font-size: 14px;
    width: 183px;
}

.titre-tableau {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    font-family: 'Marianne Medium';
    font-size: 16px;
}

.help {
    padding-left: 100px;
}

.help_margin {
    margin-left: 0px;
}

.font-titre-tableau {
    font-family: 'Marianne Medium';
    font-size: 16px;
}

.center_titre_popup {
    margin-left: 45%;
}

.progress {
    position: relative;
    display: inline-block;
}

.progressBar {
    width: 160px;
    height: 30px;
}

.progressValue {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center;
}

.publication {
    height: 5px;
}

.relance {
    vertical-align: text-top;
}

.ScrollPubHistory {
    display: block;
    overflow-y: auto;
    max-height: 65vh;
}
</style>

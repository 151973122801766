import Vue from 'vue'
import App from './App.vue'
import './assets/css/global.css'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'

import router from './router'
import store from './store'

Vue.use(Notifications, {
    velocity,
})
Vue.config.productionTip = false

new Vue({
    render: (h) => h(App),
    bus: new Vue(),
    router,
    store,
    components: {
        App,
    },
    beforeCreate() {
        this.$store.commit('initialiseStore')
    },
    created: function () {
        document.title = 'TNAAU - Ministere'
    },
}).$mount('#app')

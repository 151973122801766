<template>
    <section class="container-400px flex flex-column gap-1">
        <div class="searchFields" v-if="this.smallButton">
            <form @submit.prevent>
                <autocomplete
                    class="inputSearch"
                    :input-attrs="{
                        autofocus: true,
                        placeholder: 'Recherche',
                    }"
                    @change="search" />
            </form>
            <help-widget
                v-if="isHelp"
                class="help-margin"
                :texte="'Vous pouvez effectuer une recherche sur l\'identifiant CAAU, le numéro court, <br/> le numéro long actuel ou ancien'" />
        </div>
        <div class="searchFields big" v-if="!this.smallButton">
            <form @submit.prevent>
                <autocomplete
                    class="inputSearchBig"
                    :items="this.dataArray"
                    @item-clicked="goto()"
                    item-text="identifiantCaau"
                    item-value="idCaau"
                    return-object
                    v-model="selectedCaau"
                    :input-attrs="{
                        autofocus: true,
                        placeholder: 'ID du centre, N° court ou N° long',
                    }"
                    @change="search"
                    :component-item="template"
                    :auto-select-one-item="false">
                </autocomplete>
            </form>
        </div>
    </section>
</template>
<script>
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'
import ItemTemplate from '../template/CaauTemplate.vue'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'

export default {
    name: 'SearchField',
    components: { Autocomplete, HelpWidget },
    data() {
        return {
            selectedCaau: null,
            template: ItemTemplate,
        }
    },
    props: {
        smallButton: {
            type: Boolean,
            default: false,
        },
        isHelp: {
            type: Boolean,
            default: false,
        },
        dataArray: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        goto() {
            if (this.selectedCaau) {
                window.location.href = `#/caaudetail?id=${this.selectedCaau.idCaau}&idDepartement=${this.selectedCaau.idDepartement}`
            }
        },
        search(text) {
            let searchFinal = ''
            if (text.length >= 2) {
                searchFinal = text
                searchFinal = encodeURIComponent(searchFinal)
            } else {
                searchFinal = ''
            }
            this.$store.state.search = searchFinal
            this.$store.commit('saveStore')
            EventBus.$emit(eventTypes.LOADED_TABLE_CAAU, {
                departement: this.$store.state.dptSelect.idDepartement,
                filtre: searchFinal,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
            })
        },
    },
    computed: {
        getStyle() {
            return {
                'background-color': this.background,
            }
        },
    },
}
</script>
<style scoped>
.big {
    min-height: 64px;
    width: 100%;
    max-width: 400px;
    border-radius: 32px;
    padding-top: 13px;
}
.searchFields {
    display: flex;
}
</style>

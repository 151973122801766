<template>
    <!-- CONTENT -->
    <div class="container">
        <div class="flex spaceBetween width-100-percent">
            <TitreReference :reference="'Aide /'" :texte="'Autoformation'" />
        </div>
        <div>
            <h1 class="video">Vidéos d'autoformation</h1>
            <div class="listeVideo flex gap-3">
                <WidgetVideo
                    :image="'publier.png'"
                    :titre="'Présentation générale de l\'interface'"
                    :video="'Video-1-Presentation-generale'" />
                <WidgetVideo
                    :image="'publier.png'"
                    :titre="'Comment changer un numéro long ?'"
                    :video="'Video-2-Changer-numero-long'" />
                <WidgetVideo
                    :image="'publier.png'"
                    :titre="'Comment changer l\'affectation d\'une commune ?'"
                    :video="'Video-3-Changer-affectation-commune'" />
                <WidgetVideo
                    :image="'publier.png'"
                    :titre="'Comment publier et envoyer un PDAAU ?'"
                    :video="'Video-4-Publier-et-envoyer-un-PDAU'" />
                <WidgetVideo
                    :image="'publier.png'"
                    :titre="'Le suivi des publications'"
                    :video="'Video-5-Suivi-des-publications'" />
            </div>
        </div>
        <div>
            <div class="aide">
                <br />
                <p v-html="texteAide"></p>
            </div>
        </div>
    </div>
</template>

<script>
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import WidgetVideo from '@/components/widgets/widgetVideo/WidgetVideo'
import axios from 'axios'
import { notificationLogout } from '@/tools/authent'

export default {
    name: 'Aide',
    components: { TitreReference, WidgetVideo },
    data() {
        return {
            texteAide: '',
        }
    },
    mounted() {
        axios
            .get('/aides?code=gouv', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    this.texteAide = response.data.texte
                }
            })
            .catch((e) => {
                notificationLogout(this, e)
            })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-family: 'Marianne Medium';
    font-size: 22px;
    color: #000091;
    text-transform: uppercase;
    margin-top: 1em;
}

.listeVideo {
    max-width: calc(100vw - 270px - 6rem);
    padding: 2rem;
    border-radius: 10px;
    background: #eeeffa 0% 0% no-repeat padding-box;
    overflow-x: auto; /* Trigger vertical scroll    */
    overflow-y: hidden;
}

.sommaire p {
    font-family: 'Marianne Thin';
    font-size: 14px;
    color: #000091;
    padding: 5px;
    margin: 2px;
}

.sommaire p:hover {
    font-family: 'Marianne Bold';
    background: #eeeffa 0% 0% no-repeat padding-box;
    border-radius: 15px;
}

.aide h2 {
    font-family: 'Marianne Medium';
    color: #14153c;
}

.aide h3 {
    font-family: 'Marianne Thin';
    color: #14153c;
}

.aide p {
    font-family: 'Marianne Light';
    font-size: 16px;
    color: #14153c;
}
.link {
    color: #000091;
    text-decoration: underline;
}
</style>

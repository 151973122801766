<template>
    <section>
        <header
            class="header-site"
            :class="{
                header_background: this.styleMode !== 'classic',
                shadow: this.shadow,
            }">
            <div class="leftContainerHeader">
                <div class="logo_tnaau-background" />
                <DepartementSelector
                    v-if="this.deptSelector"
                    :smallSelector="true"
                    :departements="this.departements"
                    :background="'#EEEFFA'" />
            </div>
            <div class="rightContainerHeader">
                <span></span>
                <button
                    class="
                        bouton
                        exportBouton
                        background_rouge
                        texte_blanc
                        no-border
                    "
                    v-show="this.url == '/#/creationoperateur'"
                    v-if="isAuthorizeExportMails"
                    :class="{
                        marging:
                            this.$store.state.user.profil !==
                                roles.GESTIONNAIRE_OCE &&
                            this.$store.state.user.profil !==
                                roles.ADMINISTRATEUR,
                    }"
                    @click="listeMailsOCE">
                    Export mails OCE
                </button>
                <button
                    class="
                        bouton
                        exportBouton
                        background_rouge
                        texte_blanc
                        no-border
                    "
                    style="margin-left: 1em"
                    v-show="this.url == '/#/creationoperateur'"
                    v-if="isAuthorizeExportMails"
                    :class="{
                        marging:
                            this.$store.state.user.profil !==
                                roles.GESTIONNAIRE_OCE &&
                            this.$store.state.user.profil !==
                                roles.ADMINISTRATEUR,
                    }"
                    @click="listeMailsResponsablesOCE">
                    Export mails responsables OCE
                </button>
                <ButtonCreate
                    v-show="this.url !== '' && this.nom !== ''"
                    :url="this.url"
                    :nom="this.nom"
                    class="marging"
                    v-if="isAuthorizeModification" />
            </div>
        </header>
        <div
            class="flex"
            style="width: 100%; height: 10px; z-index: 1"
            :class="{
                header_background: this.styleMode !== 'classic' && !this.shadow,
            }" />
    </section>
</template>

<script>
import axios from 'axios'
import DepartementSelector from '@/components/widgets/departementSelector/DepartementSelector'
import ButtonCreate from '@/components/widgets/buttonCreate/ButtonCreate'
import EventBus from '@/bus'
import download from 'downloadjs'
import * as eventTypes from '@/bus/event-types'
import * as Roles from '../../../tools/Roles'

export default {
    name: 'MainHeader',
    components: {
        DepartementSelector,
        ButtonCreate,
    },
    data() {
        return {
            shadow: false,
            roles: Roles,
        }
    },
    props: {
        styleMode: {
            type: String,
            default: 'classic',
        },
        deptSelector: {
            type: Boolean,
            default: false,
        },
        searchField: {
            type: Boolean,
            default: false,
        },
        departements: {
            type: Array,
            default: () => [],
        },
        url: {
            type: String,
            default: '',
        },
        nom: {
            type: String,
            default: '',
        },
        isAllowedWithFranceEntiere: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        listeMailsOCE() {
            axios
                .get('/operateurs/emails', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        let filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        let filename = filenameBrut
                            .replace('filename=', '')
                            .split('"')
                            .join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/json'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: 'Un problème est survenu lors de la génération du fichier csv',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch(() => {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Erreur lors de la génération du fichier csv',
                        type: 'warn',
                        duration: 5000,
                    })
                })
        },
        listeMailsResponsablesOCE() {
            axios
                .get('/operateurs/responsableEmails', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        let filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        let filename = filenameBrut
                            .replace('filename=', '')
                            .split('"')
                            .join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/json'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: 'Un problème est survenu lors de la génération du fichier csv',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch(() => {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Erreur lors de la génération du fichier csv',
                        type: 'warn',
                        duration: 5000,
                    })
                })
        },
    },
    mounted() {
        EventBus.$on(eventTypes.MAIN_SCROLL_AREA, (args) => {
            this.shadow = args.y >= 20
        })
        EventBus.$on(eventTypes.RESET_SHADOW_STYLE, () => {
            this.shadow = false
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.MAIN_SCROLL_AREA)
        EventBus.$off(eventTypes.RESET_SHADOW_STYLE)
    },
    computed: {
        isAuthorizeModification() {
            return (
                (this.$store.state.dptSelect.idDepartement !== 0 ||
                    this.$props.isAllowedWithFranceEntiere) &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    (this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION &&
                        this.nom !== 'Créer un opérateur') ||
                    (this.nom === 'Créer un opérateur' &&
                        this.$store.state.user.profil ===
                            Roles.GESTIONNAIRE_OCE))
            )
        },
        isAuthorizeExportMails() {
            return (
                this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                this.$store.state.user.profil === Roles.GESTIONNAIRE_OCE ||
                this.$store.state.user.profil ===
                    Roles.PREFECTURE_CONSULTATION ||
                this.$store.state.user.profil ===
                    Roles.PREFECTURE_MODIFICATION ||
                this.$store.state.user.profil === Roles.DIRECTION_METIER
            )
        },
    },
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 62px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-family: 'Marianne Regular';
}

.header_background {
    background: #eeeffa;
}

.rightContainerHeader {
    display: flex;
    justify-content: right;
    align-items: center;
}

.leftContainerHeader {
    display: flex;
    justify-content: left;
}

.logo_tnaau-background {
    display: inline-flex;
    height: 62px;
    width: 120px;
    background: url('../../../assets/img/tnaau/logo_tnaau.svg') left top
        no-repeat;
    background-size: 80%;
    background-position-y: 20px;
    background-position-x: 20px;
    margin-right: 50px;
}

.marging {
    margin-left: 30px;
    margin-right: 30px;
}

.shadow {
    box-shadow: 0px 4px 10px 0px rgba(212, 201, 212, 1);
}
</style>

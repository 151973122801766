<template>
    <div class="contenu">
        <div id="info" class="bloc-legend">
            <!-- Légende en mode consultation -->
            <ul class="legend" v-if="this.type === 'accueil'">
                <li>Légende :</li>
                <li><span class="red"></span>Communes affectées</li>
                <li><span class="grey"></span>Communes non affectées</li>
                <li><span class="black"></span>Emplacement du CAAU</li>
            </ul>
            <!-- Légende en mode affecter -->
            <ul class="legend" v-if="this.type === 'affecter'">
                <li>Légende :</li>
                <li><span class="red"></span>Communes affectées</li>
                <li><span class="blue"></span>Nouvelles communes affectées</li>
                <li><span class="grey"></span>Communes non affectées</li>
                <li><span class="black"></span>Emplacement du CAAU</li>
            </ul>
            <!-- Légende en mode transférer -->
            <ul class="legend" v-if="this.type === 'transferer'">
                <li>Légende :</li>
                <li><span class="red"></span>Communes affectées</li>
                <li><span class="blue"></span>Communes transféres</li>
                <li><span class="grey"></span>Communes non affectées</li>
                <li><span class="black"></span>Emplacement du CAAU</li>
            </ul>
        </div>
        <div id="mapcaau" class="map" />
        <div id="popup" class="ol-popup">
            <div id="popup-content" class="popup-detail">
                <div v-if="infoCommune.properties">
                    <h1 class="titre-info">
                        {{ infoCommune.properties.nom_commune }}<br />
                        {{ infoCommune.properties.codeInsee }}<br />
                    </h1>
                    <span class="categorie-info">Dépt.</span>
                    <span class="detail-info">{{
                        infoCommune.properties.nom_departement
                    }}</span
                    ><br />
                    <span class="categorie-info">Centre</span>
                    <span class="detail-info">{{
                        infoCommune.properties.identifiant_caau
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import EventBus from '../../../bus'
import * as eventTypes from '../../../bus/event-types.js'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import WKT from 'ol/format/WKT'
import Overlay from 'ol/Overlay'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import ImageLayer from 'ol/layer/Image.js'
import ImageWMS from 'ol/source/ImageWMS.js'
import { Fill, Stroke, Style } from 'ol/style'
import { notificationLogout } from '../../../tools/authent'

export default {
    name: 'OlMapCommune',
    components: {},
    data() {
        return {
            map: {},
            extentCommune: '',
            sourceNumUrgence: {},
            sourceCaauEnCours: {},
            sourceCaauDpt: {},
            source: {},
            listePdau: {},
            overlay: {},
            infoCommune: {},
        }
    },
    props: {
        idCaau: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        caauInfo: {
            type: Object,
            default: () => {},
        },
        pdauAffecte: {
            type: Array,
            default: () => [],
        },
        pdauTransfert: {
            type: Array,
            default: () => [],
        },
        dptDifferentsId: {
            type: Array,
            default: () => [],
        },
        dptConcernesId: {
            type: Array,
            default: () => [],
        },
        dptDifferentsCode: {
            type: Array,
            default: () => [],
        },
        dptConcernesCode: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.initCarte()
        this.centerMap()
        this.ajoutCouches()
        this.informationCommune()

        //Vérifie sur quel map on est
        switch (this.type) {
            case 'accueil':
                break
            case 'affecter':
                this.affecterCommune()
                break
            case 'transferer':
                this.transfertCommune()
                break
        }
        EventBus.$on(eventTypes.AFFECTE_COMMUNE, (obj) => {
            this.eventCommune(obj)
        })
        // Elements pour construire la popup.
        const container = document.getElementById('popup')
        this.overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        })
        this.map.addOverlay(this.overlay)
    },
    watch: {
        dptDifferentsId: {
            immediate: false,
            handler(nv, ov) {
                if (nv !== ov) {
                    this.ajoutCouches()
                    this.informationCommune()
                }
            },
        },
    },
    methods: {
        // Envoi une requête authentifiée à géoserver, récupère l'image, la transforme en blob puis renvoi l'url dans le callback
        queryGeoserverToBlob(src, callback) {
            var request = new XMLHttpRequest()
            request.open('GET', src)
            request.responseType = 'arraybuffer'
            request.setRequestHeader(
                'Authorization',
                `Bearer ${this.$store.state.user.token}`
            )
            request.onload = function () {
                const arrayBufferView = new Uint8Array(request.response)
                const blob = new Blob([arrayBufferView], { type: 'image/png' })
                callback(URL.createObjectURL(blob))
            }
            request.send()
        },
        // Envoi une requête authentifiée à géoserver, récupère le texte et le renvoi dans le callback
        queryGeoserverToText(src, callback) {
            var request = new XMLHttpRequest()
            request.open('GET', src)
            request.setRequestHeader(
                'Authorization',
                `Bearer ${this.$store.state.user.token}`
            )
            request.onload = function () {
                callback(request.response)
            }
            request.send()
        },
        customLoader(tile, src) {
            this.queryGeoserverToBlob(src, (url) => (tile.getImage().src = url))
        },
        informationCommune() {
            //Information de toutes les communes d'un département
            const autreDpt = this.dptDifferentsId.join(',')
            const memeDpt = this.dptConcernesId.join(',')
            const dptSourceInfo = this.handleDepartementParametreId(
                memeDpt,
                autreDpt
            )
            if (dptSourceInfo === '') {
                return
            }
            const sourceInfo = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:pdaau_geom_view_grey',
                        CQL_FILTER:
                            'id_departement IN (' +
                            dptSourceInfo +
                            ') &&id_numero_urgence=' +
                            this.caauInfo.id_num_urgence,
                    },
                }),
            })
            var stillMoving = []
            this.map.on('pointermove', (evt) => {
                stillMoving.push(true)
                setTimeout(() => {
                    stillMoving.shift()
                    if (stillMoving[0]) {
                        this.overlay.setPosition(undefined)
                        return
                    } else {
                        this.survoleCommune(evt, sourceInfo)
                    }
                }, 250)
            })
        },
        survoleCommune(evt, sourceInfo) {
            const url = sourceInfo
                .getSource()
                .getFeatureInfoUrl(
                    evt.coordinate,
                    this.map.getView().getResolution(),
                    this.map.getView().getProjection(),
                    { INFO_FORMAT: 'application/json' }
                )
            if (url) {
                this.queryGeoserverToText(url, (text) => {
                    const info = JSON.parse(text)
                    if (typeof info.features[0] !== 'undefined') {
                        const coordinate = evt.coordinate
                        this.infoCommune = info.features[0]
                        this.overlay.setPosition(coordinate)
                    }
                })
            }
            this.overlay.setPosition(undefined)
        },
        clickCommune(evt, sourceMap) {
            const url = sourceMap
                .getSource()
                .getFeatureInfoUrl(
                    evt.coordinate,
                    this.map.getView().getResolution(),
                    this.map.getView().getProjection(),
                    { INFO_FORMAT: 'application/json' }
                )
            if (url) {
                this.queryGeoserverToText(url, (text) => {
                    const info = JSON.parse(text)
                    if (typeof info.features[0] !== 'undefined') {
                        //Maj liste pdau + maj carte
                        if (
                            info.features[0].properties.id_departement.toString() !==
                            this.$store.state.dptSelect.idDepartement.toString()
                        ) {
                            return
                        }
                        const commune = {
                            idCommune: info.features[0].properties.id_commune,
                            nom: info.features[0].properties.nom_commune,
                            codeInsee: info.features[0].properties.code_insee,
                        }
                        let isPresent = false
                        let indexCommunes = 0
                        this.listePdau.forEach((element) => {
                            if (element.idCommune === commune.idCommune) {
                                isPresent = true
                                indexCommunes = this.listePdau.indexOf(element)
                            }
                        })
                        if (isPresent) {
                            this.listePdau.splice(indexCommunes, 1)
                        } else {
                            this.listePdau.push(commune)
                        }
                        this.eventCommune(this.listePdau)
                    }
                })
            }
        },
        vectorSourceLoader(vectorSource, communesPdaa, projection, success) {
            const url =
                '/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=tnaau:pdaau_geom_view' +
                '&CQL_FILTER=id_commune%20in%20(' +
                communesPdaa +
                ')&outputFormat=application/json' +
                '&srsname=' +
                projection.getCode()
            this.queryGeoserverToText(url, (text) => {
                const features = vectorSource.getFormat().readFeatures(text)
                vectorSource.addFeatures(features)
                success(features)
            })
        },
        eventCommune(listpdauAttente) {
            this.listePdau = listpdauAttente
            this.map.removeLayer(this.source)
            if (listpdauAttente.length > 0) {
                const communesPdaa = []
                this.listePdau.forEach((pdau) => {
                    communesPdaa.push(pdau.idCommune)
                })
                var vectorLoader = this.vectorSourceLoader
                var vectorSource = new VectorSource({
                    format: new GeoJSON(),
                    loader: function (extent, resolution, projection, success) {
                        vectorLoader(
                            vectorSource,
                            communesPdaa,
                            projection,
                            success
                        )
                    },
                })
                var sourcetmp = new VectorLayer({
                    source: vectorSource,
                    style: new Style({
                        fill: new Fill({
                            color: '#000091',
                        }),
                        stroke: new Stroke({
                            color: '#FFFFFF',
                            width: 1,
                        }),
                    }),
                })
                this.map.addLayer(sourcetmp)
                this.map.removeLayer(this.source)
                this.source = sourcetmp
            }
        },
        affecterCommune() {
            //Récupère les pdaa modif en attente
            this.eventCommune(this.pdauAffecte)
            //Ajout communes sélectionnées dans pdaa modif en attente
            this.map.on('click', (evt) => {
                this.clickCommune(evt, this.sourceNumUrgence)
            })
        },
        transfertCommune() {
            //Récupère les pdaa modif en attente
            this.eventCommune(this.pdauTransfert)
            //Ajout communes sélectionnées dans pdaa modif en attente
            this.map.on('click', (evt) => {
                this.clickCommune(evt, this.sourceCaauEnCours)
            })
        },
        initCarte() {
            this.map = new Map({
                target: 'mapcaau',
                view: new View({
                    projection: 'EPSG:3857',
                    center: [0, 0],
                    zoom: 1,
                    minZoom: 8,
                    maxZoom: 11,
                }),
            })
        },
        ajoutCouches() {
            const autreDpt = this.dptDifferentsId.join(',')
            const autreDptCodeInsee = this.dptDifferentsCode.join(',')
            const memeDpt = this.dptConcernesId.join(',')
            const memeDptCodeInsee = this.dptConcernesCode.join(',')
            this.sourceCaauEnCours = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:pdaau_geom_view_red',
                        CQL_FILTER:
                            'id_departement IN (' +
                            this.handleDepartementParametreId(
                                memeDpt,
                                autreDpt
                            ) +
                            ') && id_caau=' +
                            this.idCaau,
                    },
                }),
            })
            this.sourceNumUrgence = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:pdaau_geom_view_grey',
                        CQL_FILTER:
                            'id_departement IN (' +
                            this.handleDepartementParametreId(
                                memeDpt,
                                autreDpt
                            ) +
                            ') && id_numero_urgence=' +
                            this.caauInfo.id_num_urgence +
                            '&& id_caau<>' +
                            this.idCaau,
                    },
                }),
            })

            // code_insee doit être envoyé sous la forme 'code' pour les 01/02...
            var parametreCodeInsee = this.handleDepartementParametreCode(
                memeDptCodeInsee,
                autreDptCodeInsee
            )
                .split(',')
                .map((code) => "'" + code + "'")
                .join(',')
            this.sourceCaauDpt = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:departement',
                        CQL_FILTER:
                            'code_insee IN (' + parametreCodeInsee + ')',
                    },
                }),
            })
            this.map.addLayer(this.sourceNumUrgence)
            this.map.addLayer(this.sourceCaauEnCours)
            this.map.addLayer(this.sourceCaauDpt)

            const sourceCentreCaau = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:caau_geom_view',
                        CQL_FILTER:
                            'id_commune=' +
                            this.caauInfo.idCommune +
                            '&& id_departement IN (' +
                            this.handleDepartementParametreId(
                                memeDpt,
                                autreDpt
                            ) +
                            ')',
                    },
                }),
            })
            this.map.addLayer(sourceCentreCaau)
        },
        centerMap() {
            this.polygonByDepartement().then((wkt) => {
                const format = new WKT()
                const feature = format.readFeature(wkt, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857',
                })
                this.map.getView().fit(feature.getGeometry().getExtent())
            })
        },
        polygonByDepartement() {
            return axios
                .get(
                    '/departement/polygon/' +
                        this.$store.state.detailDptSelectId,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        return response.data.bbox
                    }
                })
                .then()
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
        addComaOrNot(dpt, autreDpt) {
            return dpt === '' || dpt === '-1'
                ? autreDpt
                : autreDpt === ''
                ? dpt
                : `${dpt},${autreDpt}`
        },
        handleDepartementParametreId(meme, autre) {
            if (this.$store.state.dptSelect.idDepartement === 0) {
                return this.addComaOrNot(meme, autre)
            }
            if (
                this.$store.state.detailDptSelectId.toString() ===
                this.$store.state.dptSelect.idDepartement.toString()
            ) {
                return this.addComaOrNot(meme, autre)
            } else {
                return this.$store.state.dptSelect.idDepartement.toString()
            }
        },
        handleDepartementParametreCode(meme, autre) {
            if (this.$store.state.dptSelect.idDepartement === 0) {
                return this.addComaOrNot(meme, autre)
            }
            if (
                this.$store.state.detailDptSelectId.toString() ===
                this.$store.state.dptSelect.idDepartement.toString()
            ) {
                return this.addComaOrNot(meme, autre)
            } else {
                return this.$store.state.dptSelect.codeInsee.toString()
            }
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.AFFECTE_COMMUNE)
    },
}
</script>

<style scoped>
.contenu {
    flex: 1;
}

.map {
    height: 550px;
    width: 100%;
    background-color: #eeeffa;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.detail-info {
    font-family: 'Marianne Light';
    font-size: 14px;
}

.titre-info {
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.categorie-info {
    font-family: 'Marianne Medium';
    font-size: 12px;
}

.bloc-legend {
    font-family: 'Marianne Light';
    font-size: 14px;
}

.legend {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0;
    padding-left: 5px;
    list-style: none;
}

.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
}

/* Legend colors */
.legend .grey {
    background-color: #a8b4de;
}
.legend .red {
    background-color: #e1000f;
}
.legend .blue {
    background-color: #000091;
}
.legend .black {
    background-color: #000000;
}
</style>

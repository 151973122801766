<template>
    <!-- CONTENT -->
    <div class="container no-select">
        <TitreReference
            :reference="'Liste'"
            :texte="'Opérateurs'"
            class="margin-bottom-50-px" />
        <OperateurGrid />
    </div>
</template>
<script>
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import OperateurGrid from '@/components/widgets/operateurGrid/OperateurGrid'
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
export default {
    name: 'Operateurs',
    components: { TitreReference, OperateurGrid },
    data() {
        return {
            listOperateur: [],
        }
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
    },
    mounted() {
        this.$store.state.sortorder = 'asc'
        this.$store.state.sort = 'nom'
        this.$store.state.search = ''
        this.$store.commit('saveStore')
        EventBus.$emit(eventTypes.LOADED_TABLE_OPERATEUR, {
            offset: 0,
            limit: 10,
            search: '',
            sort: this.$store.state.sort,
            sortorder: this.$store.state.sortorder,
            tokken: this.$store.state.user.token,
        })
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
    <!-- CONTENT -->
    <div class="container width-100-percent">
        <div class="flex justify-end items-center width-100-percent">
            <TitreReference :reference="'Publier'" :texte="'le PDAAU'" />
            <button
                v-if="isAuthorize"
                @click="openClosePopupAidePublication"
                class="
                    bouton-help
                    margin-left-auto
                    bouton
                    no-border
                    plain--green
                ">
                <span>Aide</span>
                <img src="@/assets/img/tnaau/Icon_video-blanc.svg" alt="Aide" />
            </button>
            <div class="backgroundModal" v-show="this.isAidePublicationOpen">
                <div class="windowModal">
                    <div class="headerModal">
                        <div
                            class="close-button pointer"
                            @click="openClosePopupAidePublication">
                            X
                        </div>
                    </div>
                    <video width="800" ref="videoAidePublication" controls>
                        <source
                            :src="
                                require(`@/assets/video/Video-4-Publier-et-envoyer-un-PDAU.webm`)
                            "
                            type="video/webm" />
                    </video>
                </div>
            </div>
        </div>
        <div class="flex justify-end gap-1 items-center width-100-percent">
            <button
                v-if="
                    isAuthorize &&
                    this.nombreModificationsAttentesCAAU +
                        this.nombreModificationsAttentesPDAA >
                        0
                "
                class="
                    bouton
                    largeBouton
                    background_rouge
                    texte_blanc
                    no-border
                "
                @click="confirmationPublierPdaau">
                <img
                    src="../../assets/img/tnaau/Icon_publier.svg"
                    class="margin-right-10-px" />
                Publier le PDAAU en attente
            </button>
            <button
                v-if="
                    isAdmin &&
                    this.nombreModificationsAttentesCAAU +
                        this.nombreModificationsAttentesPDAA ==
                        0
                "
                class="
                    bouton
                    largeBouton
                    background_rouge
                    texte_blanc
                    no-border
                "
                @click="confirmationPublierPdaauSansModification">
                <img
                    src="../../assets/img/tnaau/Icon_publier.svg"
                    class="margin-right-10-px" />
                Publier le PDAAU
            </button>
        </div>
        <div class="uppercase font-titre-tableau sous-titre">
            Modification avant publication
        </div>
        <div class="uppercase titre-tableau font-titre-tableau">CAAU</div>
        <div
            class="flex justify-center no-select margin-bottom-50-px"
            v-if="this.listeCaau.length > 0">
            <table>
                <tr>
                    <td class="headerId">ID CAAU</td>
                    <td class="headerdata cornerLeft">N° court</td>
                    <td class="headerdata">Nouveau n° long</td>
                    <td class="headerdata">À compter du</td>
                    <td class="headerdata">Ancien numéro long</td>
                    <td class="headerdata cornerRight">Jusqu'au</td>
                    <td>
                        <span
                            v-if="
                                isAuthorize &&
                                this.nombreModificationsAttentesCAAU > 0
                            "
                            class="
                                flex
                                align-center
                                gap-05
                                texte_rouge
                                pointer
                                font-marianne-light
                            "
                            @click="openPopupAnnulerAllModifsCaau()"
                            ><img
                                src="../../assets/img/tnaau/Icon_annuler.svg" />Annuler
                            les modifications</span
                        >
                    </td>
                </tr>
                <tr v-for="(item, index) in this.listeCaau" :key="item.id">
                    <td
                        class="subHeader pointer"
                        :class="{
                            cornerLeft: index === 0,
                            shadowBlueTop: index === 0,
                            shadowBlueLeft: index !== 0,
                            line: item.type === 'suppression',
                        }"
                        @click="goto('/#/caaudetail?id=' + item.idCaau)">
                        {{ item.identifiantcaau }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.numeroCourt }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.numeroLongNouveau }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.dateNouveauAcompterDu }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.numeroLongAncien }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.dateAncienJusquAu }}
                    </td>
                    <td>
                        <span
                            v-if="isAuthorize && item.type === ''"
                            class="
                                flex
                                align-center
                                gap-05
                                texte_rouge
                                pointer
                                font-marianne-light
                            "
                            @click="
                                openPopupAnnulerModifsCaau(
                                    item.idCaau,
                                    item.identifiantcaau
                                )
                            "
                            ><img
                                src="../../assets/img/tnaau/Icon_annuler.svg" />
                            Annuler
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <div
            v-if="this.listeCaau.length == 0"
            class="font-titre-tableau text-align-center">
            Aucune modification en attente
        </div>
        <div class="uppercase titre-tableau font-titre-tableau">PDAA</div>
        <div
            v-if="this.listePdaau.length > 0"
            class="
                flex
                justify-center
                font-titre-tableau
                text-align-center
                no-select
            ">
            <table>
                <tr>
                    <td class="headerId">Commune</td>
                    <td class="headerdata cornerLeft">N° court</td>
                    <td class="headerdata">Nouveau CAAU</td>
                    <td class="headerdata">À compter du</td>
                    <td class="headerdata">Ancien CAAU</td>
                    <td class="headerdata cornerRight">Jusqu'au</td>
                    <td>
                        <span
                            v-if="
                                isAuthorize &&
                                this.nombreModificationsAttentesPDAA > 0
                            "
                            class="
                                flex
                                align-center
                                gap-05
                                texte_rouge
                                pointer
                                font-marianne-light
                            "
                            @click="openPopupAnnulerAllModifsPdaa()"
                            ><img
                                src="../../assets/img/tnaau/Icon_annuler.svg" />Annuler
                            les modifications</span
                        >
                    </td>
                </tr>
                <tr v-for="(item, index) in this.listePdaau" :key="item.id">
                    <td
                        class="subHeader alignLeft"
                        :class="{
                            cornerLeft: index === 0,
                            shadowBlueTop: index === 0,
                            shadowBlueLeft: index !== 0,
                            line: item.type === 'suppression',
                        }">
                        {{ item.commune }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.numeroCourt }}
                    </td>
                    <td
                        class="subHeader pointer"
                        :class="{
                            line: item.type === 'suppression',
                        }"
                        @click="goto('/#/caaudetail?id=' + item.idCaauNouveau)">
                        {{ item.identifiantCaauNouveau }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.dateNouveauACompterDu }}
                    </td>
                    <td
                        class="subHeader pointer"
                        :class="{
                            line: item.type === 'suppression',
                        }"
                        @click="goto('/#/caaudetail?id=' + item.idCaauAncien)">
                        {{ item.identifiantCaauAncien }}
                    </td>
                    <td
                        class="subHeader"
                        :class="{
                            line: item.type === 'suppression',
                        }">
                        {{ item.dateAncienJusquAu }}
                    </td>
                    <td>
                        <span
                            v-if="isAuthorize && item.type === ''"
                            class="
                                flex
                                align-center
                                gap-05
                                texte_rouge
                                pointer
                                font-marianne-light
                            "
                            @click="
                                openPopupAnnulerModifsPdaa(
                                    item.idNumeroUrgence,
                                    item.idCommune
                                )
                            "
                            ><img
                                src="../../assets/img/tnaau/Icon_annuler.svg" />
                            Annuler</span
                        >
                    </td>
                </tr>
            </table>
        </div>
        <div
            v-if="this.listePdaau.length == 0"
            class="font-titre-tableau text-align-center">
            Aucune modification en attente
        </div>
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir annuler les modifications de ce caau ?'"
            ref="popupAnnulerModifCaau" />
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir annuler les modifications de ce pdaa ?'"
            ref="popupAnnulerModifPdaa" />
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir annuler les modifications de tous les caau ?'"
            ref="popupAllAnnulerModifCaau" />
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir annuler les modifications de tous les pdaa ?'"
            ref="popupAllAnnulerModifPdaa" />
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir publier les modifications en attente du pdaau ?'"
            ref="popupPublier" />
        <PopupAdmin
            :titre="'Êtes-vous sûr de vouloir publier les modifications en attente du pdaau ?'"
            ref="popupPublierAdmin" />
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir publier le pdaau ?'"
            ref="popupPublierSansModification" />
        <PopupInformation
            :titre="'Le PDAAU a bien été envoyé aux opérateurs.'"
            :message="'Vous pouvez assurer le suivi relatif à sa récupération et intégration par les opérateurs dans la page historique des publications.'"
            ref="popupInfoPublier" />
    </div>
</template>

<script>
import axios from 'axios'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import * as eventTypes from '@/bus/event-types'
import EventBus from '@/bus'
import { notificationLogout } from '../../tools/authent'
import PopupConfirmation from '@/components/widgets/popupConfirmation/PopupConfirmation'
import PopupAdmin from '../widgets/popupConfirmation/PopupAdmin.vue'
import PopupInformation from '@/components/widgets/popupInformation/PopupInformation'
import moment from 'moment'
import roles from '../../tools/Roles'

export default {
    name: 'Publier',
    components: {
        TitreReference,
        PopupConfirmation,
        PopupInformation,
        PopupAdmin,
    },
    data() {
        return {
            listeCaau: [],
            listePdaau: [],
            supprModifsCaau: [],
            supprModifsPdaa: [],
            isPublication: false,
            isDateKo: false,
            isDateNotModify: false,
            isModifyPublication: false,
            isAidePublicationOpen: false,
            nombreModificationsAttentesCAAU: 0,
            nombreModificationsAttentesPDAA: 0,
        }
    },
    mounted() {
        this.getListPdaauCaauEnAttente()

        // Permet de savoir si le département a été changé
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            if (this.$store.state.dptSelect.idDepartement !== 0)
                this.getListPdaauCaauEnAttente()
        })

        //Récupère le changement d'état pour vérifier si on supprime les modifications liées au caau/pdaa
        //Pour la pop up de suppression
        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP, (args) => {
            if (args) {
                this.supprModifsCaau.forEach((suppr) => {
                    this.caauSuppressionModif(
                        suppr.idCaau,
                        suppr.identifiantcaau
                    )
                })
                this.supprModifsPdaa.forEach((suppr) => {
                    this.pdaauSuppressionModif(
                        suppr.idNumeroUrgence,
                        suppr.idCommune
                    )
                })
                if (this.isPublication === true) {
                    this.bypass = args
                    this.publierPdaau()
                }
            }

            // On vide les tableaux contenant la liste des modifs caau/pdaa a supprimer
            this.supprModifsCaau = []
            this.supprModifsPdaa = []
            this.isPublication = false
        })

        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP_BYPASS, (args) => {
            this.supprModifsCaau.forEach((suppr) => {
                this.caauSuppressionModif(suppr.idCaau, suppr.identifiantcaau)
            })
            this.supprModifsPdaa.forEach((suppr) => {
                this.pdaauSuppressionModif(
                    suppr.idNumeroUrgence,
                    suppr.idCommune
                )
            })
            if (this.isPublication === true) {
                this.bypass = args
                this.publierPdaau()
            }
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP)
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP_BYPASS)
    },
    methods: {
        openClosePopupAidePublication() {
            this.$refs.videoAidePublication.pause()
            this.$refs.videoAidePublication.currentTime = 0
            this.isAidePublicationOpen = !this.isAidePublicationOpen
        },
        goto(url) {
            window.location.href = url
        },
        openPopupAnnulerAllModifsCaau() {
            // On insert dans un tableau les caau dont il faut supprimer les modifications
            this.supprModifsCaau = this.listeCaau

            const isNouveauCentre = this.listeCaau.some(
                (caau) =>
                    caau.numeroLongAncien === null ||
                    caau.numeroLongAncien === ''
            )

            if (isNouveauCentre) {
                this.$refs.popupAllAnnulerModifCaau.titre =
                    "Êtes-vous sûr de vouloir annuler les modifications de tous les caau ?<br/>Cela supprimera également les nouveaux CAAU créés qui n'ont pas encore été publiés"
            } else {
                // On affiche la popup de confirmation
                this.$refs.popupAllAnnulerModifCaau.titre =
                    'Êtes-vous sûr de vouloir annuler les modifications de tous les caau ?'
            }

            // On affiche la popup de confirmation
            this.$refs.popupAllAnnulerModifCaau.modal = true
        },
        openPopupAnnulerModifsCaau(idCaau, identifiantcaau) {
            // On insert dans un tableau les caau dont il faut supprimer les modifications
            this.supprModifsCaau.push({
                idCaau: idCaau,
                identifiantcaau: identifiantcaau,
            })

            let numeroLongAncien = this.listeCaau.find(
                (caau) => caau.idCaau == idCaau
            ).numeroLongAncien

            if (numeroLongAncien === '' || numeroLongAncien === null) {
                this.$refs.popupAnnulerModifCaau.titre =
                    'Annuler la création de ce caau le supprime définitivement ainsi que toutes ses affectations communales'
            } else {
                // On affiche la popup de confirmation
                this.$refs.popupAnnulerModifCaau.titre =
                    'Êtes-vous sûr de vouloir annuler les modifications de ce caau ?'
            }

            this.$refs.popupAnnulerModifCaau.modal = true
        },
        openPopupAnnulerAllModifsPdaa() {
            // On insert dans un tableau les pdaa dont il faut supprimer les modifications
            this.supprModifsPdaa = this.listePdaau
            // On affiche la popup de confirmation
            this.$refs.popupAllAnnulerModifPdaa.modal = true
        },
        openPopupAnnulerModifsPdaa(idNumeroUrgence, idCommune) {
            // On insert dans un tableau les pdaa dont il faut supprimer les modifications
            this.supprModifsPdaa.push({
                idNumeroUrgence: idNumeroUrgence,
                idCommune: idCommune,
            })
            // On affiche la popup de confirmation
            this.$refs.popupAnnulerModifPdaa.modal = true
        },
        getListPdaauCaauEnAttente() {
            // On récupère la liste des CAAU/PDAAU qui ont été modifiés
            axios
                .get(
                    '/pdaau/modif/' + this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null && response.data) {
                            this.listeCaau = []
                            this.listePdaau = []
                            this.nombreModificationsAttentesCAAU = 0
                            this.nombreModificationsAttentesPDAA = 0
                            if (
                                response.data.message === null ||
                                response.data.message === undefined
                            ) {
                                this.isDateKo = false
                                // Vérification des 16 jours pour respecter les délais
                                let date = moment(new Date())
                                    .add(16, 'days')
                                    .format('YYYY-MM-DD')
                                response.data.caau.forEach((element) => {
                                    // On rempli le tableau avec les résultats trouvés
                                    this.listeCaau.push({
                                        idCaau: element.id_caau,
                                        dateAncienJusquAu:
                                            element.date_ancien_jusqu_au,
                                        dateNouveauAcompterDu:
                                            element.date_nouveau_acompter_du,
                                        identifiantcaau:
                                            element.identifiant_caau,
                                        numeroCourt: element.numero_court,
                                        numeroLongAncien:
                                            element.numero_long_ancien,
                                        numeroLongNouveau:
                                            element.numero_long_nouveau,
                                        type: element.type,
                                    })

                                    if (element.type === '') {
                                        this.nombreModificationsAttentesCAAU += 1
                                    }

                                    let acompter = moment(
                                        element.date_nouveau_acompter_du,
                                        'DD/MM/YYYY'
                                    ).format('YYYY-MM-DD')

                                    if (
                                        acompter < date &&
                                        element.type === '' &&
                                        element.numero_long_ancien !== '' &&
                                        element.numero_long_ancien !== null
                                    ) {
                                        this.isDateKo = true
                                    }
                                })

                                response.data.pdaau.forEach((element) => {
                                    // On rempli le tableau avec les résultats trouvés
                                    this.listePdaau.push({
                                        commune: element.commune,
                                        idCommune: element.idCommune,
                                        dateAncienJusquAu:
                                            element.date_ancien_jusqu_au,
                                        dateNouveauACompterDu:
                                            element.date_nouveau_a_compter_du,
                                        idCaauAncien: element.id_caau_ancien,
                                        identifiantCaauAncien:
                                            element.identifiant_caau_ancien,
                                        idCaauNouveau: element.id_caau_nouveau,
                                        identifiantCaauNouveau:
                                            element.identifiant_caau_nouveau,
                                        numeroCourt: element.numero_court,
                                        idNumeroUrgence:
                                            element.id_numero_urgence,
                                        type: element.type,
                                    })

                                    if (element.type === '') {
                                        this.nombreModificationsAttentesPDAA += 1
                                    }
                                    let acompter = moment(
                                        element.date_nouveau_a_compter_du,
                                        'DD/MM/YYYY'
                                    ).format('YYYY-MM-DD')
                                    if (
                                        acompter < date &&
                                        element.type === ''
                                    ) {
                                        this.isDateKo = true
                                    }
                                })
                                if (this.isDateKo) {
                                    var texte =
                                        'Elles seront modifiées à la publication'
                                    if (this.isAdmin) {
                                        texte += ' si vous le souhaitez.'
                                    } else {
                                        texte += '.'
                                    }
                                    this.$notify({
                                        group: 'general-notification',
                                        title: 'Certaines dates ne respectent les délais minimum.',
                                        text: texte,
                                        type: 'warn',
                                        duration: 5000,
                                    })
                                }
                            }
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: 'Un problème est survenu lors de la récupération des PDAAU/CAAU en attente',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response !== undefined) {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de récupérer la liste des caau/pdaau en attente",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    }
                })
        },
        caauSuppressionModif(idCaau, identifiantcaau) {
            axios
                .delete('/caauModifEnAttente/' + idCaau, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        // On met à jour les tableaux
                        this.getListPdaauCaauEnAttente()
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text:
                                'Les modifications du caau ' +
                                identifiantcaau +
                                ' ont bien été supprimées',
                            type: 'success',
                            duration: 5000,
                        })
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: 'Un problème est survenu lors de la suppression des modifications du CAAU',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de supprimer les modifications du caau",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 408) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'SESSION TIMEOUT',
                            text: "Votre session utilisateur n'est plus valide.",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        pdaauSuppressionModif(idNumeroUrgence, idCommune) {
            axios
                .delete(
                    '/pdaauModifEnAttente/' +
                        idNumeroUrgence +
                        '/' +
                        idCommune +
                        '/',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        // On met à jour les tableaux
                        this.getListPdaauCaauEnAttente()
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text: 'Les modifications du pdaau ont bien été supprimé',
                            type: 'success',
                            duration: 5000,
                        })
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: 'Un problème est survenu lors de la suppression des modifications du PDAAU',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de supprimer les modifications du pdaau",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 408) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'SESSION TIMEOUT',
                            text: "Votre session utilisateur n'est plus valide.",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        confirmationPublierPdaau() {
            // "this.isDateKo" est égale à "true" si la date d'une publication est plus ancienne que la date de publication.
            // "this.isDateNotModify" doit être égale à "false" pour que les anciennes dates soient mises à jour automatiquement.
            this.isPublication = true
            this.isDateNotModify = !this.isDateKo
            if (this.isAdmin) {
                this.$refs.popupPublierAdmin.modal = true
            } else {
                this.$refs.popupPublier.modal = true
            }
        },
        confirmationPublierPdaauSansModification() {
            if (this.isAdmin) {
                this.isPublication = true
                this.isDateNotModify = true
                this.$refs.popupPublierSansModification.modal = true
            }
        },
        publierPdaau() {
            if (
                this.listeCaau.length > 0 ||
                this.listePdaau.length > 0 ||
                this.isAdmin
            ) {
                axios
                    .post(
                        '/publier_pdaau',
                        {
                            idDepartement:
                                this.$store.state.dptSelect.idDepartement,
                            isDateNotModify: this.isDateNotModify,
                            bypass: this.bypass,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            // On met à jour les tableaux
                            this.getListPdaauCaauEnAttente()
                            this.$refs.popupInfoPublier.modal = true
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: 'Un problème est survenu lors de la publication du PDAAU',
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de publier le pdaau",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            } else {
                this.$notify({
                    group: 'general-notification',
                    title: 'Interdiction',
                    text: 'Aucun PDAAU à publier',
                    type: 'warn',
                    duration: 5000,
                })
            }
        },
    },
    computed: {
        isAuthorize() {
            return (
                this.$store.state.user.profil === roles.ADMINISTRATEUR ||
                this.$store.state.user.profil === roles.PREFECTURE_MODIFICATION
            )
        },
        isAdmin() {
            return this.$store.state.user.profil === roles.ADMINISTRATEUR
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alignLeft {
    align-items: flex-start;
    text-align: left;
    padding-left: 15px;
}

.largeBouton {
    width: 265px;
    height: 100%;
}

.titre-tableau {
    display: block;
    text-align: center;
    margin: 1rem;
}

.font-titre-tableau {
    font-family: 'Marianne Medium';
    font-size: 16px;
}

.sous-titre {
    margin: 1rem 0 3rem;
}

.line {
    text-decoration: line-through;
}
</style>

<template>
    <section class="sectionColumn">
        <div class="font-label-input padding-bottom-10-px">Profil</div>
        <select v-model="selected" class="selectForm" @change="selection">
            <option
                class="optionForm"
                v-for="option in this.profilList"
                :key="option.idProfil"
                :value="option.idProfil">
                {{ option.libelle }}
            </option>
        </select>
    </section>
</template>

<script>
import axios from 'axios'
import { notificationLogout } from '../../../tools/authent'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import * as Roles from '@/tools/Roles'

export default {
    name: 'ProfilSelector',
    data() {
        return {
            selected: '',
            profilList: [],
        }
    },
    methods: {
        selection() {
            this.profilList.forEach((profil) => {
                if (this.selected === profil.idProfil) {
                    EventBus.$emit(
                        eventTypes.UPDATE_BTN_FRANCE_ENTIERE,
                        profil.type
                    )
                    if (profil.type === Roles.ADMINISTRATEUR) {
                        EventBus.$emit(eventTypes.SHOW_STRUCTURE, false)
                        EventBus.$emit(eventTypes.SHOW_PROFIL_ADMIN, true)
                        EventBus.$emit(eventTypes.SHOW_PREFECTURE_MODIF, false)
                    } else if (profil.type === Roles.DIRECTION_METIER) {
                        EventBus.$emit(eventTypes.SHOW_STRUCTURE, true)
                        EventBus.$emit(eventTypes.SHOW_PROFIL_ADMIN, false)
                        EventBus.$emit(eventTypes.SHOW_PREFECTURE_MODIF, false)
                    } else if (profil.type === Roles.PREFECTURE_MODIFICATION) {
                        EventBus.$emit(eventTypes.SHOW_STRUCTURE, false)
                        EventBus.$emit(eventTypes.SHOW_PROFIL_ADMIN, false)
                        EventBus.$emit(eventTypes.SHOW_PREFECTURE_MODIF, true)
                    } else {
                        EventBus.$emit(eventTypes.SHOW_STRUCTURE, false)
                        EventBus.$emit(eventTypes.SHOW_PROFIL_ADMIN, false)
                        EventBus.$emit(eventTypes.SHOW_PREFECTURE_MODIF, false)
                    }
                }
            })
        },
    },
    mounted() {
        axios
            .get('/profil', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    this.profilList = Array.from(response.data.data)
                } else {
                    this.$notify({
                        group: 'general-notification',
                        title: response.status,
                        text: response.statusText,
                        type: 'warn',
                        duration: 5000,
                    })
                }
            })
            .catch((e) => {
                notificationLogout(this, e)
            })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

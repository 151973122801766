<template>
    <section>
        <div class="backgroundModal" v-show="this.modal">
            <div class="windowModal">
                <div class="headerModal">
                    <div class="inline-flex" />
                    <div class="inline-flex"></div>
                    <div class="inline-flex pointer" @click="fermer()">X</div>
                </div>
                <div class="contentModal uppercase">
                    Modification du numéro long
                </div>
                <div>
                    <div class="formStyle">
                        <div class="formInput textInput">
                            <div>
                                <div class="flex">
                                    <label for="newTel">Nouveau numéro</label>
                                    <help-widget
                                        class="help-widget"
                                        :titre="'Format des numéros de téléphone'"
                                        :texte="
                                            'France : +33000000000 <br /> ' +
                                            'SMS : 114 <br /> ' +
                                            'DROM : +000000000000 <br /> ' +
                                            'Saint-Pierre-et-Miquelon : +000000000 <br /> ' +
                                            'Monaco : +377000000000 <br /> '
                                        " />
                                </div>
                                <input
                                    id="newTel"
                                    v-model="newTel"
                                    type="text"
                                    name="newTel"
                                    ref="newTel" />
                            </div>
                            <div class="margin-top-20-px">
                                <label
                                    for="confirm-tel"
                                    class="flex padding-bottom-7-px"
                                    >Confirmer le nouveau numéro</label
                                >
                                <input
                                    type="text"
                                    id="confirm-tel"
                                    placeholder="Confirmer le nouveau numéro"
                                    v-model="newTelConfirm"
                                    @input="checkTelConfirm"
                                    ref="newTelConfirm" />
                            </div>
                            <div
                                class="
                                    titre_help
                                    texte_rouge_fonce
                                    margin-bottom-20-px
                                "
                                v-show="notSame">
                                Les numéros de téléphone ne correspondent pas
                            </div>
                        </div>
                        <div class="formInput dateInput">
                            <label for="dateActif" class="padding-bottom-7-px"
                                >Actif à compter</label
                            >
                            <input
                                id="dateActif"
                                v-model="dateActif"
                                type="date"
                                name="dateActif"
                                ref="dateActif" />
                        </div>
                    </div>
                    <div class="footModal">
                        <div class="annulerModal pointer" @click="fermer()">
                            Annuler
                        </div>
                        <button class="buttonRed pointer" @click="valider()">
                            <img
                                src="../../../assets/img/tnaau/Icon_valider.svg" />
                            <div class="buttonValider">Valider</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { notNullCheck, checkNumeroTel } from '@/tools/inputCheck'
import { notificationLogout } from '@/tools/authent'
import moment from 'moment'
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import * as Roles from '@/tools/Roles'

export default {
    name: 'PopupNumeroLong',
    components: {
        HelpWidget,
    },
    data() {
        return {
            modal: false,
            newTel: '',
            newTelConfirm: '',
            dateActif: '',
            notSame: false,
        }
    },
    props: {
        idCaau: {
            type: String,
            default: '',
        },
        numLongActuel: {
            type: String,
            default: '',
        },
    },
    mounted() {
        // Ajout de 16 jours pour respecter les délais
        if (!this.isAdmin) {
            document
                .getElementById('dateActif')
                .setAttribute(
                    'min',
                    moment().add(16, 'days').toISOString().split('T')[0]
                )
            this.dateActif = moment().add(16, 'days').format('YYYY-MM-DD')
        }
    },
    methods: {
        checkTelConfirm() {
            this.notSame = this.newTel !== this.newTelConfirm
            let inputTel = this.$refs['newTelConfirm']
            if (this.notSame) {
                inputTel.classList.add('input_red')
            } else {
                inputTel.classList.remove('input_red')
            }
        },
        //Lors de la validation popup devient invisible et envoie la confirmation
        valider() {
            let formulaireValid = true

            //Vérification des champs
            let inputDate = this.$refs['dateActif']
            if (!notNullCheck(this.dateActif)) {
                formulaireValid = false
                inputDate.classList.add('input_red')
            } else {
                inputDate.classList.remove('input_red')
            }

            let inputTel = this.$refs['newTel']
            if (!notNullCheck(this.newTel)) {
                formulaireValid = false
                inputTel.classList.add('input_red')
            } else {
                inputTel.classList.remove('input_red')
            }
            if (
                !checkNumeroTel(
                    this.newTel,
                    this.$store.state.dptSelect.codeInsee
                )
            ) {
                formulaireValid = false
                inputTel.classList.add('input_red')
                this.$notify({
                    group: 'general-notification',
                    title:
                        'Le numéro de téléphone ' +
                        this.newTel +
                        " n'est pas au bon format.",
                    text: 'Cliquez sur ? pour les informations.',
                    type: 'error',
                    duration: 5000,
                })
            } else {
                inputTel.classList.remove('input_red')
            }

            if (this.newTel === this.numLongActuel) {
                formulaireValid = false
                inputTel.classList.add('input_red')
                this.$notify({
                    group: 'general-notification',
                    title: 'Le nouveau numéro long doit être différent du numéro long actuel.',
                    type: 'warn',
                    duration: 5000,
                })
            }

            this.checkTelConfirm()

            if (formulaireValid && !this.notSame) {
                // Ajout de 30j sur la date ajoutée
                let jusquau = moment(this.dateActif)
                    .add(31, 'days')
                    .format('DD/MM/YYYY')

                axios
                    .post(
                        '/caau/modificationnumerolong',
                        {
                            idCaau: this.idCaau,
                            numeroLongAncien: this.numLongActuel,
                            numeroLongNouveau: this.newTel,
                            a_compter_du: moment(this.dateActif).format(
                                'DD/MM/YYYY'
                            ),
                            jusqu_au: jusquau,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            EventBus.$emit(eventTypes.LOADED_NUMERO_LONG, {
                                numeroLongAncien: this.numLongActuel,
                                numeroLongNouveau: this.newTel,
                                a_compter_du: this.dateActif,
                                jusqu_au: jusquau,
                            })
                            this.$notify({
                                group: 'general-notification',
                                title: 'Le numéro a bien été ajouté',
                                text: 'Aller dans l\'onglet "Publication => publier" pour que les changements soient pris en compte définitivement.',
                                type: 'success',
                                duration: 8000,
                            })
                            this.modal = false
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: e.response.data
                                    ? e.response.data
                                    : "Vous n'avez pas l'autorisation de modifier un caau",
                                type: 'error',
                                duration: 5000,
                            })
                        } else {
                            notificationLogout(this, e)
                        }
                    })
            }
        },
        //Lors de l'annulation, popup devient invisible
        fermer() {
            this.modal = false
        },
    },
    computed: {
        isAdmin() {
            return this.$store.state.user.profil === Roles.ADMINISTRATEUR
        },
    },
}
</script>

<style scoped>
.padding-bottom-7-px {
    padding-bottom: 7px;
}

.windowModal {
    align-items: flex-start;
}

.contentModal {
    padding-left: 10px;
}

.formInput {
    display: inline-flex;
    flex-direction: column;
    font-family: 'Marianne Regular';
    font-size: 14px;
    padding-block: 20px;
}

.textInput {
    padding-left: 10px;
}

.dateInput {
    padding-left: 25px;
    padding-right: 10px;
}

input {
    border-radius: 5px;
    border: none;
    height: 40px;
    padding-left: 15px;
    width: 315px;
    background: #e9e8f8;
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.input_red {
    border: 1px solid #ff0000 !important;
}

.textInput > input {
    width: 196px;
    height: 41px;
}

.dateInput > input {
    width: 192px;
    height: 41px;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background: url('../../../assets/img/tnaau/Icon_calendar.svg') no-repeat;
}

.buttonRed {
    width: 110px;
    padding-left: 15px;
}

.buttonValider {
    padding-left: 10px;
}
</style>

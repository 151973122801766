<template>
    <section class="flex flex-column items-center">
        <div class="inline-flex searchFlex padding-bottom-30-px">
            <div class="container-inputs">
                <SearchField
                    class="searchField"
                    :smallButton="true"
                    background="#EEF0F6"
                    :isHelp="true" />
                <div>
                    <button
                        class="
                            bouton
                            exportBoutonNumeros
                            background_rouge
                            texte_blanc
                            no-border
                        "
                        @click="rapportNumerosCommunicables">
                        Exporter ODS
                    </button>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <td class="headerId pointer" @click="sort('num_court')">
                        N° court
                        <img
                            src="../../../assets/img/tnaau/Icon_up.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowNumCourt === 'asc' }"
                            v-if="arrowNumCourt != 'none'" />
                    </td>
                    <td
                        class="headerdata uppercase cornerLeft pointer"
                        @click="sort('identifiant_caau')">
                        ID CAAU
                        <img
                            src="../../../assets/img/tnaau/Icon_up_blue.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowIdCaau === 'desc' }"
                            v-if="arrowIdCaau != 'none'" />
                    </td>
                    <td
                        class="cornerRight headerdata uppercase pointer"
                        @click="sort('num_long_sec')">
                        Numéro alternatif communicable
                        <img
                            src="../../../assets/img/tnaau/Icon_up.svg"
                            class="line_arrow"
                            :class="{
                                rotate180: arrowNumLongSecondaire === 'asc',
                            }"
                            v-if="arrowNumLongSecondaire != 'none'" />
                    </td>
                </tr>
            </thead>
            <tbody class="shadowBlue cornerLeft">
                <tr v-for="item in this.caauList" :key="item.id">
                    <td class="subHeader uppercaseBlack">
                        {{ item.numeroCourt }}
                    </td>
                    <td class="subHeader uppercase pointer" @click="goto(item)">
                        {{ item.identifiantCaau }}
                    </td>
                    <td class="subHeader uppercase">
                        {{ item.numeroLongSecondaire }}
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import axios from 'axios'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import SearchField from '@/components/widgets/searchField/SearchField'
import { notificationLogout } from '../../../tools/authent'
import download from 'downloadjs'

export default {
    name: 'CaauGridAvecNum',
    components: { SearchField },
    data() {
        return {
            caauList: [],
            caauTotal: 1,
            offset: 0,
            arrowIdCaau: 'none',
            arrowNumCourt: 'asc',
            arrowNumLongSecondaire: 'none',
            limit: 10,
        }
    },
    filters: {
        toLocaleString(value) {
            return `${value.toLocaleString()}`
        },
    },
    methods: {
        goto(item) {
            window.location.href = `#/caaudetail?id=${item.idCaau}&idDepartement=${item.idDepartement}`
        },
        sort(value) {
            switch (value) {
                case 'identifiant_caau':
                    if (this.arrowIdCaau === 'asc') {
                        this.arrowIdCaau = 'desc'
                    } else {
                        this.arrowIdCaau = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowIdCaau
                    this.arrowNumCourt = 'none'
                    this.arrowNumLongSecondaire = 'none'
                    break
                case 'num_court':
                    if (this.arrowNumCourt === 'asc') {
                        this.arrowNumCourt = 'desc'
                    } else {
                        this.arrowNumCourt = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowNumCourt
                    this.arrowIdCaau = 'none'
                    this.arrowNumLongSecondaire = 'none'
                    break
                case 'num_long_sec':
                    if (this.arrowNumLongSecondaire === 'asc') {
                        this.arrowNumLongSecondaire = 'desc'
                    } else {
                        this.arrowNumLongSecondaire = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowNumLongSecondaire
                    this.arrowIdCaau = 'none'
                    this.arrowNumCourt = 'none'
                    break
            }
            this.$store.state.sort = value
            this.$store.commit('saveStore')
            EventBus.$emit(eventTypes.LOADED_TABLE_CAAU, {
                departement: this.dept,
                filtre: this.$store.state.search,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
            })
        },
        rapportNumerosCommunicables() {
            axios
                .get('/getOdsDocument/' + this.dept, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                    responseType: 'blob',
                })
                .then((response) => {
                    if (response.status === 200) {
                        var filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        filenameBrut = filenameBrut.replace('filename=', '')
                        var filename = filenameBrut.split('"').join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/octet-stream'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: "Un problème est survenu durant le téléchargement de l'archive",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de télécharger la publication",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_TABLE_CAAU, (obj) => {
            this.dept = obj.departement
            //Recherche liste CAAU par département
            axios
                .get(
                    '/caau/?id=' +
                        this.dept +
                        '&filtre=' +
                        obj.filtre +
                        '&sort=' +
                        obj.sort +
                        '&sortorder=' +
                        obj.sortorder,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.data) {
                        this.caauList = response.data.data
                        this.caauTotal = response.data.total
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation d'accéder à ce département",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_TABLE_CAAU)
    },
}
</script>
<style scoped>
table {
    max-width: 1000px;
    border-collapse: collapse;
    word-break: break-word;
}

table thead {
    position: sticky;
    top: 0;
}

table tbody {
    display: block;
    max-height: 450px;
    overflow-y: scroll;
}

table thead,
table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

tr {
    background-color: #fbfaff;
}

tbody td:not(:last-child) {
    border-right: 1px solid;
}

.exportBoutonNumeros {
    width: 150px;
    margin-top: 10px;
}

.container-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* Si écran touch */
@media (pointer: coarse) {
    tbody {
        margin-right: 0;
    }
}
</style>

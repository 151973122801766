<template>
    <section>
        <div class="backgroundModal" v-show="this.modal">
            <div class="windowModal">
                <div class="headerModal">
                    <div class="inline-flex" />
                    <div class="inline-flex"></div>
                    <div class="inline-flex pointer" @click="fermer()">X</div>
                </div>
                <div
                    class="contentModal"
                    v-html="this.titre"
                    style="text-align: center"></div>
                <div style="margin-bottom: 1em">
                    Souhaitez-vous forcer les dates ("A compter du" et
                    "Jusqu'au") même si elles ne respectent pas les délais
                    légaux ?
                    <input type="checkbox" v-model="bypass" />
                </div>
                <div class="footerModal">
                    <div
                        class="annulerModal pointer no-select"
                        @click="fermer()">
                        Annuler
                    </div>
                    <ButtonWidget
                        text="Confirmer"
                        :small-button="true"
                        bgcolor="#E1000F"
                        logo="Icon_valider.svg"
                        @click.native="valider()"
                        :enable="true"
                        :width="150" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'

export default {
    name: 'PopupAdmin',
    components: { ButtonWidget },
    data() {
        return {
            modal: false,
            bypass: false,
        }
    },
    props: {
        titre: {
            type: String,
            default: 'titre',
        },
    },
    methods: {
        //Lors de la validation popup devient invisible et envoie la confirmation avec la valeur de bypass
        valider() {
            this.modal = false
            if (this.bypass) {
                EventBus.$emit(eventTypes.MODAL_VALIDE_POPUP_BYPASS, true)
            } else {
                EventBus.$emit(eventTypes.MODAL_VALIDE_POPUP_BYPASS, false)
            }
        },
        //Lors de l'annulation, popup devient invisible
        fermer() {
            this.modal = false
            EventBus.$emit(eventTypes.MODAL_VALIDE_POPUP, false)
        },
    },
}
</script>

<style scoped></style>

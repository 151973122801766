<template>
    <section class="section">
        <div class="no-select">
            <div class="inline-flex">
                <SearchFieldPdaa />
                <div>
                    <button
                        class="
                            bouton
                            exportBoutonNumeros
                            background_rouge
                            texte_blanc
                            no-border
                        "
                        @click="rapportPdaa">
                        Exporter ODS
                    </button>
                </div>
                <button
                    class="
                        arrowLimit
                        inline-flex-center
                        no-select no-border
                        margin-left-20-px
                        pointer
                    "
                    :class="{ rotate180: true, opaque: this.isOpaqueLeft }"
                    v-show="this.pdaaTotal > this.limit"
                    @click="page('previous')">
                    <img
                        src="../../../assets/img/tnaau/Icon_circle_button.svg" />
                </button>
                <div
                    class="font-marianne-thin inline-flex-center"
                    v-show="this.pdaaTotal > this.limit">
                    {{ this.numeroPage }}/{{ this.nbrPageTotal }}
                </div>
                <button
                    class="
                        arrowLimit
                        inline-flex-center
                        no-select no-border
                        pointer
                    "
                    :class="{ rotate180: false, opaque: this.isOpaqueRight }"
                    v-show="this.pdaaTotal > this.limit"
                    @click="page('next')">
                    <img
                        src="../../../assets/img/tnaau/Icon_circle_button.svg" />
                </button>
            </div>
            <div
                class="horizontal-scroll-except-first-column"
                v-show="this.pdaaListe.length !== 0">
                <table>
                    <thead>
                        <tr>
                            <td class="colorFond"></td>
                            <td
                                class="headertable uppercase rowNumUrgence"
                                :class="{
                                    cornerLeft: index === 0,
                                    cornerRight:
                                        index === numeroCourtListe.length - 1,
                                }"
                                v-for="(key, index) in numeroCourtListe"
                                :key="key.id">
                                {{ key.numeroCourt }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="tdheader cornerLeft columnCommuneHead">
                                Insee Ville
                            </th>
                            <th
                                class="tdheader columnIdentifiant"
                                v-for="key in numeroCourtListe"
                                :key="key.id">
                                Identifiant
                            </th>
                        </tr>
                        <tr
                            class="pdaauListe"
                            v-for="item in pdaaListe"
                            :key="item.id">
                            <td class="tdBorderBlack columnCommune">
                                {{ item[0].codeInsee }}
                                <span class="nospace">{{
                                    item[0].nomCommune
                                }}</span>
                            </td>
                            <td
                                class="tdBorderBlack columnIdentifiant pointer"
                                v-for="key in item"
                                :key="key.id"
                                @click="goto(key)">
                                <span
                                    @mouseover="
                                        modificationPositionTooltip(
                                            $event,
                                            key,
                                            true
                                        )
                                    "
                                    @mouseleave="
                                        ;(showTooltip = false), (pdaa = {})
                                    ">
                                    {{ key.identifiantCaau }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="element"
                v-show="
                    this.pdaaListe.length === 0 && !this.requestTokenSource
                ">
                Aucune donnée trouvée.
            </div>
            <div
                class="element"
                v-show="this.pdaaListe.length === 0 && this.requestTokenSource">
                Chargement des données en cours...
            </div>
            <div id="tooltipNum" class="tooltip no-select">
                <TooltipNumero :pdaa="this.pdaa" v-if="showTooltip" />
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types.js'
import { notificationLogout } from '../../../tools/authent'
import TooltipNumero from '@/components/widgets/tooltipNumero/TooltipNumero'
import SearchFieldPdaa from '@/components/widgets/searchFieldPdaa/SearchFieldPdaa'
import download from 'downloadjs'

export default {
    name: 'PdaaGrid',
    components: { TooltipNumero, SearchFieldPdaa },
    data() {
        return {
            showTooltip: false,
            pdaa: {},
            requestTokenSource: null,
            pdaaListe: [],
            numeroCourtListe: [],
            offset: 0,
            limit: 10,
            pdaaTotal: 1,
            isOpaqueRight: false,
            isOpaqueLeft: false,
            nbrPageTotal: 0,
            numeroPage: 0,
        }
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_TABLE_PDAA, (obj) => {
            this.isOpaqueRight = false
            this.isOpaqueLeft = false
            this.offset = obj.offset
            this.limit = obj.limit
            let search = obj.search

            // Clear les résultats pour afficher le message de status
            this.pdaaListe = []

            if (this.offset === 0) {
                this.isOpaqueLeft = true
            } else if (this.offset + this.limit >= this.pdaaTotal) {
                this.isOpaqueRight = true
            }
            if (search && search.includes('+')) {
                search = search.replace('+', '%2B')
            }

            // Si une autre requête était en cours alors on l'annule
            if (this.requestTokenSource) this.requestTokenSource.cancel()
            this.requestTokenSource = axios.CancelToken.source()

            axios
                .get(
                    '/pdaau/identifiant/' +
                        this.$store.state.dptSelect.idDepartement +
                        '?offset=' +
                        this.offset +
                        '&limit=' +
                        this.limit +
                        '&filter=' +
                        search,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                        cancelToken: this.requestTokenSource.token,
                    }
                )
                .then((response) => {
                    this.requestTokenSource = null
                    if (response.status === 200) {
                        this.pdaaListe = response.data.mapPdau
                        this.pdaaTotal = response.data.mapPdauSize
                        this.numeroCourtListe = response.data.numeroListe
                        this.nbrPageTotal = Math.ceil(
                            this.pdaaTotal / this.limit
                        )
                        this.numeroPage = this.offset / this.limit + 1
                    }
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) notificationLogout(this, e)
                })
        })
    },
    methods: {
        modificationPositionTooltip(event, key, isShow) {
            var tailleY = event.clientY
            var tailleX = event.clientX - 115
            this.pdaa = key
            this.showTooltip = isShow

            if (
                null !== this.pdaa.mea_numero_long_nouveau &&
                this.pdaa.mea_numero_long_nouveau
            ) {
                tailleY -= 150
            } else {
                if (
                    null !== this.pdaa.numero_long_ancien &&
                    this.pdaa.numero_long_ancien !== ''
                ) {
                    tailleY -= 150
                } else {
                    tailleY -= 80
                }
            }
            document.getElementById('tooltipNum').style.top = tailleY + 'px'
            document.getElementById('tooltipNum').style.left = tailleX + 'px'
        },
        goto(key) {
            window.location.href = `#/caaudetail?id=${key.idCaau}&idDepartement=${key.idDepartement}`
        },
        page(value) {
            if (value === 'next') {
                if (!this.isOpaqueRight) {
                    this.offset += 10
                }
            } else if (value === 'previous') {
                if (this.offset >= 10) {
                    this.offset -= 10
                }
            }
            EventBus.$emit(eventTypes.LOADED_TABLE_PDAA, {
                offset: this.offset,
                limit: this.limit,
                search: this.$store.state.search,
            })
        },
        rapportPdaa() {
            if (this.$store.state.dptSelect.idDepartement === 0) {
                this.$notify({
                    group: 'general-notification',
                    text: 'La génération du fichier prendra un certain temps.',
                    type: 'info',
                    duration: 5000,
                })
            }
            axios
                .get(
                    '/getOdsDocumentFromCAAU/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        var filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        filenameBrut = filenameBrut.replace('filename=', '')
                        var filename = filenameBrut.split('"').join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/octet-stream'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Un problème est survenu durant le téléchargement du fichier ods',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de télécharger le récapitulatif des PDAAU",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_TABLE_PDAA)
    },
}
</script>
<style scoped>
.tooltip {
    position: absolute;
    /* top: 180px; */
    z-index: 1;
}

.horizontal-scroll-except-first-column {
    max-width: calc(100vw - 270px - 6rem);
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 35px;
}

.horizontal-scroll-except-first-column > table > * > tr > th:first-child,
.horizontal-scroll-except-first-column > table > * > tr > td:first-child {
    position: sticky;
    left: 0;
}

.nospace {
    word-spacing: normal;
}

.colorFond {
    background-color: #fbfaff;
    height: 50px;
}

.columnCommuneHead {
    padding-left: 15px;
    padding-top: 15px;
    word-spacing: 25px;
    white-space: nowrap;
    text-align: left;
    box-shadow: 5px -5px 8px -5px #393bda3c, -5px -5px 8px -5px #393bda3c;
    background-color: #fbfaff;
}

.columnCommune {
    word-spacing: 25px;
    white-space: nowrap;
    text-align: left;
    box-shadow: 5px 0px 8px -5px #393bda3c, -5px 0px 8px -5px #393bda3c;
    background-color: #fbfaff;
}
.rowNumUrgence {
    column-width: 80px;
}

.columnIdentifiant {
    border: 1px #e9e8f8 solid;
    column-width: 80px;
}

table {
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;
}

.headertable {
    height: 50px;
    font-family: 'Marianne Medium';
    font-size: 16px;
    background-color: #484d7a;
    color: #ffffff;
}

.tdheader {
    font-family: 'Marianne Medium';
    font-size: 14px;
    color: #000091;
    height: 50px;
}

.tdBorderBlack {
    font-family: 'Marianne Light';
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.pdaauListe {
    height: 40px;
}

.exportBoutonNumeros {
    width: 150px;
    margin-left: 1em;
}
</style>

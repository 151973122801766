<template>
    <section class="main">
        <div class="middle-container flex-row-center">
            <div class="left_block inline-flex-column-left">
                <div class="logo_tnaau" @click="goto('/')" />
                <div class="titre-tnaau">
                    Table nationale d’acheminement des appels d’urgence
                </div>
            </div>
            <form
                @submit.prevent="clickReset"
                class="right_block inline-flex-column-center">
                <div class="flex-row padding-bottom-40-px">
                    <div class="ligne_connexion" />
                    <div class="titre-connexion">Mot de passe perdu</div>
                    <div class="ligne_connexion" />
                </div>
                <div
                    class="inline-flex-column-left padding-bottom-20-px"
                    v-show="displayOn">
                    <label for="username" class="titre-champ">Email</label>
                    <input
                        type="email"
                        id="username"
                        autocomplete="username"
                        class="input_custom background_gris_clair"
                        v-model="email"
                        placeholder="Email"
                        @input="checkMail"
                        ref="inputEmail" />
                </div>
                <div class="zone_bouton flex-row">
                    <button
                        class="bouton background_rouge texte_blanc no-border"
                        v-show="displayOn">
                        Réinitialiser
                    </button>
                    <button
                        type="button"
                        class="
                            bouton
                            background_gris
                            texte_blanc
                            no-border
                            margin-left-30-px
                        "
                        @click="goto('/')"
                        v-show="displayOn">
                        Retour
                    </button>
                    <button
                        type="button"
                        class="
                            bouton
                            background_vert
                            texte_blanc
                            no-border
                            margin-left-30-px
                        "
                        @click="goto('/')"
                        v-show="!displayOn">
                        Lien envoyé
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { emailCheck } from '../../tools/inputCheck'
import { checkInput } from '../../tools/inputCheck'

export default {
    name: 'LostPassword',
    components: {},
    data() {
        return {
            email: '',
            displayOn: true,
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        checkMail() {
            checkInput(this.$refs['inputEmail'], emailCheck(this.email))
        },
        clickReset() {
            if (!emailCheck(this.email)) {
                this.$notify({
                    group: 'general-notification',
                    title: 'Erreur',
                    text: "L'email n'est pas valide.",
                    type: 'warn',
                    duration: 5000,
                })
                return
            }
            axios
                .get('/lostpassword?email=' + this.email)
                .then(() => {
                    this.displayOn = false
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur',
                            text: 'Permissions non accordées',
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur serveur',
                            text: "Erreur lors de l'éxecution de la requête",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                })
        },
    },
}
</script>

<style scoped>
/* container principal pour le login */

.left_block {
    width: 500px;
    height: 250px;
}

.right_block {
    width: 400px;
    height: 300px;
}

/* block droit (login connexion) */

.input_custom {
    width: 340px;
}
</style>

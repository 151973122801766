<template>
    <div class="templateItem" style="width: 300px">
        <b>{{ item.identifiantCaau }} ({{ item.numeroLong }})</b>
    </div>
</template>

<script>
export default {
    props: {
        item: { required: true },
        searchText: { required: true },
    },
}
</script>
<style scoped>
.templateItem {
    background-color: #ffffff;
    width: 250px;
    padding: 7px 0 7px 5px;
    font-family: 'Marianne Thin';
}

.templateItem:hover {
    background: #cccfea;
    opacity: 1;
    color: #4143df;
    font-family: 'Marianne Thin';
}
</style>

<template>
    <div class="titre">
        {{ this.texte }}
    </div>
</template>

<script>
export default {
    name: 'TitrePage',
    data() {
        return {}
    },
    props: {
        texte: {
            type: String,
        },
    },
}
</script>

<style scoped>
.titre {
    display: inline-flex;
    justify-content: left;
    font-family: 'Marianne Thin';
    font-size: 36px;
    text-transform: uppercase;
    max-width: 60%;
}
</style>

<template>
    <section class="container-400px">
        <button
            class="bouton small no-border background_rouge texte_blanc"
            @click="goto('/#/caauCreation')"
            v-if="this.smallButton">
            <img
                src="../../../assets/img/tnaau/Icon_plus.svg"
                class="margin-right-10-px" />
            Créer un centre
        </button>
        <button
            class="bouton big no-border background_rouge texte_blanc"
            @click="goto('/#/caauCreation')"
            v-if="!this.smallButton">
            <img
                src="../../../assets/img/tnaau/Icon_plus.svg"
                class="margin-right-10-px" />
            Créer un centre
        </button>
    </section>
</template>

<script>
export default {
    name: 'ButtonCaauCreation',
    data() {
        return {}
    },
    props: {
        smallButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
    },
}
</script>

<style scoped>
.small {
    width: 195px;
}

.big {
    min-height: 64px;
    width: 100%;
    max-width: 400px;
    justify-content: left;
    padding: 0 2rem;
    font-family: 'Marianne Medium';
}
</style>

<template>
    <div class="container overflow_scroll no-select" ref="innerContainer">
        <TitreReference :reference="'TABLEAUX DE BORD'" />
        <hr class="ligneSeparation" />

        <div class="titre-tableau padding-top-20-px">
            Liste des centres nationaux
        </div>
        <button
            class="bouton exportBouton background_rouge texte_blanc no-border"
            @click="rapportListeCentreNationaux">
            Exporter CSV
        </button>

        <hr class="ligneSeparation" />

        <div class="titre-tableau">Fiche commune</div>
        <div class="formInput">
            <div class="labelHistorique">Commune</div>
            <autocomplete
                class="inputSearch"
                :items="this.listeCommunes"
                @item-clicked="selectionCom"
                item-text="Commune"
                item-value="idCommune"
                v-model="communeFiche"
                :input-attrs="{
                    autofocus: false,
                    placeholder: 'Commune',
                }"
                @change="checkCommune"
                :component-item="template"
                :auto-select-one-item="false"
                ref="communeFiche" />
        </div>
        <button
            class="bouton background_rouge texte_blanc no-border exportBouton"
            @click="ficheCommune">
            Exporter PDF
        </button>

        <hr class="ligneSeparation" />

        <div class="titre-tableau">Historique des modifications</div>
        <div class="formInput">
            <label for="idDepartementHistorique" class="labelHistorique"
                >Département</label
            >
            <select
                v-model="idDepartementHistorique"
                class="selectForm"
                ref="departementHistorique">
                <option
                    class="optionForm"
                    v-for="option in this.departements"
                    :key="option.idDepartement"
                    :value="option.idDepartement">
                    {{ option.nom }} ({{ option.codeInsee }})
                </option>
            </select>
        </div>
        <div class="flex flex-wrap gap-1 padding-top-20-px">
            <div class="formInput">
                <label for="dateDebut" class="labelHistorique">Début</label>
                <input
                    id="dateDebut"
                    v-model="dateDebut"
                    type="date"
                    name="dateDebut"
                    ref="dateDebut"
                    class="texte_noir"
                    :max="dateFin"
                    @change="changeDate('debut')" />
            </div>
            <div class="formInput">
                <label for="dateFin" class="labelHistorique">Fin</label>
                <input
                    id="dateFin"
                    v-model="dateFin"
                    type="date"
                    name="dateFin"
                    ref="dateFin"
                    class="texte_noir"
                    :min="dateDebut"
                    @change="changeDate('fin')" />
            </div>
        </div>
        <button
            class="bouton background_rouge texte_blanc no-border exportBouton"
            @click="historiqueModifications">
            Exporter CSV
        </button>

        <hr class="ligneSeparation" />

        <div class="titre-tableau">Rapport départemental</div>
        <div class="formInput">
            <label for="idDepartementRapport" class="labelHistorique"
                >Département</label
            >
            <select
                v-model="idDepartementRapport"
                class="selectForm"
                ref="departementRapport">
                <option
                    class="optionForm"
                    v-for="option in this.departements"
                    :key="option.idDepartement"
                    :value="option.idDepartement">
                    {{ option.nom }} ({{ option.codeInsee }})
                </option>
            </select>
        </div>
        <button
            class="bouton background_rouge texte_blanc no-border exportBouton"
            @click="rapportDepartemental">
            Exporter PDF
        </button>
    </div>
</template>
<script>
import axios from 'axios'
import * as eventTypes from '../../bus/event-types.js'
import EventBus from '../../bus'
import { notificationLogout } from '../../tools/authent'
import download from 'downloadjs'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import { notNullCheck } from '../../tools/inputCheck'
import { checkInput } from '../../tools/inputCheck'
import moment from 'moment'
import Autocomplete from 'v-autocomplete'
import ItemTemplate from '@/components/widgets/template/CommuneTemplate.vue'

export default {
    name: 'Tableau',
    components: { TitreReference, Autocomplete },
    data() {
        return {
            dateDebut: '',
            dateFin: '',
            dateDebutSave: '',
            dateFinSave: '',
            idDepartementHistorique: '',
            idDepartementRapport: '',
            departements: {},
            communeFiche: null,
            communeFicheId: '',
            listeCommunes: [],
            template: ItemTemplate,
        }
    },
    mounted() {
        this.extendHeightMenu()
        EventBus.$on(eventTypes.WINDOWS_RESIZE, this.extendHeightMenu)
        this.$refs.innerContainer.addEventListener('scroll', this.updateScroll)
        this.recupereDepartement()
    },
    methods: {
        changeDate(action) {
            if (action === 'debut') {
                this.dateDebutSave = moment(this.dateDebut).format('DD/MM/YYYY')
            } else {
                this.dateFinSave = moment(this.dateFin)
                    .add(1, 'days')
                    .format('DD/MM/YYYY')
            }
        },
        historiqueModifications() {
            let inputDateDebut = this.$refs['dateDebut']
            let isDateDebut = checkInput(
                inputDateDebut,
                notNullCheck(this.dateDebutSave)
            )

            let inputDateFin = this.$refs['dateFin']
            let isDateFin = checkInput(
                inputDateFin,
                notNullCheck(this.dateFinSave)
            )

            let inputDepartement = this.$refs.departementHistorique
            let isDepartement = checkInput(
                inputDepartement,
                notNullCheck(this.idDepartementHistorique)
            )

            if (isDateDebut && isDateFin && isDepartement) {
                axios
                    .get(
                        '/historiquemodifications/?idDepartement=' +
                            this.idDepartementHistorique +
                            '&dateDebut=' +
                            this.dateDebutSave +
                            '&dateFin=' +
                            this.dateFinSave,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            var filenameBrut =
                                '' +
                                response.headers['content-disposition'].match(
                                    /filename="(.*)"$/g
                                )
                            filenameBrut = filenameBrut.replace('filename=', '')
                            var filename = filenameBrut.split('"').join('')
                            download(
                                new Blob([response.data]),
                                filename,
                                'application/octet-stream'
                            )
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Attention',
                                text: 'Un problème est survenu lors de la récupération du rapport',
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 404) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Attention',
                                text: 'Aucun rapport trouvé',
                                type: 'warn',
                                duration: 5000,
                            })
                        } else {
                            notificationLogout(this, e)
                        }
                    })
            }
        },
        rapportListeCentreNationaux() {
            axios
                .get('/centrenationaux/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        var filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        filenameBrut = filenameBrut.replace('filename=', '')
                        var filename = filenameBrut.split('"').join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/octet-stream'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: 'Un problème est survenu lors de la récupération du rapport',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: "Aucun CAAU national n'a été trouvé.",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        recupereDepartement() {
            //Récupère tout les départements
            axios
                .get('departementutilisateur', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.departements = response.data
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Inconnu',
                            text: "Le compte recherché n'existe pas",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        rapportDepartemental() {
            let inputDepartement = this.$refs.departementRapport
            let isDepartement = checkInput(
                inputDepartement,
                notNullCheck(this.idDepartementRapport)
            )
            if (isDepartement) {
                this.$notify({
                    group: 'general-notification',
                    text: 'En cours de téléchargement',
                    type: 'info',
                    duration: 5000,
                })
                axios
                    .get(
                        '/rapportdepartementale/' + this.idDepartementRapport,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                            responseType: 'blob',
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            var filenameBrut =
                                '' +
                                response.headers['content-disposition'].match(
                                    /filename="(.*)"$/g
                                )
                            filenameBrut = filenameBrut.replace('filename=', '')
                            var filename = filenameBrut.split('"').join('')
                            download(
                                new Blob([response.data]),
                                filename,
                                'application/octet-stream'
                            )
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Attention',
                                text: 'Un problème est survenu lors de la récupération du rapport',
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        notificationLogout(this, e)
                    })
            }
        },
        selectionCom(item) {
            // On récupère le nom et l'id de la commune sélectionné et on remet le border-radius en bas de l'input Ville
            if (item.idCommune !== null && item.nom !== null) {
                this.communeFiche = item.nom
                this.communeFicheId = item.idCommune
            }
        },
        checkCommune(text) {
            if (text.length > 2) {
                axios
                    .get('/commune/autorize?nomCommune=' + text, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.listeCommunes = response.data
                        } else {
                            this.listeCommunes = []
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de récupérer la liste des communes",
                                type: 'error',
                            })
                        }
                        notificationLogout(this, e)
                    })
            } else {
                this.listeCommunes = []
            }
        },
        ficheCommune() {
            let isCommune = checkInput(
                this.$refs.communeFiche.$el.firstChild,
                notNullCheck(this.communeFicheId)
            )

            if (isCommune) {
                axios
                    .get('/fichecommune/' + this.communeFicheId, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                        responseType: 'blob',
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            var filenameBrut =
                                '' +
                                response.headers['content-disposition'].match(
                                    /filename="(.*)"$/g
                                )
                            filenameBrut = filenameBrut.replace('filename=', '')
                            var filename = filenameBrut.split('"').join('')
                            download(
                                new Blob([response.data]),
                                filename,
                                'application/octet-stream'
                            )
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Attention',
                                text: 'Un problème est survenu lors de la récupération du rapport',
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        notificationLogout(this, e)
                    })
            }
        },
        updateScroll() {
            EventBus.$emit(eventTypes.MAIN_SCROLL_AREA, {
                y: this.$refs.innerContainer.scrollTop,
            })
        },
        extendHeightMenu() {
            if (this.$refs.innerContainer)
                this.$refs.innerContainer.style.height =
                    window.innerHeight -
                    this.$refs.innerContainer.getBoundingClientRect().top +
                    'px'
        },
    },
    beforeDestroy() {
        this.$refs.innerContainer.removeEventListener(
            'scroll',
            this.updateScroll
        )
    },
    destroyed() {
        EventBus.$off(eventTypes.WINDOWS_RESIZE)
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inputSearch {
    border-radius: 5px;
    background: #e9e8f8;
}

.inputSearch > .v-autocomplete-list {
    overflow-y: scroll;
    height: 150px;
}

.overflow_scroll {
    overflow-y: scroll;
    width: 100%;
}

.titre-tableau {
    font-family: 'Marianne Medium';
    font-size: 18px;
    margin-bottom: 15px;
}

.bouton {
    width: 150px;
    height: 40px;
    font-family: 'Marianne Regular';
}

.exportBouton {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
}

.ligneSeparation {
    margin: 1rem 0;
}

.labelHistorique {
    padding: 10px 10px 0 0;
    min-width: 100px;
}

.formInput {
    display: inline-flex;
    font-family: 'Marianne Regular';
    font-size: 14px;
    padding-top: 10px;
}

input {
    border-radius: 5px;
    border: none;
    height: 41px;
    padding-left: 15px;
    width: 192px;
    background: #e9e8f8;
    font-family: 'Marianne Medium';
    font-size: 14px;
}
</style>

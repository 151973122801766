<template>
    <section class="main">
        <div class="middle-container flex-row-center">
            <div class="left_block inline-flex-column-left">
                <div v-if="!isOperateur" class="logo_tnaau" />
                <div v-if="!isOperateur" class="titre-tnaau">
                    Table nationale d’acheminement des appels d’urgence
                </div>
            </div>
            <div class="right_block inline-flex-column-center">
                <div class="flex-row">
                    <div class="ligne_connexion"></div>
                    <div class="titre-connexion">
                        Validation de l'intégration des données
                    </div>
                    <div class="ligne_connexion"></div>
                </div>
                <div
                    class="titre-champ no_padding_left padding-top-20-px"
                    v-show="displayOn">
                    Valider l'intégration du PDAAU pour le département
                    {{ this.codeDep }}
                </div>

                <div class="zone_bouton padding-top-20-px">
                    <div
                        class="titre-champ no_padding_left"
                        v-show="!displayOn">
                        La validation de l'intégration a bien été enregistré
                    </div>
                    <div
                        class="flex row spaceBetween padding-top-20-px"
                        v-show="displayOn">
                        <div
                            class="bouton background_rouge texte_blanc"
                            @click="clickInit">
                            Valider
                        </div>
                        <div class="inline-flex" style="width: 30px">
                            &nbsp;
                        </div>
                        <div
                            class="bouton background_gris texte_blanc"
                            @click="goto('/')">
                            Annuler
                        </div>
                    </div>
                    <div
                        class="bouton background_vert texte_blanc"
                        v-show="!displayOn"
                        @click="goto('/')">
                        Accueil
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { notificationLogout } from '../../tools/authent'
import roles from '../../tools/Roles'

export default {
    name: 'Integration',
    components: {},
    data() {
        return {
            password: '',
            passwordConfirm: '',
            displayOn: true,
            notSame: false,
            codeDep: '',
        }
    },
    mounted() {
        if (this.$router.currentRoute.query.code === undefined) {
            this.goto('/')
        }
        if (this.$router.currentRoute.query.dep === undefined) {
            this.goto('/')
        } else {
            this.codeDep = this.$router.currentRoute.query.dep
        }
        if (this.$router.currentRoute.query.oce === undefined) {
            this.goto('/')
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },

        clickInit() {
            axios
                .post(
                    '/pdaau/integration?code=' +
                        this.$router.currentRoute.query.code +
                        '&dep=' +
                        this.$router.currentRoute.query.dep +
                        '&oce=' +
                        this.$router.currentRoute.query.oce,
                    {}
                )
                .then(() => {
                    this.displayOn = false
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Non autorisé',
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur',
                            text: "Le code n'est pas valide",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    computed: {
        isOperateur() {
            return this.$store.state.user.profil === roles.OPERATEUR
        },
    },
}
</script>

<style scoped>
/* container principal pour le login */

.left_block {
    width: 500px;
    height: 300px;
}

.right_block {
    width: 450px;
    height: 400px;
}

/* block droit (login connexion) */

.titre-connexion {
    align-items: center;
    text-align: center;
    width: 250px;
}

.input_custom {
    width: 340px;
    border-radius: 32px;
    border: transparent 1px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Marianne Regular';
    font-size: 14px;
}

.input_red {
    border: 1px solid #ff0000 !important;
}

.input_custom:focus {
    border: 2px solid #2d3d71;
    outline: 1px transparent solid;
}

.input_custom::placeholder {
    color: #14153c99;
    font-family: 'Marianne Thin';
    font-size: 14px;
}

.input_custom:hover {
    opacity: 0.8;
}

.zone_bouton {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
}

.bouton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    font-family: 'Marianne Regular';
    font-size: 16px;
    width: 206px;
    height: 40px;
    cursor: pointer;
}

.bouton:hover {
    opacity: 0.8;
}

.no_padding_left {
    padding-left: 0 !important;
}
</style>

<template>
    <section class="flex">
        <div class="flex" v-show="this.listAcces.length === 0">
            <ButtonWidget
                :small-button="true"
                bgcolor="#E1000F"
                class="titreBtn"
                logo="Icon_liste.svg"
                text="Afficher la liste"
                @click.native="openSelectionCommune()"
                :enable="true"
                :width="180" />
        </div>
        <div class="flex flex-column" v-show="this.listAcces.length > 0">
            <div class="inline-flex">
                <div class="titreAutorisation uppercase">
                    Affecter des communes
                </div>
            </div>
            <div class="inline-flex">
                <div
                    class="etiquetteCommune"
                    v-for="item in checkedCommunes"
                    :key="item.id">
                    {{ item.codeInsee }}
                </div>
                <div
                    class="titreModifier uppercase pointer"
                    @click="openSelectionCommune()">
                    <img
                        src="../../../assets/img/tnaau/Icon_cadenas_locked.svg" />Modifier
                    les accès
                </div>
            </div>
        </div>
        <WidgetModal
            :titre="'Affecter des communes'"
            :icon="'Icon_cadenas_locked_noir.svg'"
            :modalId="'modalIdCommune'"
            :listes="this.checkedCommunes"
            ref="widgetCommune"
            :boutonText="boutonModal">
            <div v-show="search">
                <autocomplete
                    class="inputSearch"
                    :input-attrs="{
                        placeholder: 'Recherche des communes',
                    }"
                    @change="researchCommunes" />
            </div>
            <div class="deptListScrollable">
                <div v-if="isAllSelection" class="deptListOption">
                    <input
                        type="checkbox"
                        id="checkboxAll"
                        class="checkboxTransfo deptListCheck"
                        @click="selectionAllCommunes()" />
                    <div class="deptListNom bold padding-left-10-px">
                        Tout sélectionner
                    </div>
                </div>
                <div
                    v-for="(item, index) in this.communesFiltrer"
                    :key="item.code"
                    class="deptListOption pointer"
                    :style="getBackground"
                    :class="{ deptListOptionEven: (index & 1) === 0 }">
                    <div class="deptListCheck">
                        <input
                            type="checkbox"
                            :id="'deptNum' + item.id"
                            :checked="inListAcces(item.idCommune)"
                            @click="selectionCommune(item)" />
                    </div>
                    <div class="deptListCode">{{ item.codeInsee }}</div>
                    <div class="deptListNom">{{ item.nom }}</div>
                </div>
            </div>
        </WidgetModal>
    </section>
</template>

<script>
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import WidgetModal from '@/components/widgets/widgetModal/WidgetModal'
import * as eventTypes from '../../../bus/event-types.js'
import EventBus from '../../../bus'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'

export default {
    name: 'WidgetAccesCommune',
    components: { WidgetModal, ButtonWidget, Autocomplete },
    data() {
        return {
            listAcces: [],
            visibleModal: false,
            checkedCommunes: [],
            idCaau: '',
            communesFiltrer: '',
        }
    },
    props: {
        communes: {
            type: Array,
            default: () => [],
        },
        isAllSelection: {
            type: Boolean,
            default: false,
        },
        search: {
            type: Boolean,
            default: false,
        },
        boutonModal: {
            type: String,
            default: "Autoriser l'accès",
        },
    },
    methods: {
        researchCommunes(texte) {
            this.communesFiltrer = this.communes.filter(
                (str) =>
                    str.codeInsee.toUpperCase().indexOf(texte.toUpperCase()) >
                        -1 ||
                    str.nom.toUpperCase().indexOf(texte.toUpperCase()) > -1
            )
        },
        selectionAllCommunes() {
            if (this.checkedCommunes.length === this.communes.length) {
                this.checkedCommunes.splice(0, this.checkedCommunes.length)
            } else {
                this.communes.forEach((commune) => {
                    let item = {
                        codeInsee: commune.codeInsee,
                        idCommune: commune.idCommune,
                        nom: commune.nom,
                    }
                    if (
                        !this.checkedCommunes
                            .map((item) => item.idCommune)
                            .includes(item.idCommune)
                    ) {
                        this.checkedCommunes.push(item)
                    }
                })
            }
            EventBus.$emit(eventTypes.AFFECTE_COMMUNE, this.checkedCommunes)
        },
        //Gére la sélection d'une commune
        selectionCommune(item) {
            let isPresent = false
            let indexCommunes = 0
            this.checkedCommunes.forEach((element) => {
                if (element.idCommune === item.idCommune) {
                    isPresent = true
                    indexCommunes = this.checkedCommunes.indexOf(element)
                }
            })

            if (isPresent) {
                this.checkedCommunes.splice(indexCommunes, 1)
            } else {
                this.checkedCommunes.push(item)
            }

            EventBus.$emit(eventTypes.AFFECTE_COMMUNE, this.checkedCommunes)
        },
        openSelectionCommune() {
            EventBus.$emit(eventTypes.MODAL_VISIBLE, {
                modalId: 'modalIdCommune',
                opened: true,
            })
            this.researchCommunes('')
        },
        inListAcces(num) {
            return this.checkedCommunes
                .map((item) => item.idCommune)
                .includes(num)
        },
    },
    computed: {
        getBackground() {
            return {
                'background-color': '#FFFFFF',
            }
        },
        getBackgroundPopup() {
            return {
                'background-color': this.background,
            }
        },
    },
}
</script>

<style scoped>
.titreAutorisation {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    font-family: 'Marianne Medium';
    font-size: 14px;
    height: 25px;
    margin-right: 5px;
}

.etiquetteCommune {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Marianne Light';
    font-size: 14px;
    width: 41px;
    height: 41px;
    background: #dfe0ed;
    border-radius: 5px;
    margin: 5px;
}

.cadenasLocked,
.titreModifier {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Marianne Regular';
    color: #000091;
    font-size: 14px;
    height: 41px;
    margin: 5px;
}

.deptListScrollable {
    display: inline-flex;
    flex-direction: column;
    font-family: 'Marianne Light';
    font-size: 14px;
    width: 350px;
    height: 350px;
    overflow-y: scroll;
    padding-right: 20px;
}

.deptListOption {
    display: inline-flex;
    flex-direction: row;
    min-height: 40px;
    font-family: 'Marianne Light';
    font-size: 14px;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-left: 5px;
}

.deptListOption:hover {
    background: #e1e2ed !important;
}

.deptListOptionEven {
    background: #eeeffa !important;
}

.deptListCheck {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: 0;
}

.deptListCode {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    font-family: 'Marianne Light';
    font-size: 14px;
}

.deptListNom {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Marianne Light';
    font-size: 14px;
}

.titreBtn {
    padding-top: 10px;
}
</style>

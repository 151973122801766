// import widgets necessary constant message

export const POPUP_CLOSE = 'popup_close'
export const WINDOWS_RESIZE = 'windows_resize'
export const CREATION_IDENTIFIANT_CAAU = 'creation_identifiant_caau'
export const DEPT_CHANGE = 'dept_change'
export const MAIN_SCROLL_AREA = 'main_scroll_area'
export const MODAL_VISIBLE = 'modal_visible'
export const MODAL_VALIDE_POPUP = 'modal_valide_popup'
export const MODAL_VALIDE_POPUP_BYPASS = 'modal_valide_popup_bypass'
export const LOADED_TABLE_CAAU = 'loaded_table_caau'
export const LOADED_TABLE_OPERATEUR = 'loaded_table_operateur'
export const LOADED_TABLE_UTILISATEUR = 'loaded_table_utilisateur'
export const LOADED_NUMERO_LONG = 'loaded_numero_long'
export const BTN_ENABLE = 'btn_enable'
export const BTN_ANNULER = 'btn_annuler'
export const SHOW_STRUCTURE = 'show_structure'
export const SHOW_PREFECTURE_MODIF = 'show_prefecture_modif'
export const LOADED_TABLE_PDAA = 'loaded_table_pdaa'
export const AFFECTE_COMMUNE = 'affecte_commune'
export const AFFECTE_DEPARTEMENT = 'affecte_departement'
export const SHOW_PROFIL_ADMIN = 'show_profil_admin'
export const UPDATE_BTN_FRANCE_ENTIERE = 'UPDATE_BTN_FRANCE_ENTIERE'
export const RESET_SHADOW_STYLE = 'reset_shadow_style'

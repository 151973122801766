<template>
    <div>
        <div id="mapcentre" class="map" />
        <div id="legend" v-if="this.legende !== ''">
            <img :src="legende" />
        </div>
        <ul class="legend" v-if="this.legende === ''">
            <li><span class="red"></span>Communes affectées au CAAU</li>
            <li><span class="grey"></span>Communes non affectées</li>
        </ul>
        <div id="info">&nbsp;</div>
        <div id="popup" class="ol-popup">
            <div id="popup-content" class="popup-detail">
                <div v-if="infoCommune.properties">
                    <h1 class="titre-info">
                        {{ infoCommune.properties.nom_commune }}<br />
                        {{ infoCommune.properties.code_insee }}<br />
                    </h1>
                    <span class="categorie-info">CAAU </span>
                    <span class="detail-info">{{
                        infoCommune.properties.identifiant_caau
                    }}</span
                    ><br />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import WKT from 'ol/format/WKT'
import Overlay from 'ol/Overlay'
import ImageLayer from 'ol/layer/Image.js'
import ImageWMS from 'ol/source/ImageWMS.js'
import { notificationLogout } from '../../../tools/authent'

export default {
    name: 'OlMapCentre',
    components: {},
    data() {
        return {
            map: {},
            sourceNumUrgence: {},
            sourceCaau: {},
            infoCommune: {},
            idNumUrgence: 0,
            sourceInfo: {},
            legende: '',
        }
    },
    props: {
        idNumUrgenceTemp: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        this.idNumUrgence = this.idNumUrgenceTemp
        this.initCarte()
        this.ajoutCouches(this.idNumUrgence, '')
        this.centerMap()
        this.informationCommune(this.idNumUrgence)
        // Elements pour construire la popup.
        const container = document.getElementById('popup')
        this.overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        })
        this.map.addOverlay(this.overlay)
        this.map.on('click', (evt) => {
            this.survoleCommune(evt, this.sourceInfo)
        })
    },
    methods: {
        initCarte() {
            this.map = new Map({
                target: 'mapcentre',
                view: new View({
                    projection: 'EPSG:3857',
                    center: [0, 0],
                    zoom: 2,
                }),
            })
        },
        // Envoi une requête authentifiée à géoserver, récupère l'image, la transforme en blob puis renvoi l'url dans le callback
        queryGeoserverToBlob(src, callback) {
            var request = new XMLHttpRequest()
            request.open('GET', src)
            request.responseType = 'arraybuffer'
            request.setRequestHeader(
                'Authorization',
                `Bearer ${this.$store.state.user.token}`
            )
            request.onload = function () {
                const arrayBufferView = new Uint8Array(request.response)
                const blob = new Blob([arrayBufferView], { type: 'image/png' })
                callback(URL.createObjectURL(blob))
            }
            request.send()
        },
        // Envoi une requête authentifiée à géoserver, récupère le texte et le renvoi dans le callback
        queryGeoserverToText(src, callback) {
            var request = new XMLHttpRequest()
            request.open('GET', src)
            request.setRequestHeader(
                'Authorization',
                `Bearer ${this.$store.state.user.token}`
            )
            request.onload = function () {
                callback(request.response)
            }
            request.send()
        },
        customLoader(tile, src) {
            this.queryGeoserverToBlob(src, (url) => (tile.getImage().src = url))
        },
        ajoutCouches(idNum, idCaau) {
            this.map.removeLayer(this.sourceNumUrgence)
            this.map.removeLayer(this.sourceCaau)
            this.legende = ''

            if (idCaau && idCaau > 0) {
                this.sourceNumUrgence = new ImageLayer({
                    source: new ImageWMS({
                        url: '/geoserver/wms',
                        imageLoadFunction: this.customLoader.bind(this),
                        params: {
                            LAYERS: 'tnaau:pdaau_geom_view_grey',
                            CQL_FILTER:
                                'id_departement=' +
                                this.$store.state.dptSelect.idDepartement +
                                '&&id_numero_urgence=' +
                                idNum,
                        },
                    }),
                })
                this.map.addLayer(this.sourceNumUrgence)

                this.sourceCaau = new ImageLayer({
                    source: new ImageWMS({
                        url: '/geoserver/wms',
                        imageLoadFunction: this.customLoader.bind(this),
                        params: {
                            LAYERS: 'tnaau:pdaau_geom_view_red',
                            CQL_FILTER:
                                'id_departement=' +
                                this.$store.state.dptSelect.idDepartement +
                                '&&id_numero_urgence=' +
                                idNum +
                                '&&id_caau=' +
                                idCaau,
                        },
                    }),
                })
                this.map.addLayer(this.sourceCaau)
            } else {
                this.sourceNumUrgence = new ImageLayer({
                    source: new ImageWMS({
                        url: '/geoserver/wms',
                        imageLoadFunction: this.customLoader.bind(this),
                        params: {
                            LAYERS: 'tnaau:pdaau_geom_view_grey',
                            CQL_FILTER:
                                'id_departement=' +
                                this.$store.state.dptSelect.idDepartement +
                                '&&id_numero_urgence=' +
                                idNum,
                            SLD:
                                this.$store.state.urlApi +
                                'sld/caau/color/' +
                                this.$store.state.dptSelect.idDepartement +
                                '/' +
                                idNum +
                                '/' +
                                this.$store.state.user.id,
                            TILED: true,
                        },
                    }),
                })
                this.map.addLayer(this.sourceNumUrgence)

                let legendeURL =
                    '/geoserver/wms?SERVICE=WMS&VERSION=1.3.0' +
                    '&REQUEST=GetLegendGraphic&FORMAT=image/png&TRANSPARENT=true' +
                    '&LAYER=tnaau:pdaau_geom_view_grey' +
                    '&CQL_FILTER=id_departement=' +
                    this.$store.state.dptSelect.idDepartement +
                    '&id_numero_urgence=' +
                    idNum +
                    '&LEGEND_OPTIONS=forceLabels:on;fontName:Marianne Light;fontAntiAliasing:true;fontSize:12;' +
                    '&SLD=' +
                    this.$store.state.urlApi +
                    'sld/caau/color/' +
                    this.$store.state.dptSelect.idDepartement +
                    '/' +
                    idNum +
                    '/' +
                    this.$store.state.user.id +
                    '&TILED=true&CRS=EPSG:3857'

                this.queryGeoserverToBlob(
                    legendeURL,
                    (url) => (this.legende = url)
                )
            }
        },
        centerMap() {
            this.polygonByDepartement().then((wkt) => {
                const format = new WKT()
                const feature = format.readFeature(wkt, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857',
                })
                this.map.getView().fit(feature.getGeometry().getExtent())
            })
        },
        polygonByDepartement() {
            return axios
                .get(
                    '/departement/polygon/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            return response.data.bbox
                        }
                    }
                })
                .then()
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
        informationCommune(idNumUrgence) {
            this.map.removeLayer(this.sourceInfo)

            //Information de toutes les communes d'un département
            this.sourceInfo = new ImageLayer({
                source: new ImageWMS({
                    url: '/geoserver/wms',
                    imageLoadFunction: this.customLoader.bind(this),
                    params: {
                        LAYERS: 'tnaau:pdaau_geom_view_grey',
                        CQL_FILTER:
                            'id_departement=' +
                            this.$store.state.dptSelect.idDepartement +
                            '&&id_numero_urgence=' +
                            idNumUrgence,
                    },
                }),
            })
        },
        survoleCommune(evt, sourceInfo) {
            const url = sourceInfo
                .getSource()
                .getFeatureInfoUrl(
                    evt.coordinate,
                    this.map.getView().getResolution(),
                    this.map.getView().getProjection(),
                    { INFO_FORMAT: 'application/json' }
                )
            if (url) {
                this.queryGeoserverToText(url, (text) => {
                    const info = JSON.parse(text)
                    if (typeof info.features[0] !== 'undefined') {
                        const coordinate = evt.coordinate
                        this.infoCommune = info.features[0]
                        this.overlay.setPosition(coordinate)
                    } else {
                        // on masque la popup d'info
                        this.overlay.setPosition(undefined)
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
.legend {
    list-style: none;
    padding-left: 5px;
    font-family: 'Marianne Light';
    font-size: 14px;
    height: 20px;
}
.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
}
/* Legend colors */
.legend .grey {
    background-color: #a8b4de;
}
.legend .red {
    background-color: #e1000f;
}
.legend .blue {
    background-color: #000091;
}
.legend .black {
    background-color: #000000;
}

.map {
    height: 553px;
    width: 100%;
    background-color: #eeeffa;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.detail-info {
    font-family: 'Marianne Light';
    font-size: 14px;
}

.titre-info {
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.categorie-info {
    font-family: 'Marianne Medium';
    font-size: 12px;
}
</style>

const OPERATEUR = 'operateur'
const ADMINISTRATEUR = 'administrateur'
const PREFECTURE_CONSULTATION = 'prefecture_consultation'
const PREFECTURE_MODIFICATION = 'prefecture_modification'
const DIRECTION_METIER = 'direction_metier'
const GESTIONNAIRE_OCE = 'gestionnaire_oce'

module.exports = {
    OPERATEUR,
    ADMINISTRATEUR,
    PREFECTURE_CONSULTATION,
    PREFECTURE_MODIFICATION,
    DIRECTION_METIER,
    GESTIONNAIRE_OCE,
}

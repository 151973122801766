var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{ref:"menuColumn",staticClass:"aside-site",class:{
        leftMenuRadius: this.radius === 'on',
        leftMenu: this.radius !== 'on',
    }},[_c('div',{staticClass:"topMenu no-select"},[_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Home' },on:{"click":function($event){return _vm.goto('/#/')}}},[_vm._m(0),_c('label',{staticClass:"line-item"},[_vm._v("Accueil")])]),(_vm.isAllowed('ContactPrefecture'))?_c('div',{staticClass:"lineMenu pointer",class:{
                'lineMenu-selected':
                    this.$route.name === 'ContactPrefecture',
            },on:{"click":function($event){return _vm.goto('/#/operateur/contactprefecture')}}},[_vm._m(1),_c('label',{staticClass:"line-item"},[_vm._v("Contacter une préfecture")])]):_vm._e(),(_vm.isAllowed('ContactCced'))?_c('div',{staticClass:"lineMenu pointer",class:{
                'lineMenu-selected': this.$route.name === 'ContactCced',
            },on:{"click":function($event){return _vm.goto('/#/operateur/contactcced')}}},[_vm._m(2),_c('label',{staticClass:"line-item"},[_vm._v("Contacter le CCED")])]):_vm._e(),(_vm.isAllowed('AideOce'))?_c('div',{staticClass:"lineMenu pointer",class:{
                'lineMenu-selected': this.$route.name === 'AideOce',
            },on:{"click":function($event){return _vm.goto('/#/operateur/aide')}}},[_vm._m(3),_c('label',{staticClass:"line-item"},[_vm._v("Aide / présentation")])]):_vm._e(),(_vm.isAllowed('Pdaa'))?_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Pdaa' },on:{"click":function($event){return _vm.goto('/#/pdaa')}}},[_vm._m(4),_c('label',{staticClass:"line-item"},[_vm._v("Liste des communes - PDAA")])]):_vm._e(),(_vm.isAllowed('Caau'))?_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Caau' },on:{"click":function($event){return _vm.goto('/#/caau')}}},[_vm._m(5),_c('label',{staticClass:"line-item"},[_vm._v("Liste des CAAU")])]):_vm._e(),(_vm.isAllowed('Centre') && !_vm.isEntireCountry)?_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Centre' },on:{"click":function($event){return _vm.goto('/#/centre')}}},[_vm._m(6),_c('label',{staticClass:"line-item"},[_vm._v("Cartographie des centres")])]):_vm._e(),(
                (_vm.isAllowed('Publier') || _vm.isAllowed('Historique')) &&
                !_vm.isEntireCountry
            )?_c('div',{staticClass:"lineMenu pointer",class:{
                noBottomRadius: _vm.openPublication,
                'lineMenu-selected': _vm.openPublication,
                publicationtodo: this.isModificationExist,
            },on:{"click":function($event){_vm.openPublication = !_vm.openPublication
                _vm.openAdministration = false
                _vm.openModification = false
                _vm.sendCloseAll()}}},[_vm._m(7),_c('div',{staticClass:"line-item"},[_vm._v("Publication")]),_c('div',{staticClass:"line_arrow",class:{ rotate180: !_vm.openPublication }},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_up.svg")}})])]):_vm._e(),(
                this.openPublication &&
                !_vm.isEntireCountry &&
                (_vm.isAllowed('Publier') || _vm.isAllowed('Historique'))
            )?_c('div',{staticClass:"subMenuDown lineMenu-selected"},[_c('div',{staticClass:"spacerLine"}),(_vm.isAllowed('Publier'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected ': this.$route.name === 'Publier',
                    publicationtodo:
                        this.isModificationExist &&
                        this.$route.name !== 'Publier',
                },on:{"click":function($event){return _vm.goto('/#/publier')}}},[_vm._m(8),_c('div',{staticClass:"line-subItem"},[_vm._v("Publier")])]):_vm._e(),(_vm.isAllowed('Historique'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected ':
                        this.$route.name === 'Historique',
                },on:{"click":function($event){return _vm.goto('/#/historique')}}},[_vm._m(9),_c('div',{staticClass:"line-subItem"},[_vm._v("Historique des publications")])]):_vm._e()]):_vm._e(),_c('div',{staticStyle:{"clear":"both"}}),(_vm.isAllowed('Nac') && !_vm.isEntireCountry)?_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Nac' },on:{"click":function($event){return _vm.goto('/#/nac')}}},[_vm._m(10),_c('label',{staticClass:"line-item"},[_vm._v("Numéros alternatifs communicables")])]):_vm._e(),(_vm.isAllowed('Tableau'))?_c('div',{staticClass:"lineMenu pointer",class:{
                'lineSubMenu-selected': this.$route.name === 'Tableau',
            },on:{"click":function($event){return _vm.goto('/#/tableau')}}},[_vm._m(11),_c('div',{staticClass:"line-subItem"},[_vm._v("Tableaux de bord")])]):_vm._e(),_c('div',{staticStyle:{"clear":"both"}}),(_vm.isAllowed('Operateurs') || _vm.isAllowed('PrefectureModif'))?_c('div',{staticClass:"lineMenu pointer",class:{
                noBottomRadius: _vm.openModification,
                'lineMenu-selected': _vm.openModification,
            },on:{"click":function($event){_vm.openModification = !_vm.openModification
                _vm.openAdministration = false
                _vm.openPublication = false
                _vm.sendCloseAll()}}},[_vm._m(12),_c('div',{staticClass:"line-item"},[_vm._v("Contacts")]),_c('div',{staticClass:"line_arrow",class:{ rotate180: !_vm.openModification }},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_up.svg")}})])]):_vm._e(),(
                this.openModification &&
                (_vm.isAllowed('Operateurs') || _vm.isAllowed('PrefectureModif'))
            )?_c('div',{staticClass:"subMenuDown lineMenu-selected"},[_c('div',{staticClass:"spacerLine"}),(_vm.isAllowed('Operateurs'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected ':
                        this.$route.name === 'Operateurs',
                },on:{"click":function($event){return _vm.goto('/#/operateurs')}}},[_vm._m(13),_c('div',{staticClass:"line-subItem"},[_vm._v("Opérateurs")])]):_vm._e(),(_vm.isAllowed('PrefectureModif'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected ':
                        this.$route.name === 'PrefectureModif',
                },on:{"click":function($event){return _vm.goto('/#/prefecturemodif')}}},[_vm._m(14),_c('div',{staticClass:"line-subItem"},[_vm._v("Préfectures")])]):_vm._e()]):_vm._e(),(_vm.isAllowed('Aide'))?_c('div',{staticClass:"lineMenu pointer",class:{ 'lineMenu-selected': this.$route.name === 'Aide' },on:{"click":function($event){return _vm.goto('/#/aide')}}},[_vm._m(15),_c('label',{staticClass:"line-item"},[_vm._v("Aide / autoformation")])]):_vm._e()]),_c('div',{staticClass:"middleSpacer"}),_c('div',{staticClass:"bottomMenu no-select"},[(!_vm.isOperateur)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.openAdministration),expression:"this.openAdministration"}],staticClass:"subMenuUp lineMenu-selected background_bleu_tres_fonce"},[(_vm.isAllowed('Comptes'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected': this.$route.name === 'Comptes',
                },on:{"click":function($event){return _vm.goto('/#/comptes')}}},[_vm._m(16),_c('div',{staticClass:"line-subItem"},[_vm._v("Comptes")])]):_vm._e(),(_vm.isAllowed('AdministrationAide'))?_c('div',{staticClass:"lineSubMenu pointer",class:{
                    'lineSubMenu-selected':
                        this.$route.name === 'AdministrationAide',
                },on:{"click":function($event){return _vm.goto('/#/administration-aide')}}},[_vm._m(17),_c('div',{staticClass:"line-subItem"},[_vm._v("Aides")])]):_vm._e(),_c('div',{staticClass:"spacerLine"})]):_vm._e(),(_vm.isAdmin)?_c('div',{staticClass:"lineMenu background_bleu_tres_fonce pointer",class:{ noTopRadius: _vm.openAdministration },on:{"click":function($event){_vm.openAdministration = !_vm.openAdministration
                _vm.openPublication = false
                _vm.openModification = false
                _vm.sendCloseAll()}}},[_vm._m(18),_c('label',{staticClass:"line-item"},[_vm._v("Administration")]),_c('div',{staticClass:"line_arrow",class:{ rotate180: _vm.openAdministration }},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_up.svg")}})])]):_vm._e(),_c('div',{staticClass:"lineMenu background_bleu_tres_fonce pointer",on:{"click":_vm.logout}},[_vm._m(19),_c('label',{staticClass:"line-item no-select"},[_vm._v("Déconnexion")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_accueil.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_contacter_prefecture.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_contacter_prefecture.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_help-circle.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_pdaa.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_caau.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_carto.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_publication.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_publier.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_historique.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_caau.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_tableau.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_operateurs.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_operateurs.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_operateurs.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_help-circle.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_comptes.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_help-circle.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_admin.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"line_logo"},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_logout.svg")}})])
}]

export { render, staticRenderFns }
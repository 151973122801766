<template>
    <section class="section gap-05">
        <div class="deptSelector" v-if="smallSelector">
            <div
                class="deptGlobalBox pointer"
                :class="{
                    noBottomRadius: deptOpened,
                    shadowSelector: deptOpened,
                }"
                @click="sendCloseAll()">
                <div class="deptName">
                    <label v-if="this.memoDept.codeInsee === 0"
                        >Sélectionner un département</label
                    >
                    <label v-if="this.memoDept.codeInsee !== 0"
                        >{{ this.memoDept.nom }}
                        {{
                            this.memoDept.codeInsee !== '-1'
                                ? `(${this.memoDept.codeInsee})`
                                : ''
                        }}
                    </label>
                </div>
                <div class="deptArrow" :class="{ rotate180: deptOpened }">
                    <img
                        src="../../../assets/img/tnaau/Icon_up_blue.svg"
                        class="tailleIcone_20px" />
                </div>
            </div>
            <div
                class="deptPopup shadow"
                v-if="deptOpened && smallSelector"
                :style="getBackgroundPopup">
                <div class="deptScrollable">
                    <div
                        v-for="(item, index) in departements"
                        :key="item.codeInsee"
                        @click="clickOption(item)"
                        class="deptOption pointer"
                        :style="getBackground"
                        :class="{ deptOptionEven: (index & 1) === 0 }">
                        {{ item.nom }}
                        {{
                            item.idDepartement !== 0
                                ? `(${item.codeInsee})`
                                : ''
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="deptSelectorBig">
            <div
                class="deptGlobalBoxBig pointer"
                :class="{
                    noBottomRadius: deptOpened,
                    shadowSelector: deptOpened,
                }"
                @click="sendCloseAll()"
                v-if="!smallSelector">
                <div class="deptNameBig">
                    <label v-if="this.memoDept.codeInsee === 0"
                        >Sélectionner un département</label
                    >
                    <label v-if="this.memoDept.codeInsee !== 0"
                        >{{ this.memoDept.nom }}
                        {{
                            this.memoDept.codeInsee !== '-1'
                                ? `(${this.memoDept.codeInsee})`
                                : ''
                        }}</label
                    >
                </div>
                <div class="deptArrowBig" :class="{ rotate180: deptOpened }">
                    <img
                        src="../../../assets/img/tnaau/Icon_up_blue.svg"
                        class="tailleIcone_20px" />
                </div>
            </div>
            <div
                class="deptPopupBig shadowSelector"
                v-if="deptOpened && !smallSelector">
                <div class="deptScrollableBig">
                    <div
                        v-for="(item, index) in departements"
                        :key="item.codeInsee"
                        @click="clickOption(item)"
                        class="deptOptionBig pointer"
                        :style="getBackground"
                        :class="{ deptOptionEven: (index & 1) === 0 }">
                        {{ item.nom }}
                        {{
                            item.idDepartement !== 0
                                ? `(${item.codeInsee})`
                                : ''
                        }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import { notificationLogout } from '@/tools/authent'
export default {
    name: 'DepartementSelector',
    data() {
        return {
            departements: [],
            memoDept: {
                nom: '',
                codeInsee: 0,
            },
            deptOpened: false,
            enableCloseBus: false,
        }
    },
    props: {
        smallSelector: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            default: '',
        },
        isHome: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sendCloseAll() {
            this.deptOpened = !this.deptOpened
            this.enableCloseBus = false
            EventBus.$emit(eventTypes.POPUP_CLOSE, {})
        },
        clickOption(obj) {
            this.memoDept.nom = obj.nom
            this.memoDept.codeInsee = obj.codeInsee
            this.deptOpened = false
            this.$store.state.dptSelect = obj
            this.$store.commit('saveStore')
            EventBus.$emit(eventTypes.DEPT_CHANGE, {
                departement: obj,
            })

            // Si l'utilisateur a choisi la France entière et que l'on est dans une route invalide alors on redirige
            if (obj.idDepartement === 0) {
                const route = this.$route.name
                if (
                    route === 'Centre' ||
                    route === 'Publier' ||
                    route === 'Historique' ||
                    route === 'CaauCreation'
                )
                    this.$router.push('Home')
            }
        },
    },
    mounted() {
        this.memoDept.nom = this.$store.state.dptSelect.nom
        this.memoDept.codeInsee = this.$store.state.dptSelect.codeInsee
        let _this = this
        EventBus.$on(eventTypes.POPUP_CLOSE, () => {
            if (_this.enableCloseBus) {
                _this.deptOpened = false
            }
            _this.enableCloseBus = true
        })
        EventBus.$on(eventTypes.DEPT_CHANGE, (args) => {
            let obj = args.departement
            _this.memoDept.nom = obj.nom
            _this.memoDept.codeInsee = obj.codeInsee
        }),
            //Récupére la liste des départements
            axios
                .get('/departementutilisateur', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    this.departements = response.data
                    var franceEntiereAjoutee = false

                    // Si on a les droits de visibilité sur la france entière
                    if (this.$store.state.user.droitFranceEntiere) {
                        // On ajoute la france entière artificiellement à la liste des départements
                        this.departements.unshift({
                            codeInsee: '-1',
                            idDepartement: 0,
                            nom: 'France entière',
                        })
                        franceEntiereAjoutee = true
                    }

                    var defaultSelectorValue = franceEntiereAjoutee ? 1 : 0
                    if (Object.keys(this.$store.state.dptSelect).length === 0) {
                        if (this.departements.length > 0) {
                            this.clickOption(
                                this.departements[defaultSelectorValue]
                            )
                        }
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
    },
    computed: {
        getBackground() {
            return {
                'background-color': '#FFFFFF',
            }
        },
        getBackgroundPopup() {
            return {
                'background-color': this.background,
            }
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.POPUP_CLOSE)
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>
<style scoped>
.section {
    background: inherit;
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    max-width: 40%;
    margin: 0px;
    padding: 0px;
}
.deptSelector {
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 62px;
    width: 250px;
    color: #000091;
    padding-top: 5px;
    background: inherit;
}
.deptGlobalBox {
    display: flex;
    border-radius: 15px;
    border: #808ffa 1px solid;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
}
.deptName {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 100%;
    font-size: 13px;
}
.deptArrow {
    display: inline-flex;
    height: 100%;
}
.deptPopup {
    display: block;
    position: relative;
    width: 222px;
    height: 230px;
    background: inherit;
    z-index: 30;
    left: -222px;
    top: 125px;
    border: #808ffa 1px solid;
    border-top: none;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 10px 5px;
}
.deptScrollable {
    width: 210px;
    height: 210px;
    border-top: #aaaaaa 1px solid;
    font-size: 14px;
    overflow-y: scroll;
}

.deptOption {
    display: inline-flex;
    height: 25px;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-left: 5px;
    font-size: 12px;
    background: inherit;
}
.deptOption:hover {
    background: #e1e2ed !important;
}
.deptOptionEven {
    background: #eeeffa !important;
}
/* big selector */
.deptSelectorBig {
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 62px;
    width: 255px;
    color: #000091;
    padding-top: 5px;
    background: inherit;
}
.deptGlobalBoxBig {
    display: flex;
    border-radius: 24px;
    border: #808ffa 1px solid;
    height: 48px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Marianne Regular';
}
.deptNameBig {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    width: 204px;
    height: 100%;
    font-size: 14px;
    padding-left: 10px;
}
.deptArrowBig {
    display: inline-flex;
    height: 100%;
    margin-right: 14px;
}
.deptPopupBig {
    font-family: 'Marianne Regular';
    display: block;
    position: relative;
    width: 250px;
    height: 230px;
    background: inherit;
    z-index: 10;
    left: -250px;
    top: 125px;
    border: #808ffa 1px solid;
    border-top: none;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 10px 5px;
}
.deptScrollableBig {
    font-family: inherit;
    width: 240px;
    height: 210px;
    overflow-y: scroll;
    border-top: #aaaaaa 1px solid;
    font-size: 14px;
}
.deptOptionBig {
    display: inline-flex;
    height: 25px;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-left: 5px;
}
.deptOptionBig:hover {
    background: #e1e2ed !important;
}
.deptOptionEvenBig {
    background: #eeeffa !important;
}
</style>

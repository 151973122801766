<template>
    <aside
        ref="menuColumn"
        class="aside-site"
        :class="{
            leftMenuRadius: this.radius === 'on',
            leftMenu: this.radius !== 'on',
        }">
        <div class="topMenu no-select">
            <div
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Home' }"
                @click="goto('/#/')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_accueil.svg"
                /></label>
                <label class="line-item">Accueil</label>
            </div>
            <div
                v-if="isAllowed('ContactPrefecture')"
                class="lineMenu pointer"
                :class="{
                    'lineMenu-selected':
                        this.$route.name === 'ContactPrefecture',
                }"
                @click="goto('/#/operateur/contactprefecture')">
                <label class="line_logo"
                    ><img
                        src="../../assets/img/tnaau/Icon_contacter_prefecture.svg"
                /></label>
                <label class="line-item">Contacter une préfecture</label>
            </div>
            <div
                v-if="isAllowed('ContactCced')"
                class="lineMenu pointer"
                :class="{
                    'lineMenu-selected': this.$route.name === 'ContactCced',
                }"
                @click="goto('/#/operateur/contactcced')">
                <label class="line_logo"
                    ><img
                        src="../../assets/img/tnaau/Icon_contacter_prefecture.svg"
                /></label>
                <label class="line-item">Contacter le CCED</label>
            </div>
            <div
                v-if="isAllowed('AideOce')"
                class="lineMenu pointer"
                :class="{
                    'lineMenu-selected': this.$route.name === 'AideOce',
                }"
                @click="goto('/#/operateur/aide')">
                <label class="line_logo">
                    <img src="../../assets/img/tnaau/Icon_help-circle.svg" />
                </label>
                <label class="line-item">Aide / présentation</label>
            </div>
            <div
                v-if="isAllowed('Pdaa')"
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Pdaa' }"
                @click="goto('/#/pdaa')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_pdaa.svg"
                /></label>
                <label class="line-item">Liste des communes - PDAA</label>
            </div>
            <div
                v-if="isAllowed('Caau')"
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Caau' }"
                @click="goto('/#/caau')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_caau.svg"
                /></label>
                <label class="line-item">Liste des CAAU</label>
            </div>
            <div
                v-if="isAllowed('Centre') && !isEntireCountry"
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Centre' }"
                @click="goto('/#/centre')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_carto.svg"
                /></label>
                <label class="line-item">Cartographie des centres</label>
            </div>
            <div
                v-if="
                    (isAllowed('Publier') || isAllowed('Historique')) &&
                    !isEntireCountry
                "
                class="lineMenu pointer"
                :class="{
                    noBottomRadius: openPublication,
                    'lineMenu-selected': openPublication,
                    publicationtodo: this.isModificationExist,
                }"
                @click="
                    openPublication = !openPublication
                    openAdministration = false
                    openModification = false
                    sendCloseAll()
                ">
                <div class="line_logo">
                    <img src="../../assets/img/tnaau/Icon_publication.svg" />
                </div>
                <div class="line-item">Publication</div>
                <div
                    class="line_arrow"
                    :class="{ rotate180: !openPublication }">
                    <img src="../../assets/img/tnaau/Icon_up.svg" />
                </div>
            </div>
            <div
                class="subMenuDown lineMenu-selected"
                v-if="
                    this.openPublication &&
                    !isEntireCountry &&
                    (isAllowed('Publier') || isAllowed('Historique'))
                ">
                <div class="spacerLine"></div>
                <div
                    v-if="isAllowed('Publier')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected ': this.$route.name === 'Publier',
                        publicationtodo:
                            this.isModificationExist &&
                            this.$route.name !== 'Publier',
                    }"
                    @click="goto('/#/publier')">
                    <div class="line_logo">
                        <img src="../../assets/img/tnaau/Icon_publier.svg" />
                    </div>
                    <div class="line-subItem">Publier</div>
                </div>
                <div
                    v-if="isAllowed('Historique')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected ':
                            this.$route.name === 'Historique',
                    }"
                    @click="goto('/#/historique')">
                    <div class="line_logo">
                        <img src="../../assets/img/tnaau/Icon_historique.svg" />
                    </div>
                    <div class="line-subItem">Historique des publications</div>
                </div>
            </div>
            <div style="clear: both"></div>
            <div
                v-if="isAllowed('Nac') && !isEntireCountry"
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Nac' }"
                @click="goto('/#/nac')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_caau.svg"
                /></label>
                <label class="line-item"
                    >Numéros alternatifs communicables</label
                >
            </div>
            <div
                v-if="isAllowed('Tableau')"
                class="lineMenu pointer"
                :class="{
                    'lineSubMenu-selected': this.$route.name === 'Tableau',
                }"
                @click="goto('/#/tableau')">
                <div class="line_logo">
                    <img src="../../assets/img/tnaau/Icon_tableau.svg" />
                </div>
                <div class="line-subItem">Tableaux de bord</div>
            </div>
            <div style="clear: both"></div>
            <div
                v-if="isAllowed('Operateurs') || isAllowed('PrefectureModif')"
                class="lineMenu pointer"
                :class="{
                    noBottomRadius: openModification,
                    'lineMenu-selected': openModification,
                }"
                @click="
                    openModification = !openModification
                    openAdministration = false
                    openPublication = false
                    sendCloseAll()
                ">
                <div class="line_logo">
                    <img src="../../assets/img/tnaau/Icon_operateurs.svg" />
                </div>
                <div class="line-item">Contacts</div>
                <div
                    class="line_arrow"
                    :class="{ rotate180: !openModification }">
                    <img src="../../assets/img/tnaau/Icon_up.svg" />
                </div>
            </div>
            <div
                class="subMenuDown lineMenu-selected"
                v-if="
                    this.openModification &&
                    (isAllowed('Operateurs') || isAllowed('PrefectureModif'))
                ">
                <div class="spacerLine"></div>
                <div
                    v-if="isAllowed('Operateurs')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected ':
                            this.$route.name === 'Operateurs',
                    }"
                    @click="goto('/#/operateurs')">
                    <div class="line_logo">
                        <img src="../../assets/img/tnaau/Icon_operateurs.svg" />
                    </div>
                    <div class="line-subItem">Opérateurs</div>
                </div>
                <div
                    v-if="isAllowed('PrefectureModif')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected ':
                            this.$route.name === 'PrefectureModif',
                    }"
                    @click="goto('/#/prefecturemodif')">
                    <div class="line_logo">
                        <img src="../../assets/img/tnaau/Icon_operateurs.svg" />
                    </div>
                    <div class="line-subItem">Préfectures</div>
                </div>
            </div>
            <div
                v-if="isAllowed('Aide')"
                class="lineMenu pointer"
                :class="{ 'lineMenu-selected': this.$route.name === 'Aide' }"
                @click="goto('/#/aide')">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_help-circle.svg"
                /></label>
                <label class="line-item">Aide / autoformation</label>
            </div>
        </div>
        <div class="middleSpacer"></div>
        <div class="bottomMenu no-select">
            <div
                v-if="!isOperateur"
                class="subMenuUp lineMenu-selected background_bleu_tres_fonce"
                v-show="this.openAdministration">
                <div
                    v-if="isAllowed('Comptes')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected': this.$route.name === 'Comptes',
                    }"
                    @click="goto('/#/comptes')">
                    <div class="line_logo">
                        <img src="../../assets/img/tnaau/Icon_comptes.svg" />
                    </div>
                    <div class="line-subItem">Comptes</div>
                </div>
                <div
                    v-if="isAllowed('AdministrationAide')"
                    class="lineSubMenu pointer"
                    :class="{
                        'lineSubMenu-selected':
                            this.$route.name === 'AdministrationAide',
                    }"
                    @click="goto('/#/administration-aide')">
                    <div class="line_logo">
                        <img
                            src="../../assets/img/tnaau/Icon_help-circle.svg" />
                    </div>
                    <div class="line-subItem">Aides</div>
                </div>
                <div class="spacerLine"></div>
            </div>
            <div
                v-if="isAdmin"
                class="lineMenu background_bleu_tres_fonce pointer"
                :class="{ noTopRadius: openAdministration }"
                @click="
                    openAdministration = !openAdministration
                    openPublication = false
                    openModification = false
                    sendCloseAll()
                ">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_admin.svg"
                /></label>
                <label class="line-item">Administration</label>
                <div
                    class="line_arrow"
                    :class="{ rotate180: openAdministration }">
                    <img src="../../assets/img/tnaau/Icon_up.svg" />
                </div>
            </div>
            <div
                class="lineMenu background_bleu_tres_fonce pointer"
                @click="logout">
                <label class="line_logo"
                    ><img src="../../assets/img/tnaau/Icon_logout.svg"
                /></label>
                <label class="line-item no-select">Déconnexion</label>
            </div>
        </div>
    </aside>
</template>

<script>
import axios from 'axios'
import * as eventTypes from '../../bus/event-types.js'
import EventBus from '../../bus'
import roles from '../../tools/Roles'
import {
    notificationLogout,
    isUserPageAuthorized,
    logoutServer,
} from '@/tools/authent'

export default {
    name: 'LeftMenu',
    data() {
        return {
            openPublication: false,
            openAdministration: false,
            openModification: false,
            currentRoute: {
                name: '',
            },
            enableCloseBus: true,
            isModificationExist: false,
        }
    },
    props: {
        radius: {
            type: String,
            default: 'on',
        },
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        logout() {
            logoutServer(this, this.$store.state.user.token)
        },
        checkOpenedMenu() {
            if (this.currentRoute === null) {
                return
            }
            switch (this.currentRoute.name) {
                case 'Publier':
                case 'Historique':
                    this.openPublication = true
                    break
                case 'Comptes':
                case 'Operateurs':
                case 'Tableau':
                    this.openAdministration = true
                    break
            }
        },
        extendHeightMenu() {
            if (this.$refs.menuColumn) {
                // On calcule l'offset du menu (par rapport au header)
                let offset = this.$refs.menuColumn.getBoundingClientRect().top
                // Si le contenu est scrollable horizontalement alors on incrémente l'offset de la taille de la scrollbar
                if (document.documentElement.scrollWidth > window.innerWidth)
                    offset +=
                        window.innerWidth -
                        document.documentElement.clientWidth +
                        1
                // On applique la taille du menu (hauteur totale - offset)
                this.$refs.menuColumn.style.height = `calc(100vh - ${offset}px)`
            }
            EventBus.$emit(eventTypes.WINDOWS_RESIZE, {})
        },
        sendCloseAll() {
            this.deptOpened = !this.deptOpened
            this.enableCloseBus = false
            EventBus.$emit(eventTypes.POPUP_CLOSE, {})
        },
        isAllowed(page) {
            return isUserPageAuthorized(this, page)
        },
        checkModificationExist() {
            axios
                .get(
                    '/pdaau/modificationexist/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    this.isModificationExist = response.data
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
    },
    beforeUpdate() {
        if (!this.isOperateur) {
            this.checkModificationExist()
        }
    },
    updated() {
        // Quand on change de page on rescroll tout en haut et étend la taille du menu latéral
        window.scrollTo(0, 0)
        this.extendHeightMenu()
    },
    mounted() {
        this.currentRoute = this.$router.currentRoute
        this.extendHeightMenu()
        this.checkOpenedMenu()
        window.addEventListener('resize', this.extendHeightMenu)
        window.addEventListener('scroll', this.extendHeightMenu)
        EventBus.$on(eventTypes.POPUP_CLOSE, () => {
            if (this.enableCloseBus) {
                this.openPublication = false
                this.openAdministration = false
            }
            this.enableCloseBus = true
        })
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            if (!this.isOperateur) {
                this.checkModificationExist()
            }
        })
    },
    computed: {
        isOperateur() {
            return this.$store.state.user.profil === roles.OPERATEUR
        },
        isAdmin() {
            return this.$store.state.user.profil === roles.ADMINISTRATEUR
        },
        isEntireCountry() {
            return this.$store.state.dptSelect.idDepartement === 0
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.POPUP_CLOSE)
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>

<style scoped>
.publicationtodo {
    background-color: red;
    animation-name: colorblue;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
}

@keyframes colorblue {
    from {
        background-color: #1a1a9f;
    }
    to {
        background-color: #3434c4;
    }
}

.leftMenuRadius {
    position: sticky;
    top: 0px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    background: #1a1a9f;
    width: 270px;
    min-height: 550px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.leftMenu {
    position: sticky;
    top: 0px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    background: #1a1a9f;
    width: 270px;
    min-height: 550px;
}

.lineMenu {
    display: inline-flex;
    color: #ffffff;
    font-family: 'Marianne Medium';
    font-size: 14px;
    height: 40px;
    border-radius: 20px;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    width: 250px;
}

.lineMenu:hover {
    background: #3434c4 !important;
    opacity: 0.9 !important;
}

.lineMenu-selected {
    background: #3434c4 !important;
}

.line_logo {
    height: 20px;
    overflow: hidden;
    margin-right: 10px;
    display: inline-flex;
    float: left;
    cursor: inherit;
}

.line-item {
    display: inline-flex;
    float: left;
    width: 175px;
    border-radius: 20px;
    justify-content: left;
    align-items: center;
    cursor: inherit;
}

.subMenuDown {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    height: auto;
    width: 250px;
    top: -10px;
    border-top-right-radius: 0px;
    padding-left: 5px;
}

.spacerLine {
    width: 220px;
    height: 3px;
    border-top: #ffffff 1px solid;
    margin-left: 10px;
}

.lineSubMenu {
    display: inline-flex;
    color: #ffffff;
    font-family: 'Marianne Medium';
    font-size: 12px;
    height: 40px;
    border-radius: 20px;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 240px;
    /*margin-top:5px;*/
    margin-bottom: 5px;
}

.lineSubMenu:hover {
    opacity: 0.8;
    background: #4143df;
}

.lineSubMenu-selected {
    opacity: 0.8;
    background: #4143df;
}

.line-subItem {
    display: inline-flex;
    float: left;
    width: 175px;
    border-radius: 20px;
    justify-content: left;
    align-items: center;
    padding-left: 5px;
    cursor: inherit;
}

.subMenuUp {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: relative;
    width: 250px;
    background: #000091;
    top: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 10px;
    padding-left: 5px;
}
</style>

<template>
    <!-- CONTENT -->
    <div class="container">
        <TitreReference :reference="'Nouvel'" :texte="'Opérateur'" />
        <div class="flex-column padding-top-50-px">
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Code ARCEP
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="codearcep"
                        ref="codearcep" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Nom opérateur
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="nomoperateur"
                        ref="nomoperateur" />
                </div>
            </div>
            <br />
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Mail de réception des PDAAU
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="emailutilisateur"
                        ref="emailutilisateur" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Email du responsable
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="contactemail"
                        ref="contactemail" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Téléphone du responsable
                    </div>
                    <input
                        class="inputForm"
                        type="tel"
                        v-model="contacttel"
                        ref="contacttel" />
                </div>
            </div>
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        SIRET
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="siret"
                        ref="siret" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Contact adresse
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="contactadresse"
                        ref="contactadresse" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Type ressource
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="typeressource"
                        ref="typeressource" />
                </div>
            </div>
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Support exploitation heures ouvrées
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="supportheuresouvrees"
                        ref="supportheuresouvrees" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Support exploitation heures non ouvrées
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="supportheuresnonouvrees"
                        ref="supportheuresnonouvrees" />
                </div>
            </div>
            <div class="formInputField">
                <div class="font-label-input padding-bottom-10-px">
                    Commentaire
                </div>
                <textarea
                    class="textAreaForm"
                    type="text"
                    v-model="commentaire"
                    ref="commentaire"
                    cols="50"
                    rows="5" />
            </div>
        </div>
        <WidgetAccesDepartement
            ref="widgetDemartement"
            :isAllSelection="true" />
        <div class="flex flex-wrap gap-1">
            <switch-button
                :texte="'Opérateur principal'"
                :color="'#00ACA8'"
                ref="isOperateurPrincipal"
                class="ficheSwitchSpacer" />

            <switch-button
                v-if="isIntegration"
                :texte="'Acquittement auto (Uniquement via API)'"
                :color="'#00ACA8'"
                ref="isAcquittementAuto"
                class="ficheSwitchSpacer" />
            <switch-button
                :texte="'Téléchargement auto ok'"
                :color="'#00ACA8'"
                ref="isTelechargementAutoOk"
                class="ficheSwitchSpacer" />
        </div>
        <div class="flex">
            <button
                class="
                    bouton
                    background_page
                    texte_bleu_fonce
                    no-border
                    margin-bottom-10-px margin-left-30-px
                "
                @click="annuler">
                Annuler
            </button>
            <button
                class="
                    bouton
                    boutonValidation
                    background_bleu_tres_fonce
                    texte_blanc
                    no-border
                    margin-left-20-px
                "
                @click="creer">
                <img
                    src="../../assets/img/tnaau/Icon_check_mark.svg"
                    class="margin-right-10-px" />
                Valider la création
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {
    emailCheck,
    notNullCheck,
    checkInput,
    isNotHtml,
} from '@/tools/inputCheck'
import { notificationLogout } from '@/tools/authent'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import WidgetAccesDepartement from '@/components/widgets/widgetAccesDepartement/WidgetAccesDepartement'
export default {
    name: 'CreationOperateur',
    components: { TitreReference, SwitchButton, WidgetAccesDepartement },
    data() {
        return {
            nomoperateur: '',
            emailutilisateur: '',
            contactemail: '',
            contacttel: '',
            contactadresse: '',
            codearcep: '',
            siret: '',
            typeressource: '',
            supportheuresouvrees: '',
            supportheuresnonouvrees: '',
            commentaire: '',
            operateurPrincipal: false,
            departementsUtilisateur: [],
            acquittementAuto: false,
            telechargementAutoOk: false,
        }
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isIntegration() {
            return this.$store.state.integration
        },
    },
    methods: {
        creer() {
            this.operateurPrincipal = this.$refs.isOperateurPrincipal.status
            this.departementsUtilisateur =
                this.$refs.widgetDemartement.checkedDepartement
            this.telechargementAutoOk = this.$refs.isTelechargementAutoOk.status
            this.acquittementAuto = this.$refs.isAcquittementAuto
                ? this.$refs.isAcquittementAuto.status
                : false

            //Vérification des champs
            let formulaireValid = true
            if (this.departementsUtilisateur.length == 0) {
                formulaireValid = false
                this.$notify({
                    group: 'general-notification',
                    title: 'Aucun département sélectionné',
                    text: 'Veuillez autoriser au moins un département',
                    type: 'warn',
                    duration: 5000,
                })
            }
            let checkHeuresOuvrees = checkInput(
                this.$refs.supportheuresouvrees,
                isNotHtml(this.supportheuresouvrees)
            )
            let checkHeuresNonOuvrees = checkInput(
                this.$refs.supportheuresnonouvrees,
                isNotHtml(this.supportheuresnonouvrees)
            )
            let checkNomOperateur = checkInput(
                this.$refs.nomoperateur,
                notNullCheck(this.nomoperateur)
            )
            let checkEmailOperateur = checkInput(
                this.$refs.emailutilisateur,
                emailCheck(this.emailutilisateur)
            )
            let checkEmail = checkInput(
                this.$refs.contactemail,
                emailCheck(this.contactemail)
            )
            let checkTel = checkInput(
                this.$refs.contacttel,
                notNullCheck(this.contacttel)
            )
            let checkAdresse = checkInput(
                this.$refs.contactadresse,
                notNullCheck(this.contactadresse)
            )
            let checkArcep = checkInput(
                this.$refs.codearcep,
                notNullCheck(this.codearcep)
            )
            let checkSiret = checkInput(
                this.$refs.siret,
                notNullCheck(this.siret)
            )
            let checkTypeRessource = checkInput(
                this.$refs.typeressource,
                notNullCheck(this.typeressource)
            )
            let checkCommentaire = checkInput(
                this.$refs.commentaire,
                isNotHtml(this.commentaire)
            )
            //Création de l'opérateur
            if (
                formulaireValid &&
                checkHeuresOuvrees &&
                checkHeuresNonOuvrees &&
                checkNomOperateur &&
                checkEmailOperateur &&
                checkEmail &&
                checkTel &&
                checkAdresse &&
                checkArcep &&
                checkSiret &&
                checkTypeRessource &&
                checkCommentaire
            ) {
                axios
                    .post(
                        '/operateur',
                        {
                            username: this.nomoperateur,
                            nomOperateur: this.nomoperateur,
                            email: this.emailutilisateur,
                            contact_email: this.contactemail,
                            contact_telephone: this.contacttel,
                            contact_adresse: this.contactadresse,
                            code_arcep: this.codearcep,
                            siret: this.siret,
                            type_ressource: this.typeressource,
                            commentaire: this.commentaire,
                            operateurPrincipal: this.operateurPrincipal,
                            departementAcces: this.departementsUtilisateur,
                            support_heures_ouvrees: this.supportheuresouvrees,
                            support_heures_non_ouvrees:
                                this.supportheuresnonouvrees,
                            acquittementAuto: this.acquittementAuto,
                            telechargementAutoOk: this.telechargementAutoOk,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title:
                                    'Le compte de ' +
                                    this.nomoperateur +
                                    ' a bien été créé',
                                text:
                                    'Un email lui a été adressé à ' +
                                    this.emailutilisateur,
                                type: 'success',
                                duration: 5000,
                            })
                            window.location.href = '/#/operateurs'
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            var msgError =
                                "Vous n'avez pas l'autorisation de créer un utilisateur"
                            if (e.response.data !== undefined) {
                                msgError = e.response.data.message
                            }
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: msgError,
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        annuler() {
            window.location.href = '/#/operateurs'
        },
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ficheSwitchSpacer {
    padding: 15px 15px 40px 20px;
}

.formInputField {
    margin-left: 15px;
    padding-bottom: 20px;
}

.bouton {
    width: 186px;
    margin-left: 10px;
}
</style>

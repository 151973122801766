<template>
    <div id="app">
        <template>
            <!-- CONTENT -->
            <section>
                <div>
                    <login v-if="!isLogged" />
                    <MainHeader
                        v-if="isLogged && changeMainHeader"
                        :deptSelector="deptSelectorMainHeader"
                        :styleMode="styleModeMainHeader"
                        :url="urlMainHeader"
                        :nom="nomMainHeader"
                        :isAllowedWithFranceEntiere="
                            isAllowedWithFranceEntiere
                        ">
                    </MainHeader>
                    <div class="flex" v-if="isLogged && isUserAuthorized">
                        <LeftMenu :radius="radius"></LeftMenu>
                        <router-view />
                    </div>
                </div>
            </section>
        </template>
        <notifications
            group="general-notification"
            position="top right"
            width="400"
            height="200"
            animation-type="velocity"
            :duration="4000"
            classes="vue-notification font-marianne-thin" />
    </div>
</template>

<script>
import LeftMenu from '@/components/home/LeftMenu'
import MainHeader from '@/components/widgets/mainHeader/MainHeader'
import Login from '@/components/authent/Login'
import { isUserPageAuthorized } from './tools/authent'

export default {
    name: 'app',
    components: { LeftMenu, MainHeader, Login },
    data() {
        return {
            urlMainHeader: '',
            nomMainHeader: '',
            styleModeMainHeader: '',
            deptSelectorMainHeader: '',
            isAllowedWithFranceEntiere: false,
            radius: 'on',
        }
    },
    methods: {
        changeMainHeaderVar(route) {
            switch (route) {
                case 'Operateurs':
                    this.radius = 'off'
                    this.urlMainHeader = '/#/creationoperateur'
                    this.nomMainHeader = 'Créer un opérateur'
                    this.styleModeMainHeader = 'full'
                    this.deptSelectorMainHeader = true
                    this.isAllowedWithFranceEntiere = true
                    break
                case 'Comptes':
                    this.radius = 'off'
                    this.urlMainHeader = '/#/creationcompte'
                    this.nomMainHeader = 'Créer un compte'
                    this.styleModeMainHeader = 'full'
                    this.deptSelectorMainHeader = true
                    this.isAllowedWithFranceEntiere = true
                    break
                case 'Home':
                case 'HomeOce':
                    this.radius = 'on'
                    this.urlMainHeader = ''
                    this.nomMainHeader = ''
                    this.styleModeMainHeader = 'classic'
                    this.deptSelectorMainHeader = false
                    break
                case 'Caau':
                    this.radius = 'off'
                    this.urlMainHeader = '/#/caauCreation'
                    this.nomMainHeader = 'Créer un centre'
                    this.styleModeMainHeader = 'full'
                    this.deptSelectorMainHeader = true
                    this.isAllowedWithFranceEntiere = false
                    break
                case 'Nac':
                    this.radius = 'off'
                    this.urlMainHeader = '/#/nac'
                    this.nomMainHeader = ''
                    this.styleModeMainHeader = 'full'
                    this.deptSelectorMainHeader = true
                    this.isAllowedWithFranceEntiere = false
                    break
                case 'Publier':
                case 'Historique':
                case 'CreationCompte':
                case 'CreationOperateur':
                case 'OperateurDetail':
                case 'CaauCreation':
                case 'CompteDetail':
                case 'Tableau':
                case 'Pdaa':
                case 'Centre':
                case 'PrefectureModif':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Integration':
                case 'Register':
                case 'Aide':
                    this.radius = 'off'
                    this.urlMainHeader = ''
                    this.nomMainHeader = ''
                    this.styleModeMainHeader = 'full'
                    this.deptSelectorMainHeader = true
                    break
            }
        },
    },
    computed: {
        changeMainHeader() {
            let route = this.$route.name
            this.changeMainHeaderVar(route)
            return true
        },
        isUserAuthorized() {
            return isUserPageAuthorized(this)
        },
        isLogged() {
            return this.$store.state.user.fullLogged
        },
    },
}
</script>

<style>
.vue-notification-template.vue-notification {
    padding: 20px;
    min-height: 100px;
    font-size: 18px;
    font-weight: 900;
    color: #ffffff;
    background: #44a4fc;
    border-left: 10px solid #187fe7;
}

.vue-notification-template.vue-notification.warn {
    background: #f48a06;
    border-left-color: #f48a06;
}

.vue-notification-template.vue-notification.error {
    background: #e54d42;
    border-left-color: #b82e24;
}

.vue-notification-template.vue-notification.success {
    background: #68cd86;
    border-left-color: #42a85f;
}
</style>

import axios from 'axios'

export function logout(_this) {
    _this.$store.state.user.id = ''
    _this.$store.state.user.profil = ''
    _this.$store.state.user.login = ''
    _this.$store.state.user.token = ''
    _this.$store.state.user.fullLogged = false
    _this.$store.state.dptSelect = []
    _this.$store.commit('saveStore')
    window.location.href = '/#/'
}

export function logoutServer(_this, token) {
    axios
        .post(
            '/logout',
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            }
        )
        .then(() => {
            logout(_this)
        })
        .catch((e) => {
            _this.$notify({
                group: 'general-notification',
                text: e.response.data.message,
                type: 'error',
                duration: 5000,
            })
            logout(_this)
        })
}

export function getLogin(token) {
    return axios
        .get('/current-user', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((response) => {
            return response?.data
        })
}

export function notificationLogout(_this, e) {
    var rep = e.response === undefined ? 500 : e.response.status
    switch (rep) {
        case 401:
            if (
                e.response.headers['www-authenticate'].includes('invalid_token')
            ) {
                // Si l'utilisateur a déjà eu une notification de time out on en renvoi pas une autre
                if (_this.$store.state.user.timedOut) return
                _this.$store.state.user.timedOut = true

                _this.$notify({
                    group: 'general-notification',
                    title: 'SESSION TIMEOUT',
                    text: "Votre session utilisateur n'est plus valide.",
                    type: 'warn',
                    duration: 5000,
                })
            } else {
                _this.$notify({
                    group: 'general-notification',
                    title: 'Authentification requise',
                    text: 'Veuillez vous authentifier.',
                    type: 'error',
                    duration: 5000,
                })
            }
            logout(_this)
            break
        case 500:
            _this.$notify({
                group: 'general-notification',
                title: 'Erreur serveur',
                text: "Erreur lors de l'éxecution de la requête",
                type: 'error',
                duration: 5000,
            })
            break
    }
}

import * as Roles from '@/tools/Roles'
export function isUserPageAuthorized(_this, page) {
    // On vérifie si l'utilisateur à le droit d'accéder à la page liée à l'url courante
    //let router = this.$router;
    let route = ''
    if (page !== undefined) {
        route = page
    } else {
        route = _this.$route.name
    }

    switch (_this.$store.state.user.profil) {
        case Roles.OPERATEUR:
            switch (route) {
                case 'Home':
                case 'ContactPrefecture':
                case 'Integration':
                case 'ContactCced':
                case 'AideOce':
                    return true
            }
            break
        case Roles.ADMINISTRATEUR:
            switch (route) {
                case 'Home':
                case 'CreationCompte':
                case 'CreationOperateur':
                case 'OperateurDetail':
                case 'Historique':
                case 'Publier':
                case 'Comptes':
                case 'Operateurs':
                case 'PrefectureModif':
                case 'Tableau':
                case 'Pdaa':
                case 'Centre':
                case 'Nac':
                case 'Caau':
                case 'CaauCreation':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Register':
                case 'CompteDetail':
                case 'Aide':
                case 'AdministrationAide':
                    return true
            }
            break
        case Roles.PREFECTURE_CONSULTATION:
            switch (route) {
                case 'Home':
                case 'Historique':
                case 'Publier':
                case 'Tableau':
                case 'Operateurs':
                case 'PrefectureModif':
                case 'OperateurDetail':
                case 'Pdaa':
                case 'Centre':
                case 'Nac':
                case 'Caau':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Register':
                case 'Aide':
                    return true
            }
            break
        case Roles.PREFECTURE_MODIFICATION:
            switch (route) {
                case 'Home':
                case 'Historique':
                case 'Publier':
                case 'Tableau':
                case 'Operateurs':
                case 'PrefectureModif':
                case 'OperateurDetail':
                case 'Pdaa':
                case 'Centre':
                case 'Nac':
                case 'Caau':
                case 'CaauCreation':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Register':
                case 'Aide':
                    return true
            }
            break
        case Roles.DIRECTION_METIER:
            switch (route) {
                case 'Home':
                case 'Tableau':
                case 'Pdaa':
                case 'Centre':
                case 'Operateurs':
                case 'PrefectureModif':
                case 'OperateurDetail':
                case 'Nac':
                case 'Caau':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Register':
                case 'Aide':
                    return true
            }
            break
        case Roles.GESTIONNAIRE_OCE:
            switch (route) {
                case 'Home':
                case 'CreationOperateur':
                case 'OperateurDetail':
                case 'Historique':
                case 'Publier':
                case 'Operateurs':
                case 'PrefectureModif':
                case 'Tableau':
                case 'Pdaa':
                case 'Centre':
                case 'Nac':
                case 'Caau':
                case 'CaauDetail':
                case 'LostPassword':
                case 'Register':
                case 'Aide':
                    return true
            }
            break
    }

    return false
}

<template>
    <div class="container no-select">
        <TitreReference
            :reference="'CAAU'"
            :texte="'Centres d’accueil des appels d’urgence'"
            class="margin-bottom-50-px" />
        <CaauGrid />
    </div>
</template>

<script>
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import CaauGrid from '@/components/widgets/caauGrid/CaauGrid'

export default {
    name: 'Caau',
    components: { TitreReference, CaauGrid },
    data() {
        return {
            dept: 0,
            caauList: [],
        }
    },
    mounted() {
        this.$store.state.search = ''
        this.$store.state.sortorder = 'asc'
        this.$store.state.sort = 'num_court'
        this.$store.commit('saveStore')
        //Sélection du département via DepartementSelector
        EventBus.$on(eventTypes.DEPT_CHANGE, (args) => {
            let obj = args.departement
            this.dept = obj.idDepartement

            EventBus.$emit(eventTypes.LOADED_TABLE_CAAU, {
                departement: this.dept,
                filtre: '',
                sort: 'num_court',
                sortorder: 'asc',
            })
        })

        //Chargement de la page
        this.dept = this.$store.state.dptSelect.idDepartement
        EventBus.$emit(eventTypes.LOADED_TABLE_CAAU, {
            departement: this.dept,
            filtre: '',
            sort: 'num_court',
            sortorder: 'asc',
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

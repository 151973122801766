<template>
    <section>
        <div class="inline-flex flex-column">
            <div class="postitGlobalBox postitGlobalBoxBlack">
                <div class="subContainer">
                    <div class="postitTitreNumero uppercase">
                        Numéro long actuel
                    </div>
                    <div class="postitNumero uppercase">
                        {{ this.numLongActuel }}
                    </div>
                </div>
                <div class="subContainer">
                    <div class="inline-flex" v-if="this.jusquau">
                        <div class="postitTitreDate">Jusqu'au</div>
                        <div class="postitDate">{{ this.jusquau }}</div>
                    </div>
                </div>
            </div>
            <div
                class="postitGlobalBox postitGlobalBoxRed"
                v-if="this.numLongNouveau">
                <div class="subContainer">
                    <div
                        class="
                            postitTitreNumeroRed
                            postitTitreNumero
                            uppercase
                        ">
                        Nouveau numéro long
                    </div>
                    <div class="postitNumero uppercase">
                        {{ this.numLongNouveau }}
                    </div>
                </div>
                <div class="subContainer">
                    <div class="inline-flex">
                        <div class="postitTitreDate">A compter du</div>
                        <div class="postitDate">{{ this.acompter }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TooltipNumero',
    data() {
        return {
            numLongActuel: '',
            jusquau: '',
            numLongNouveau: '',
            acompter: '',
        }
    },
    props: {
        pdaa: {
            type: Object,
            default: () => {},
        },
    },
    mounted() {
        //Mise à jours des numéros longs
        if (
            null !== this.pdaa.mea_numero_long_nouveau &&
            this.pdaa.mea_numero_long_nouveau
        ) {
            this.numLongActuel = this.pdaa.mea_numero_long_ancien
            this.jusquau = this.pdaa.mea_date_ancien_jusqu_au
            this.numLongNouveau = this.pdaa.mea_numero_long_nouveau
            this.acompter = this.pdaa.mea_date_nouveau_acompter_du
        } else {
            if (
                null !== this.pdaa.numero_long_ancien &&
                this.pdaa.numero_long_ancien !== ''
            ) {
                this.numLongActuel = this.pdaa.numero_long_ancien
                this.jusquau = this.pdaa.date_jusqu_au
                this.numLongNouveau = this.pdaa.numero_long_actuel
                this.acompter = this.pdaa.date_acompter_du
            } else {
                this.numLongActuel = this.pdaa.numero_long_actuel
                this.jusquau = this.pdaa.date_jusqu_au
                this.numLongNouveau = ''
                this.acompter = ''
            }
        }
    },
}
</script>

<style scoped>
.postitGlobalBox {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 328px;
    height: 75px;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
}

.postitGlobalBoxRed {
    border: 1px solid #e1000f;
}

.postitGlobalBoxBlack {
    box-shadow: 0px 3px 6px #00000029;
}

.postitTitreNumero {
    font-family: 'Marianne Medium';
    font-size: 12px;
}

.postitNumero {
    font-family: 'Marianne Light';
    font-size: 14px;
}

.postitTitreNumeroRed:before {
    content: url('../../../assets/img/tnaau/Icon_pointrouge.png');
    margin-right: 5px;
}

.postitTitreDate {
    display: inline-flex;
    font-family: 'Marianne Medium';
    font-size: 12px;
}

.postitTitreDate:before {
    content: url('../../../assets/img/tnaau/Icon_calendar.svg');
    width: 20px;
    height: 20px;
}

.postitDate {
    display: inline-flex;
    font-family: 'Marianne Light';
    font-size: 12px;
    margin-left: 10px;
}
</style>

<template>
    <section class="ficheGlobalBox">
        <div class="ficheLeftColumn">
            <div class="ficheNumCourt">
                {{ this.centreEdit.numero_court }}
            </div>
        </div>
        <div class="ficheMiddleColumn">
            <div class="ficheGlobalAddresse">
                <div class="ficheAddresseModification">
                    <input
                        class="inputForm"
                        type="text"
                        v-model="centreEdit.adresse_nom_voie"
                        :placeholder="this.centreEdit.adresse_nom_voie"
                        ref="inputVoie"
                        @keyup="btnSaveEnable"
                        :readonly="!isAuthorizeModification" />
                    <div class="inline-flex flex-wrap">
                        <input
                            class="inputForm inputFormCp"
                            type="text"
                            v-model="centreEdit.adresse_cp"
                            :placeholder="this.centreEdit.adresse_cp"
                            ref="inputCp"
                            @keyup="btnSaveEnable"
                            :readonly="!isAuthorizeModification" />
                        <div class="inputSearchFormVille no-select">
                            <autocomplete
                                class="inputFormVille"
                                :items="this.listeCommunes"
                                @item-clicked="selectionCom"
                                item-text="nom Commune"
                                item-value="idCommune"
                                v-model="centreEdit.nom_commune"
                                :input-attrs="{
                                    autofocus: false,
                                    placeholder: 'Nom commune',
                                }"
                                @change="checkCommune"
                                :component-item="template"
                                :auto-select-one-item="false"
                                :disabled="!isAuthorizeModification" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="ficheDateNotification">
                Date de notification
                <span
                    class="bold margin-left-10-px"
                    v-text="this.centreEdit.date_notification" />
            </div>
            <div class="ficheContact">
                <span class="ficheTitle uppercase margin-bottom-10-px"
                    >Contact</span
                >
                <div class="flex flex-column gap-05 margin-left-15-px">
                    <div class="ficheTitle">Fonction</div>
                    <input
                        type="text"
                        class="inputForm"
                        @keyup="btnSaveEnable"
                        v-model="centreEdit.contact_fonction"
                        ref="contactFonction"
                        :readonly="!isAuthorizeModification" />
                    <div class="ficheTitle">Email</div>
                    <input
                        type="text"
                        class="inputForm"
                        @keyup="btnSaveEnable"
                        v-model="centreEdit.contact_email"
                        ref="contactMail"
                        :readonly="!isAuthorizeModification" />
                    <div class="ficheTitle">Téléphone</div>
                    <input
                        type="text"
                        class="inputForm"
                        @keyup="btnSaveEnable"
                        v-model="centreEdit.contact_telephone"
                        ref="contactTel"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="ficheCommentaire">
                <span class="ficheCommentaireTitle uppercase">Commentaire</span>
                <div class="ficheCommentaireArea uppercase">
                    <textarea
                        cols="40"
                        rows="5"
                        class="textAreaForm"
                        @keyup="btnSaveEnable"
                        v-model="centreEdit.commentaire"
                        ref="commentaire"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="ficherZoneSwitch">
                <switch-button
                    :texte="'Centre national'"
                    :color="'#00ACA8'"
                    ref="isCentreNational"
                    class="ficheSwitchSpacer"
                    :isNotBlock="
                        isNotBlockSwitchButton && isAuthorizeModification
                    " />
                <switch-button
                    :texte="'Connexion PFLAU'"
                    :color="'#00ACA8'"
                    ref="isConnexionPflau"
                    class="ficheSwitchSpacer"
                    :isNotBlock="
                        isNotBlockSwitchButton && isAuthorizeModification
                    " />
            </div>
            <div class="margin-top-30-px flex">
                <WidgetAccesDepartement
                    v-show="isAuthorizeModification"
                    ref="widgetDemartement"
                    :dptCaau="this.$store.state.dptSelect"
                    :isAllSelection="false" />
            </div>
            <div class="listeDepAut">
                <div class="ficheTitle">Liste des départements autorisés</div>
                <div class="listeDepartement">
                    <div
                        class="listDepartementLine"
                        v-for="item in this.departementChecked"
                        :key="item.id">
                        <div>{{ item.codeInsee }} - {{ item.nom }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ficheRightColumn">
            <div class="flex justify-end">
                <button
                    v-if="isAuthorizeModification"
                    class="bouton-help bouton no-border plain--green"
                    @click="openClosePopupAide2">
                    <span>Aide</span>
                    <img
                        src="../../../assets/img/tnaau/Icon_video-blanc.svg"
                        alt="Aide" />
                </button>
            </div>
            <div class="backgroundModal" v-show="this.modalCaauAide2">
                <div class="windowModal">
                    <div class="headerModal">
                        <div
                            class="close-button pointer"
                            @click="openClosePopupAide2">
                            X
                        </div>
                    </div>
                    <video
                        width="800"
                        v-bind:id="'id-caau-Video-2-Changer-numero-long'"
                        controls>
                        <source
                            :src="
                                require(`@/assets/video/Video-2-Changer-numero-long.webm`)
                            "
                            type="video/webm" />
                    </video>
                </div>
            </div>
            <PostitNumeroLong :idCaau="this.idCaau" ref="numLong" />
            <div>
                <div class="ficheTitle flex margin-bottom-10-px">
                    Numéro alternatif communicable
                    <help-widget
                        class="help-widget"
                        :texte="'Ce numéro a vocation à pouvoir être communiqué auprès des usagers en cas de panne du numéro court habituel pour joindre le CAAU. Avant toute diffusion, veuillez prendre attache avec le CAAU pour obtenir confirmation.'" />
                </div>
                <textarea
                    type="text"
                    rows="3"
                    cols="40"
                    class="textAreaForm"
                    ref="numeroCommunicable"
                    @keyup="btnSaveEnable"
                    v-model="centreEdit.numero_communicable"
                    :readonly="!isAuthorizeModification" />
            </div>
            <div
                class="flex flex-wrap justify-end gap-1 margin-top-30-px"
                v-if="isAuthorizeModification">
                <ButtonWidget
                    :small-button="true"
                    bgcolor="#000091"
                    bgcolorHover="#6e6cbb"
                    logo="Icon_save.svg"
                    text="Enregistrer les modifications"
                    @click.native="saveCaau()"
                    :enable="this.saveEnable && isAuthorizeModification" />
                <button
                    class="
                        bouton bouton--border
                        background_page
                        textBlue
                        no-border no-select
                        center
                    "
                    @click="annuler">
                    Annuler
                </button>
            </div>
            <PopupInformation
                :titre="'Vos modifications ont bien été enregistrées.'"
                ref="popupCaau">
            </PopupInformation>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import EventBus from '../../../bus'
import * as eventTypes from '../../../bus/event-types.js'
import { notificationLogout } from '@/tools/authent'
import {
    emailCheck,
    notNullCheck,
    checkInput,
    isNotHtml,
    codePostalCheck,
} from '@/tools/inputCheck'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import ItemTemplate from '../template/CommuneTemplate.vue'
import Autocomplete from 'v-autocomplete'
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import PostitNumeroLong from '@/components/widgets/postitNumeroLong/PostitNumeroLong'
import PopupInformation from '@/components/widgets/popupInformation/PopupInformation'
import WidgetAccesDepartement from '@/components/widgets/widgetAccesDepartement/WidgetAccesDepartement'
import * as Roles from '../../../tools/Roles'

export default {
    name: 'FicheIdentiteCentre',
    components: {
        ButtonWidget,
        WidgetAccesDepartement,
        PostitNumeroLong,
        SwitchButton,
        Autocomplete,
        HelpWidget,
        PopupInformation,
    },
    data() {
        return {
            centreEdit: {},
            listeCommunes: [],
            isNotBlockSwitchButton: false,
            template: ItemTemplate,
            saveEnable: false,
            departementChecked: [],
            modalCaauAide2: false,
        }
    },
    props: {
        idCaau: {
            type: String,
            default: '',
        },
    },
    mounted() {
        EventBus.$on(eventTypes.BTN_ENABLE, () => {
            this.btnSaveEnable()
        })

        EventBus.$on(eventTypes.AFFECTE_DEPARTEMENT, (checked, all) => {
            this.nomDepartementChecked(checked, all)
        })
        this.isNotBlockSwitchButton =
            this.$store.state.user.profil === Roles.ADMINISTRATEUR
    },
    methods: {
        openClosePopupAide2() {
            // Fermeture de la vidéo
            const video = document.querySelector(
                '#id-caau-Video-2-Changer-numero-long'
            )
            video.pause()
            video.currentTime = 0
            // Fermeture de la fenêtre
            this.modalCaauAide2 = !this.modalCaauAide2
        },
        nomDepartementChecked(nomListeDepartement, allDepartements) {
            this.departementChecked = []
            if (nomListeDepartement.length > 0) {
                for (let idDpt of nomListeDepartement) {
                    if (
                        allDepartements.some((e) => e.idDepartement === idDpt)
                    ) {
                        let index = allDepartements
                            .map(function (e) {
                                return e.idDepartement
                            })
                            .indexOf(idDpt)
                        this.departementChecked.push(allDepartements[index])
                    }
                }
                this.departementChecked.sort((a, b) =>
                    a.codeInsee > b.codeInsee
                        ? 1
                        : b.codeInsee > a.codeInsee
                        ? -1
                        : 0
                )
            }
        },
        //Récupère les infos de la bdd pour réinitialiser la page
        annuler() {
            this.saveEnable = false
            EventBus.$emit(eventTypes.BTN_ANNULER, {})
        },
        selectionCom(item) {
            if (item !== null) {
                this.centreEdit.nom_commune = item.nom
                this.centreEdit.idCommune = item.idCommune
            }
        },
        checkCommune(text) {
            this.btnSaveEnable()
            if (text.length > 2) {
                axios
                    .get(
                        '/commune?nomCommune=' +
                            text +
                            '&idDepartement=' +
                            this.$store.state.detailDptSelectId,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.listeCommunes = response.data
                        } else {
                            this.listeCommunes = []
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de récupérer la liste des communes",
                                type: 'error',
                            })
                        }
                        notificationLogout(this, e)
                    })
            } else {
                this.listeCommunes = []
            }
        },
        btnSaveEnable() {
            this.saveEnable = true
        },
        saveCaau() {
            if (this.saveEnable && this.isAuthorizeModification) {
                let isNumContact = checkInput(
                    this.$refs.contactTel,
                    this.centreEdit.contact_telephone
                )
                if (!isNumContact) {
                    this.$notify({
                        group: 'general-notification',
                        title:
                            'Le numéro de téléphone ' +
                            this.centreEdit.contact_telephone +
                            " n'est pas au bon format.",
                        text: 'Cliquez sur ? pour les informations.',
                        type: 'error',
                        duration: 5000,
                    })
                }

                let isNumeroCommunicable = checkInput(
                    this.$refs.numeroCommunicable,
                    isNotHtml(this.centreEdit.numero_communicable)
                )

                let inputContactFonction = this.$refs.contactFonction
                let formulaireValidFonction = checkInput(
                    inputContactFonction,
                    notNullCheck(this.centreEdit.contact_fonction)
                )

                let inputContactMail = this.$refs.contactMail
                let formulaireValidMail = checkInput(
                    inputContactMail,
                    emailCheck(this.centreEdit.contact_email)
                )

                //Adresse CAAU
                let inputAdresseVoie = this.$refs.inputVoie
                let formulaireValidVoie = checkInput(
                    inputAdresseVoie,
                    notNullCheck(this.centreEdit.adresse_nom_voie)
                )

                let formulaireCommentaire = checkInput(
                    this.$refs.commentaire,
                    isNotHtml(this.centreEdit.commentaire)
                )

                let inputAdresseCp = this.$refs.inputCp
                let formulaireValidCp = checkInput(
                    inputAdresseCp,
                    notNullCheck(this.centreEdit.adresse_cp) &&
                        codePostalCheck(this.centreEdit.adresse_cp)
                )

                if (
                    isNumContact &&
                    isNumeroCommunicable &&
                    formulaireValidVoie &&
                    formulaireCommentaire &&
                    formulaireValidCp &&
                    formulaireValidFonction &&
                    formulaireValidMail
                ) {
                    this.$refs.popupCaau.modal = true
                    axios
                        .post(
                            '/caau/update/?idCaau=' +
                                this.idCaau +
                                '&idDepartement=' +
                                this.$store.state.detailDptSelectId,
                            {
                                estCentreNational:
                                    this.$refs.isCentreNational.status,
                                estConnexionPflau:
                                    this.$refs.isConnexionPflau.status,
                                commentaire: this.centreEdit.commentaire,
                                adresseNomVoie:
                                    this.centreEdit.adresse_nom_voie,
                                adresseCp: this.centreEdit.adresse_cp,
                                idCommune: this.centreEdit.idCommune,
                                contactFonction:
                                    this.centreEdit.contact_fonction,
                                contactEmail: this.centreEdit.contact_email,
                                contactTelephone:
                                    this.centreEdit.contact_telephone,
                                numeroCommunicable:
                                    this.centreEdit.numero_communicable,
                                departementsAcces:
                                    this.$refs.widgetDemartement
                                        .checkedDepartement,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        this.$store.state.user.token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                this.$notify({
                                    group: 'general-notification',
                                    title:
                                        'Le caau ' +
                                        this.centreEdit.identifiant_caau +
                                        ' a bien été modifié',
                                    text: '',
                                    type: 'success',
                                    duration: 5000,
                                })
                                this.saveEnable = false
                            } else {
                                this.$notify({
                                    group: 'general-notification',
                                    title: response.status,
                                    text: response.statusText,
                                    type: 'warn',
                                    duration: 5000,
                                })
                            }
                        })
                        .catch((e) => {
                            if (e.response.status === 403) {
                                this.$notify({
                                    group: 'general-notification',
                                    title: 'Interdiction',
                                    text: "Vous n'avez pas l'autorisation de modifier un caau",
                                    type: 'error',
                                    duration: 5000,
                                })
                            }
                            notificationLogout(this, e)
                        })
                }
            }
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.BTN_ENABLE)
    },
    computed: {
        isAuthorizeModification() {
            return (
                this.$store.state.dptSelect.idDepartement !== 0 &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    (this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION &&
                        this.centreEdit.acces_caau))
            )
        },
    },
}
</script>

<style scoped>
.ficheGlobalBox {
    display: flex;
    flex-wrap: wrap;
    grid-area: 2rem;
    background: #eeeffa;
    border-radius: 0 10px 10px 10px;
    padding: 2rem;
    margin-top: 15px;
}

.ficheLeftColumn {
    width: 90px;
    margin: -2rem 0 0 -2rem;
}

.ficheMiddleColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ficheRightColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
}

.ficheNumCourt {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #e1000f;
    border-bottom-right-radius: 20px;
    font-family: 'Marianne Medium';
    font-size: 22px;
    color: #ffffff;
    height: 50px;
    min-width: 50px;
    margin-right: 10px;
    padding: 5px;
}

.ficheGlobalAddresse {
    display: inline-flex;
}

.ficheAddresse {
    display: inline-flex;
    font-family: 'Marianne Light';
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
}

.ficheAddresseModification {
    display: flex;
    flex-direction: column;
    font-family: 'Marianne Light';
    font-size: 16px;
    padding-top: 15px;
}

.inputForm {
    height: 30px;
    background-color: #dfe0ed;
    font-family: 'Marianne Light';
    font-size: 14px;
    padding-left: 5px;
}

.inputFormCp {
    max-width: 70px;
    margin-right: 5px;
    margin-top: 15px;
}

.inputSearchFormVille {
    flex: 1;
    margin-top: 15px;
    height: 30px;
    max-width: 240px;
    border-radius: 5px;
    background: #dfe0ed;
}

.ficheDateNotification {
    display: inline-flex;
    font-family: 'Marianne Light';
    font-size: 14px;
    margin-top: 10px;
}

.ficheRow {
    background: inherit;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
}

.ficheTitle {
    display: block;
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.inputForm {
    background: #dfe0ed;
    width: 260px;
}

.ficheCommentaireTitle {
    display: inline-flex;
    font-family: 'Marianne Medium';
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
}

.ficheCommentaireTitle:before {
    content: url('../../../assets/img/tnaau/Icon_blocnote.svg');
    width: 20px;
    height: 20px;
}

.textAreaForm {
    background: #dfe0ed;
}

.ficheSwitchSpacer {
    margin: 10px 0 10px 0;
}

.postitModifier {
    display: inline-flex;
    font-family: 'Marianne Regular';
    font-size: 14px;
    color: #000091;
    align-items: center;
    justify-content: right;
    margin-bottom: 30px;
    margin-right: 30px;
}

.postitModifier:before {
    content: url('../../../assets/img/tnaau/Icon_crayon.svg');
    margin-left: 10px;
    margin-right: 5px;
    height: 10px;
    position: relative;
    top: -3px;
}

.listeDepartement {
    display: inline-flex;
    flex-direction: column;
    height: 80px;
    width: 350px;
    overflow-y: scroll;
    padding-left: 20px;
    margin-right: 40px;
}

.listDepartementLine {
    display: inline-flex;
    font-family: 'Marianne Light';
    font-size: 14px;
}
.close-button {
    margin-left: auto;
    margin-right: 0;
    color: #000;
}
</style>

<template>
    <!-- CONTENT -->
    <div class="container">
        <TitreReference :reference="'Modification'" :texte="'Compte'" />
        <div class="flex-column padding-top-100-px">
            <div class="formInputField">
                <div>
                    <div class="font-label-input padding-bottom-10-px">Nom</div>

                    <input
                        class="inputForm"
                        type="texte"
                        v-model="nom"
                        :placeholder="this.nom"
                        ref="inputNom" />
                </div>
            </div>
            <div class="formInputField">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Email de connexion
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="email"
                        :placeholder="this.email"
                        ref="inputEmail" />
                </div>
            </div>
            <ProfilSelector class="flex-column formfield" ref="profil" />
            <StructureSelector
                class="flex-column formfield"
                ref="structure"
                v-show="isDirectionMetier" />
            <div class="formInputField" v-show="isPrefectureModif && isAdmin">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Contact téléphonique
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="contactTel"
                        ref="contactTel" />
                </div>
            </div>
            <div class="formInputField" v-show="isPrefectureModif && isAdmin">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Commentaire
                    </div>
                    <textarea
                        class="textAreaForm"
                        type="text"
                        v-model="commentaire"
                        ref="commentaire"
                        cols="50"
                        rows="5" />
                </div>
            </div>
            <WidgetAccesDepartement
                ref="widgetDemartement"
                :isAllSelection="true" />
            <switch-button
                :texte="'Actif'"
                :color="'#00ACA8'"
                ref="switchEnable"
                class="ficheSwitchSpacer formfield" />
            <switch-button
                :texte="'Afficher dans la liste des départements : France entière'"
                :color="'#00ACA8'"
                ref="switchFranceEnable"
                class="ficheSwitchSpacer formfield"
                v-show="this.btnDroitFranceEntiereVisible" />
        </div>
        <div class="flex">
            <button
                class="
                    bouton
                    background_page
                    texte_bleu_fonce
                    no-border
                    margin-bottom-10-px margin-left-30-px
                "
                @click="annuler()">
                Annuler
            </button>
            <button
                class="
                    bouton
                    boutonValidation
                    background_bleu_tres_fonce
                    texte_blanc
                    no-border
                    margin-left-20-px
                "
                @click="modifier">
                <img
                    src="../../assets/img/tnaau/Icon_check_mark.svg"
                    class="margin-right-10-px" />
                Modification
            </button>
        </div>

        <button
            class="
                bouton
                boutonValidation
                background_rouge
                texte_blanc
                no-border
                margin-left-20-px
            "
            @click="openPopupSuppression">
            Suppression définitive
        </button>
        <PopupConfirmation
            :titre="'Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ?'"
            ref="popupSuppression" />
    </div>
</template>

<script>
import axios from 'axios'
import {
    emailCheck,
    notNullCheck,
    checkInput,
    isNotHtml,
} from '@/tools/inputCheck'
import { notificationLogout } from '../../tools/authent'
import * as eventTypes from '../../bus/event-types.js'
import EventBus from '../../bus'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import StructureSelector from '@/components/widgets/structureSelector/StructureSelector'
import ProfilSelector from '@/components/widgets/profilSelector/ProfilSelector'
import WidgetAccesDepartement from '@/components/widgets/widgetAccesDepartement/WidgetAccesDepartement'
import PopupConfirmation from '@/components/widgets/popupConfirmation/PopupConfirmation'
import * as Roles from '@/tools/Roles'

export default {
    name: 'CompteDetail',
    components: {
        TitreReference,
        SwitchButton,
        WidgetAccesDepartement,
        PopupConfirmation,
        StructureSelector,
        ProfilSelector,
    },
    data() {
        return {
            idUtilisateur: 0,
            nom: '',
            email: '',
            enable: false,
            btnDroitFranceEntiereVisible: false,
            departementsUtilisateur: [],
            profil: 0,
            structure: 0,
            commentaire: '',
            contactTel: '',
            isDirectionMetier: false,
            isPrefectureModif: false,
        }
    },
    methods: {
        //Action pour ouvrir la popup de confirmation de suppression
        openPopupSuppression() {
            this.$refs.popupSuppression.modal = true
        },
        //Action permettant d'annuler la modification et donc d'aller sur la liste des comptes
        annuler() {
            window.location.href = '/#/comptes'
        },
        //Action permettant de supprimer un utilisateur + les départements auxquels il avait accès
        supprimer() {
            axios
                .delete('/utilisateur/' + this.idUtilisateur, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text:
                                'Le compte "' +
                                this.nom +
                                '" a bien été supprimé',
                            type: 'success',
                            duration: 5000,
                        })
                        window.location.href = '/#/comptes'
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'success',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Inconnu',
                            text: "Le compte recherché n'existe pas",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        //Action permettant de modifier un utilisateur + les départements auxquels il a accès
        modifier() {
            //Vérification des champs
            let formulaireValid = true
            this.enable = this.$refs.switchEnable.status
            this.departementsUtilisateur =
                this.$refs.widgetDemartement.checkedDepartement
            this.profil = this.$refs.profil.selected
            this.structure = this.$refs.structure.selected

            if (this.departementsUtilisateur.length == 0) {
                formulaireValid = false
                this.$notify({
                    group: 'general-notification',
                    title: 'Pas de département sélectionné',
                    text: "Merci d'autoriser au moins un département",
                    type: 'warn',
                    duration: 5000,
                })
            }
            let checkEmail = checkInput(
                this.$refs.inputEmail,
                emailCheck(this.email)
            )
            let checkNom = checkInput(
                this.$refs.inputNom,
                notNullCheck(this.nom)
            )

            let checkPhone =
                !this.contactTel ||
                checkInput(this.$refs.contactTel, isNotHtml(this.contactTel))
            let checkCommentaire =
                !this.commentaire ||
                checkInput(this.$refs.commentaire, isNotHtml(this.commentaire))

            let checkStructure = this.isDirectionMetier
                ? checkInput(
                      this.$refs.structure.$el.lastChild,
                      notNullCheck(this.structure)
                  )
                : true
            let checkProfil = checkInput(
                this.$refs.profil.$el.lastChild,
                notNullCheck(this.profil)
            )

            if (
                formulaireValid &&
                checkEmail &&
                checkNom &&
                checkCommentaire &&
                checkPhone &&
                checkStructure &&
                checkProfil
            ) {
                axios
                    .put(
                        '/utilisateur/' + this.idUtilisateur,
                        {
                            email: this.email,
                            nom: this.nom,
                            idProfil: this.profil,
                            idStructure: this.structure,
                            commentaire: this.commentaire,
                            contactTel: this.contactTel,
                            enable: this.enable,
                            droitFranceEntiere:
                                this.$refs.switchFranceEnable.status,
                            departementsAcces: this.departementsUtilisateur,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title: '',
                                text:
                                    'Le compte de ' +
                                    this.nom +
                                    ' a bien été modifié',
                                type: 'success',
                                duration: 5000,
                            })
                            window.location.href = '/#/comptes'
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'success',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (
                            e.response.status === 403 ||
                            e.response.status === 400
                        ) {
                            var msgError =
                                "Vous n'avez pas l'autorisation de modifier un utilisateur"
                            if (e.response.data !== undefined) {
                                msgError = e.response.data.message
                            }
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: msgError,
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
    },
    mounted() {
        //Récupère l'id utilisateur dans l'url
        this.idUtilisateur = this.$route.query.id
        //Récupère le changement d'état pour vérifier si on supprime l'utilisateur
        //Pour la pop up de suppression
        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP, (args) => {
            if (args) {
                this.supprimer(this.idUtilisateur)
            }
        })
        EventBus.$on(eventTypes.SHOW_STRUCTURE, (args) => {
            this.isDirectionMetier = args
            this.structure = null
            this.$refs.structure.selected = this.structure
        })
        EventBus.$on(eventTypes.SHOW_PREFECTURE_MODIF, (args) => {
            this.isPrefectureModif = args
            this.commentaire = null
            this.contactTel = null
        })
        EventBus.$on(eventTypes.UPDATE_BTN_FRANCE_ENTIERE, (args) => {
            if (
                args === Roles.GESTIONNAIRE_OCE ||
                args === Roles.DIRECTION_METIER ||
                args === Roles.ADMINISTRATEUR
            ) {
                this.$refs.switchFranceEnable.status = true
                this.btnDroitFranceEntiereVisible = true
            } else {
                this.$refs.switchFranceEnable.status = false
                this.btnDroitFranceEntiereVisible = false
            }
        })

        // //Récupère le détail d'un utilisateur
        axios
            .get('/utilisateur/info/' + this.idUtilisateur, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                this.enable = response.data.enable
                this.$refs.switchEnable.status = this.enable
                this.$refs.switchFranceEnable.status =
                    response.data.droitFranceEntiere
                this.nom = response.data.nom
                this.email = response.data.email
                this.profil = response.data.id_profil
                this.contactTel = response.data.contactTel
                this.commentaire = response.data.commentaire
                this.$refs.profil.selected = this.profil
                this.structure = response.data.id_structure
                this.$refs.structure.selected = this.structure
                this.departementsUtilisateur = response.data.departements_access
                this.$refs.widgetDemartement.checkedDepartement =
                    response.data.departements_access

                // On vérifie à l'initialisation de la page si le profil de l'utilisateur est DIRECTION_METIER
                // afin de savoir si la structure doit être affiché
                if (response.data.nom_profil === Roles.DIRECTION_METIER) {
                    this.isDirectionMetier = true
                }
                if (
                    response.data.nom_profil === Roles.PREFECTURE_MODIFICATION
                ) {
                    this.isPrefectureModif = true
                }
                if (
                    response.data.nom_profil === Roles.DIRECTION_METIER ||
                    response.data.nom_profil === Roles.GESTIONNAIRE_OCE ||
                    response.data.nom_profil === Roles.ADMINISTRATEUR
                ) {
                    this.btnDroitFranceEntiereVisible = true
                }
            })
            .catch((e) => {
                if (e.response.status === 403) {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Interdiction',
                        text: "Vous n'avez pas l'autorisation",
                        type: 'error',
                        duration: 5000,
                    })
                }
                if (e.response.status === 404) {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Inconnu',
                        text: "Le compte recherché n'existe pas",
                        type: 'warn',
                        duration: 5000,
                    })
                }
                notificationLogout(this, e)
            })
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isAdmin() {
            return this.$store.state.user.profil === Roles.ADMINISTRATEUR
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP)
        EventBus.$off(eventTypes.SHOW_STRUCTURE)
        EventBus.$off(eventTypes.SHOW_PREFECTURE_MODIF)
        EventBus.$off(eventTypes.UPDATE_BTN_FRANCE_ENTIERE)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.formInputField {
    margin-left: 15px;
    padding-bottom: 20px;
}

.formfield {
    padding-left: 15px;
    padding-bottom: 20px;
}

.annuler {
    font-family: 'Marianne Regular';
    font-size: 14px;
    color: #000091;
    cursor: pointer;
}

.bouton {
    width: 186px;
    margin-left: 10px;
}

.ficheSwitchSpacer {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>

<template>
    <section class="sectionColumn">
        <div class="font-label-input padding-bottom-10-px">Structure</div>
        <select v-model="selected" class="selectForm">
            <option
                class="optionForm"
                v-for="option in this.structureList"
                :key="option.idStructure"
                :value="option.idStructure">
                {{ option.libelle }}
            </option>
        </select>
    </section>
</template>

<script>
import axios from 'axios'
import { notificationLogout } from '../../../tools/authent'

export default {
    name: 'StructureSelector',
    data() {
        return {
            selected: '',
            structureList: [],
        }
    },
    mounted() {
        axios
            .get('/structure', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                this.structureList = Array.from(response.data.data)
            })
            .catch((e) => {
                notificationLogout(this, e)
            })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

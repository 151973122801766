<template>
    <section>
        <div class="flex flex-column">
            <div class="postitGlobalBox postitGlobalBoxBlack">
                <div class="postitLine">
                    <div class="postitTitreNumero uppercase">
                        Numéro long actuel
                    </div>
                    <div class="postitNumero uppercase">
                        {{ this.numLongActuel }}
                    </div>
                </div>
                <div class="postitLine">
                    <div class="inline-flex" v-if="this.jusquau">
                        <div class="postitTitreDate">Jusqu'au</div>
                        <div class="postitDate">{{ this.jusquau }}</div>
                    </div>
                </div>
            </div>
            <div
                class="postitGlobalBox postitGlobalBoxRed"
                v-if="this.numLongNouveau">
                <div class="postitLine">
                    <div
                        class="
                            postitTitreNumeroRed
                            postitTitreNumero
                            uppercase
                        ">
                        Nouveau numéro long
                    </div>
                    <div class="postitNumero uppercase">
                        {{ this.numLongNouveau }}
                    </div>
                </div>
                <div class="postitLine">
                    <div class="inline-flex">
                        <div class="postitTitreDate">A compter du</div>
                        <div class="postitDate">{{ this.acompter }}</div>
                    </div>
                    <div
                        v-show="isAuthorizeModification && acces_caau"
                        class="postitModifier pointer"
                        @click="openPopupNouveauNumero()">
                        Modifier
                    </div>
                </div>
            </div>
            <div
                class="postitNouveauNumero"
                v-if="
                    !this.numLongNouveau &&
                    isAuthorizeModification &&
                    acces_caau
                ">
                <button
                    class="buttonRed pointer"
                    @click="openPopupNouveauNumero()">
                    <div class="buttonNouveauNumero">
                        Modifier le numéro long
                    </div>
                </button>
            </div>
            <PopupNumeroLong
                :idCaau="this.idCaau"
                :numLongActuel="this.numLongActuel"
                ref="popupNouveau" />
        </div>
    </section>
</template>

<script>
import PopupNumeroLong from '@/components/widgets/popupNumeroLong/PopupNumeroLong'
import * as eventTypes from '../../../bus/event-types.js'
import EventBus from '../../../bus'
import moment from 'moment'
import * as Roles from '../../../tools/Roles'

export default {
    name: 'PostitNumeroLong',
    components: { PopupNumeroLong },
    data() {
        return {
            numLongActuel: '',
            numLongNouveau: '',
            jusquau: '',
            acompter: '',
            acces_caau: false,
        }
    },
    props: {
        idCaau: {
            type: String,
            default: '',
        },
    },
    methods: {
        //Action pour ouvrir la popup d'un nouveau numéro long
        openPopupNouveauNumero() {
            this.$refs.popupNouveau.modal = true
            this.$refs.popupNouveau.newTel = this.numLongNouveau
            if (this.acompter) {
                var dateParts = this.acompter.split('/')
                let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                this.$refs.popupNouveau.dateActif =
                    moment(d).format('YYYY-MM-DD')
            } else {
                this.$refs.popupNouveau.dateActif = moment()
                    .add(16, 'days')
                    .format('YYYY-MM-DD')
            }
        },
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_NUMERO_LONG, (obj) => {
            this.numLongActuel = obj.numeroLongAncien
            this.numLongNouveau = obj.numeroLongNouveau
            this.jusquau = obj.jusqu_au
            this.acompter = moment(obj.a_compter_du).format('DD/MM/YYYY')
        })
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_NUMERO_LONG)
    },
    computed: {
        isAuthorizeModification() {
            return (
                this.$store.state.dptSelect.idDepartement !== 0 &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION)
            )
        },
    },
}
</script>

<style scoped>
.postitNouveauNumero {
    display: inline-flex;
}

.postitGlobalBox {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 408px;
    min-height: 100px;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
}

.postitGlobalBoxRed {
    border: 1px solid #e1000f;
    margin-top: 20px;
}

.postitGlobalBoxBlack {
    box-shadow: 0px 3px 6px #00000029;
}

.postitLine {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.alignLeft {
    justify-content: left;
}

.postitTitreNumero {
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.postitNumero {
    font-family: 'Marianne Light';
    font-size: 16px;
}

.postitTitreNumeroRed:before {
    content: url('../../../assets/img/tnaau/Icon_pointrouge.png');
    margin-right: 5px;
}

.postitTitreDate {
    display: inline-flex;
    font-family: 'Marianne Medium';
    font-size: 12px;
}

.postitTitreDate:before {
    content: url('../../../assets/img/tnaau/Icon_calendar.svg');
    width: 20px;
    height: 20px;
}

.postitDate {
    display: inline-flex;
    font-family: 'Marianne Light';
    font-size: 14px;
    margin-left: 10px;
}

.postitModifier {
    display: inline-flex;
    font-family: 'Marianne Regular';
    font-size: 14px;
    color: #000091;
    align-items: center;
    justify-content: center;
}

.postitModifier:before {
    content: url('../../../assets/img/tnaau/Icon_crayon.svg');
    margin-left: 10px;
    margin-right: 5px;
    height: 10px;
    position: relative;
    top: -3px;
}

.buttonNouveauNumero {
    padding-left: 10px;
}
</style>

<template>
    <section class="main">
        <div class="middle-container flex-row-center">
            <div class="left_block inline-flex-column-left">
                <div class="logo_tnaau" />
                <div class="titre-tnaau">
                    Table nationale d’acheminement des appels d’urgence
                </div>
            </div>
            <form
                @submit.prevent="clickInit"
                class="right_block inline-flex-column-center">
                <div class="flex-row padding-bottom-40-px">
                    <div class="ligne_connexion"></div>
                    <div class="titre-connexion">
                        Initialisation du mot de passe
                    </div>
                    <div class="ligne_connexion"></div>
                </div>
                <div
                    id="password-constraints"
                    class="titre_help margin-bottom-20-px">
                    12 caractères minimum<br />Au moins 1 majuscule<br />Au
                    moins 1 minuscule<br />Au moins 1 caractère spécial<br />Au
                    moins 1 chiffre
                </div>
                <div
                    class="inline-flex-column-left padding-bottom-20-px"
                    v-show="displayOn">
                    <label for="new-password" class="titre-champ"
                        >Mot de passe</label
                    >
                    <input
                        type="password"
                        id="new-password"
                        autocomplete="new-password"
                        aria-describedby="password-constraints"
                        class="input_custom background_gris_clair"
                        placeholder="Mot de passe"
                        v-model="password"
                        @input="checkPassword"
                        ref="inputPassword" />
                </div>
                <div
                    class="inline-flex-column-left margin-bottom-20-px"
                    v-show="displayOn">
                    <label for="confirm-password" class="titre-champ"
                        >Confirmer le mot de passe</label
                    >
                    <input
                        type="password"
                        id="confirm-password"
                        autocomplete="new-password"
                        class="input_custom background_gris_clair"
                        placeholder="Confirmer le mot de passe"
                        v-model="passwordConfirm"
                        @input="checkPasswordConfirm"
                        ref="inputPasswordConfirm" />
                </div>
                <div
                    class="titre_help texte_rouge_fonce margin-bottom-20-px"
                    v-show="notSame">
                    Les mots de passe ne correspondent pas
                </div>
                <div class="zone_bouton">
                    <div class="flex" v-show="displayOn">
                        <button
                            class="
                                bouton
                                background_rouge
                                texte_blanc
                                no-border
                            ">
                            Valider
                        </button>
                        <button
                            type="button"
                            class="
                                bouton
                                background_gris
                                texte_blanc
                                no-border
                                margin-left-30-px
                            "
                            @click="goto('/')"
                            v-show="displayOn">
                            Retour
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { passwordCheck } from '../../tools/inputCheck'
import { checkInput } from '../../tools/inputCheck'

export default {
    name: 'Register',
    components: {},
    data() {
        return {
            password: '',
            passwordConfirm: '',
            displayOn: true,
            notSame: false,
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        checkPassword() {
            checkInput(
                this.$refs['inputPassword'],
                passwordCheck(this.password)
            )
        },
        checkPasswordConfirm() {
            this.notSame = this.password !== this.passwordConfirm
            checkInput(
                this.$refs['inputPasswordConfirm'],
                passwordCheck(this.passwordConfirm)
            )
        },
        clickInit() {
            if (null === passwordCheck(this.password)) {
                this.$notify({
                    group: 'general-notification',
                    title: 'Mot de passe incorrect',
                    text: 'Le mot de passe ne correspond pas au format attendu',
                    type: 'warn',
                    duration: 5000,
                })
                return
            }
            if (this.notSame) {
                this.$notify({
                    group: 'general-notification',
                    title: 'Mot de passe incorrect',
                    text: 'Les 2 mots de passe sont différents',
                    type: 'warn',
                    duration: 5000,
                })
                return
            }
            axios
                .post(
                    '/validation?code=' + this.$router.currentRoute.query.code,
                    {},
                    {
                        headers: {
                            password: this.password,
                        },
                    }
                )
                .then(() => {
                    this.displayOn = false
                    this.goto('/')
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        this.$notify({
                            group: 'general-notification',
                            title: "Erreur lors de l'initialisation",
                            text: e.response.data.message,
                            type: 'error',
                            duration: 5000,
                        })
                    } else if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur',
                            text: "Le code n'est pas valide",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                })
        },
    },
}
</script>

<style scoped>
/* container principal pour le login */

.left_block {
    width: 500px;
    height: 300px;
}

.right_block {
    width: 400px;
    height: 400px;
}

.titre-connexion {
    align-items: center;
    text-align: center;
}

.titre_help {
    display: inline-flex;
    justify-content: left;
    font-family: 'Marianne Regular';
    font-size: 12px;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 4px;
}

.input_custom {
    width: 340px;
}

.bouton {
    width: 206px;
}

.bouton:hover {
    opacity: 0.8;
}
</style>

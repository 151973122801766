<template>
    <section>
        <div class="backgroundModal" v-show="this.modal">
            <div class="windowModal">
                <div class="headerModal">
                    <div class="inline-flex" />
                    <div class="inline-flex"></div>
                    <div
                        class="inline-flex pointer close-button"
                        @click="fermer()">
                        X
                    </div>
                </div>
                <div class="contentModal" v-if="titre != ''">
                    {{ this.titre }}
                </div>
                <div class="contentModal" v-if="message != ''">
                    {{ this.message }}
                </div>
                <div class="contentModal">
                    <slot />
                </div>
                <button class="buttonRed pointer" @click="fermer()">
                    <div class="div_ok">Ok</div>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PopupInformation',
    components: {},
    data() {
        return {
            modal: false,
        }
    },
    props: {
        titre: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
    },
    methods: {
        //Lors de l'annulation, popup devient invisible
        fermer() {
            this.modal = false
        },
    },
}
</script>

<style scoped>
.div_ok {
    margin-left: auto;
    margin-right: auto;
}
.buttonRed {
    width: 110px;
    padding-left: 0px;
}
.close-button {
    margin-left: auto;
    margin-right: 0;
    color: #000;
}
</style>

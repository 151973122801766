<template>
    <!-- CONTENT -->
    <div class="container">
        <TitreReference :reference="'Modification'" :texte="'Opérateur'" />
        <div class="flex-column">
            <br />
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Code ARCEP
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="codearcep"
                        ref="codearcep"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Nom opérateur
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="nomoperateur"
                        ref="nomoperateur"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Mail de réception des PDAAU
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="emailutilisateur"
                        ref="emailutilisateur"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Email du responsable
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="contactemail"
                        ref="contactemail"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Téléphone du responsable
                    </div>
                    <input
                        class="inputForm"
                        type="tel"
                        v-model="contacttel"
                        ref="contacttel"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        SIRET
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="siret"
                        ref="siret"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Contact adresse
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="contactadresse"
                        ref="contactadresse"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Type ressource
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="typeressource"
                        ref="typeressource"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="flex flex-wrap gap-1">
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Support exploitation heures ouvrées
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="supportheuresouvrees"
                        ref="supportheuresouvrees"
                        :readonly="!isAuthorizeModification" />
                </div>
                <div class="formInputField">
                    <div class="font-label-input padding-bottom-10-px">
                        Support exploitation heures non ouvrées
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="supportheuresnonouvrees"
                        ref="supportheuresnonouvrees"
                        :readonly="!isAuthorizeModification" />
                </div>
            </div>
            <div class="formInputField">
                <div class="font-label-input padding-bottom-10-px">
                    Commentaire
                </div>
                <textarea
                    class="textAreaForm"
                    type="text"
                    v-model="commentaire"
                    ref="commentaire"
                    cols="50"
                    rows="5"
                    :readonly="!isAuthorizeModification" />
            </div>
            <WidgetAccesDepartement
                ref="widgetDepartement"
                :isAllSelection="true"
                v-if="isAuthorizeModification" />
            <div class="flex flex-wrap gap-1">
                <switch-button
                    :texte="'Opérateur principal'"
                    :color="'#00ACA8'"
                    ref="isOperateurPrincipal"
                    class="ficheSwitchSpacer"
                    :isNotBlock="isAuthorizeModification" />
                <switch-button
                    :texte="'Actif'"
                    :color="'#00ACA8'"
                    ref="isEnable"
                    class="ficheSwitchSpacer"
                    :isNotBlock="isAuthorizeModification" />
                <switch-button
                    v-if="isIntegration"
                    :texte="'Acquittement auto (Uniquement via API)'"
                    :color="'#00ACA8'"
                    ref="isAcquittementAuto"
                    class="ficheSwitchSpacer"
                    :isNotBlock="isAuthorizeModification" />
                <switch-button
                    :texte="'Téléchargement auto ok'"
                    :color="'#00ACA8'"
                    ref="isTelechargementAutoOk"
                    class="ficheSwitchSpacer"
                    :isNotBlock="isAuthorizeModification" />
            </div>
            <div
                class="flex flex-wrap gap-1 justify-center"
                v-if="isAuthorizeModification">
                <button
                    class="
                        bouton
                        background_page
                        texte_bleu_fonce
                        no-border
                        margin-bottom-10-px
                    "
                    @click="annuler">
                    Annuler
                </button>
                <button
                    class="
                        bouton
                        boutonValidation
                        background_bleu_tres_fonce
                        texte_blanc
                        no-border
                    "
                    @click="modifier">
                    <img
                        src="../../assets/img/tnaau/Icon_check_mark.svg"
                        class="margin-right-10-px" />
                    Modifier
                </button>
                <button
                    class="
                        bouton
                        boutonValidation
                        background_rouge
                        texte_blanc
                        no-border
                    "
                    @click="openPopupSuppression">
                    <img
                        src="../../assets/img/tnaau/Icon_check_mark.svg"
                        class="margin-right-10-px" />
                    Suppression définitive
                </button>
                <PopupConfirmation
                    :titre="'Êtes-vous sûr de vouloir supprimer définitivement cet opérateur ?'"
                    ref="popupSuppression" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import {
    emailCheck,
    notNullCheck,
    checkInput,
    isNotHtml,
} from '@/tools/inputCheck'
import { notificationLogout } from '@/tools/authent'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import WidgetAccesDepartement from '@/components/widgets/widgetAccesDepartement/WidgetAccesDepartement'
import PopupConfirmation from '@/components/widgets/popupConfirmation/PopupConfirmation'
import * as Roles from '@/tools/Roles'

export default {
    name: 'OperateurDetail',
    components: {
        TitreReference,
        SwitchButton,
        WidgetAccesDepartement,
        PopupConfirmation,
    },
    data() {
        return {
            idOperateur: 0,
            nomoperateur: '',
            emailutilisateur: '',
            contactemail: '',
            contacttel: '',
            contactadresse: '',
            codearcep: '',
            siret: '',
            typeressource: '',
            commentaire: '',
            operateurPrincipal: false,
            enable: false,
            departementsUtilisateur: [],
            supportheuresouvrees: '',
            supportheuresnonouvrees: '',
            acquittementAuto: false,
            telechargementAutoOk: false,
        }
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isAuthorizeModification() {
            return (
                this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                this.$store.state.user.profil === Roles.GESTIONNAIRE_OCE
            )
        },
        isIntegration() {
            return this.$store.state.integration
        },
    },
    mounted() {
        //Récupère le changement d'état pour vérifier si on supprime l'opérateur
        //Pour la pop up de suppression
        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP, (args) => {
            if (args) {
                this.supprimer(this.idOperateur)
            }
        })
        //Récupère l'id utilisateur dans l'url
        this.idOperateur = this.$route.query.id
        //Récupère le détail d'un opérateur
        axios
            .get('/operateur/info/' + this.idOperateur, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                this.nomoperateur = response.data.nom_opeprateur
                this.emailutilisateur = response.data.mail_utilisateur
                this.contactemail = response.data.contact_email
                this.contacttel = response.data.contact_telephone
                this.contactadresse = response.data.contact_adresse
                this.codearcep = response.data.code_arcep
                this.siret = response.data.siret
                this.typeressource = response.data.type_ressource
                this.commentaire = response.data.commentaire
                this.operateurPrincipal = response.data.operateur_principal
                this.enable = response.data.enable
                this.departementsUtilisateur = response.data.departement_access
                this.supportheuresouvrees = response.data.support_heures_ouvrees
                this.supportheuresnonouvrees =
                    response.data.support_heures_non_ouvrees

                this.acquittementAuto = response.data.acquittement_auto
                this.telechargementAutoOk = response.data.telechargement_Auto_Ok

                this.$refs.isEnable.status = this.enable
                this.$refs.isOperateurPrincipal.status = this.operateurPrincipal
                this.$refs.widgetDepartement.checkedDepartement =
                    this.departementsUtilisateur
                if (this.$refs.isAcquittementAuto) {
                    this.$refs.isAcquittementAuto.status = this.acquittementAuto
                }
                this.$refs.isTelechargementAutoOk.status =
                    this.telechargementAutoOk
            })
            .catch((e) => {
                if (e.response.status === 403) {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Interdiction',
                        text: "Vous n'avez pas l'autorisation",
                        type: 'error',
                        duration: 5000,
                    })
                    window.location.href = '/#/operateurs'
                }
                if (e.response.status === 404) {
                    this.$notify({
                        group: 'general-notification',
                        title: 'Inconnu',
                        text: "Le compte recherché n'existe pas",
                        type: 'warn',
                        duration: 5000,
                    })
                    window.location.href = '/#/operateurs'
                }
                notificationLogout(this, e)
            })
    },
    methods: {
        //Action pour ouvrir la popup de confirmation de suppression
        openPopupSuppression() {
            this.$refs.popupSuppression.modal = true
        },
        //Action permettant de supprimer un operateur
        supprimer() {
            axios
                .delete('/operateur/' + this.idOperateur, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'general-notification',
                            title: '',
                            text:
                                'Le compte de ' +
                                this.nomoperateur +
                                ' a bien été supprimé',
                            type: 'success',
                            duration: 5000,
                        })
                        window.location.href = '/#/operateurs'
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Inconnu',
                            text: "Le compte recherché n'existe pas",
                            type: 'warn',
                            duration: 5000,
                        })
                        window.location.href = '/#/operateurs'
                    }
                    notificationLogout(this, e)
                })
        },
        modifier() {
            this.enable = this.$refs.isEnable.status
            this.operateurPrincipal = this.$refs.isOperateurPrincipal.status
            this.departementsUtilisateur =
                this.$refs.widgetDepartement.checkedDepartement
            this.acquittementAuto = this.$refs.isAcquittementAuto
                ? this.$refs.isAcquittementAuto.status
                : false
            this.telechargementAutoOk = this.$refs.isTelechargementAutoOk.status

            // Vérification des champs
            let formulaireValid = true
            if (this.departementsUtilisateur.length == 0) {
                formulaireValid = false
                this.$notify({
                    group: 'general-notification',
                    title: 'Pas de département sélectionné',
                    text: "Merci d'autoriser au moins un département",
                    type: 'warn',
                    duration: 5000,
                })
            }
            let checkHeuresOuvrees = checkInput(
                this.$refs.supportheuresouvrees,
                isNotHtml(this.supportheuresouvrees)
            )
            let checkHeuresNonOuvrees = checkInput(
                this.$refs.supportheuresnonouvrees,
                isNotHtml(this.supportheuresnonouvrees)
            )
            let checkNomOperateur = checkInput(
                this.$refs.nomoperateur,
                notNullCheck(this.nomoperateur)
            )
            let checkEmailOperateur = checkInput(
                this.$refs.emailutilisateur,
                emailCheck(this.emailutilisateur)
            )
            let checkEmail = checkInput(
                this.$refs.contactemail,
                emailCheck(this.contactemail)
            )
            let checkTel = checkInput(
                this.$refs.contacttel,
                notNullCheck(this.contacttel)
            )
            let checkAdresse = checkInput(
                this.$refs.contactadresse,
                notNullCheck(this.contactadresse)
            )
            let checkArcep = checkInput(
                this.$refs.codearcep,
                notNullCheck(this.codearcep)
            )
            let checkSiret = checkInput(
                this.$refs.siret,
                notNullCheck(this.siret)
            )
            let checkTypeRessource = checkInput(
                this.$refs.typeressource,
                notNullCheck(this.typeressource)
            )
            let checkCommentaire = checkInput(
                this.$refs.commentaire,
                isNotHtml(this.commentaire)
            )
            // Modification de l'opérateur
            if (
                formulaireValid &&
                checkHeuresOuvrees &&
                checkHeuresNonOuvrees &&
                checkNomOperateur &&
                checkEmailOperateur &&
                checkEmail &&
                checkTel &&
                checkAdresse &&
                checkArcep &&
                checkSiret &&
                checkTypeRessource &&
                checkCommentaire
            ) {
                axios
                    .put(
                        '/operateur/' + this.idOperateur,
                        {
                            username: this.nomoperateur,
                            nomOperateur: this.nomoperateur,
                            email: this.emailutilisateur,
                            contact_email: this.contactemail,
                            contact_telephone: this.contacttel,
                            contact_adresse: this.contactadresse,
                            code_arcep: this.codearcep,
                            siret: this.siret,
                            type_ressource: this.typeressource,
                            commentaire: this.commentaire,
                            operateurPrincipal: this.operateurPrincipal,
                            enable: this.enable,
                            departementAcces: this.departementsUtilisateur,
                            support_heures_ouvrees: this.supportheuresouvrees,
                            support_heures_non_ouvrees:
                                this.supportheuresnonouvrees,
                            acquittementAuto: this.acquittementAuto,
                            telechargementAutoOk: this.telechargementAutoOk,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title:
                                    'Le compte de ' +
                                    this.nomoperateur +
                                    ' a bien été modifié',
                                type: 'success',
                                duration: 5000,
                            })
                            window.location.href = '/#/operateurs'
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de modifier un opérateur",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        annuler() {
            window.location.href = '/#/operateurs'
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ficheSwitchSpacer {
    padding: 15px 15px 40px 20px;
}

.formInputField {
    padding-bottom: 20px;
}

.bouton {
    width: auto;
    padding: 0 2rem;
}
</style>

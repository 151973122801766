import { render, staticRenderFns } from "./WidgetVideo.vue?vue&type=template&id=109bff62&scoped=true"
import script from "./WidgetVideo.vue?vue&type=script&lang=js"
export * from "./WidgetVideo.vue?vue&type=script&lang=js"
import style0 from "./WidgetVideo.vue?vue&type=style&index=0&id=109bff62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109bff62",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./Reference.vue?vue&type=template&id=7fe2fef1&scoped=true"
import script from "./Reference.vue?vue&type=script&lang=js"
export * from "./Reference.vue?vue&type=script&lang=js"
import style0 from "./Reference.vue?vue&type=style&index=0&id=7fe2fef1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe2fef1",
  null
  
)

export default component.exports
<template>
    <section class="section flex-column">
        <div class="inline-flex searchFlex padding-bottom-30-px">
            <SearchFieldOperateur
                class="searchField"
                background="#EEF0F6"
                width="315px" />
            <button
                class="
                    arrowLimit
                    inline-flex-center
                    no-select no-border
                    margin-left-20-px
                    pointer
                "
                :class="{ rotate180: true, opaque: this.isOpaqueLeft }"
                v-show="this.operateurTotal > this.limit"
                @click="page('previous')">
                <img src="../../../assets/img/tnaau/Icon_circle_button.svg" />
            </button>
            <div
                class="font-marianne-thin inline-flex-center"
                v-show="this.operateurTotal > this.limit">
                {{ this.numeroPage }}/{{ this.nbrPageTotal }}
            </div>
            <button
                class="
                    arrowLimit
                    inline-flex-center
                    no-select no-border
                    pointer
                "
                :class="{ rotate180: false, opaque: this.isOpaqueRight }"
                v-show="this.operateurTotal > this.limit"
                @click="page('next')">
                <img src="../../../assets/img/tnaau/Icon_circle_button.svg" />
            </button>
        </div>
        <table>
            <thead>
                <tr>
                    <td
                        class="uppercase pointer colonneWidth"
                        @click="sort('nom')">
                        Nom
                        <img
                            src="../../../assets/img/tnaau/Icon_up_blue.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowNom === 'desc' }"
                            v-if="arrowNom != 'none'" />
                    </td>
                    <td
                        class="uppercase pointer colonneWidth"
                        @click="sort('bloquer')">
                        Compte bloqué ?
                        <img
                            src="../../../assets/img/tnaau/Icon_up_blue.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowBloquer === 'desc' }"
                            v-if="arrowBloquer != 'none'" />
                    </td>
                </tr>
            </thead>
            <tbody class="shadowBlue cornerLeft cornerRight">
                <tr
                    v-for="(item, index) in this.operateurList"
                    :key="item.idUtilisateur">
                    <td
                        class="colonneWidth pointer"
                        :class="{
                            cornerLeft: index === 0,
                        }"
                        @click="goto(item.idOperateur)">
                        {{ item.nom }}
                    </td>
                    <td class="colonneWidth pointer">
                        <span v-if="item.account_blocked" style="color: red"
                            >OUI</span
                        >
                        <span v-else style="color: #8ebf42">NON</span>
                    </td>
                    <td
                        class="
                            columnWidth
                            texte_rouge_fonce
                            colonneWidth
                            pointer
                        "
                        :class="{
                            cornerRight: index === 0,
                        }"
                        @click="goto(item.idOperateur)">
                        Cliquer pour voir la fiche
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>
<script>
import axios from 'axios'
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import SearchFieldOperateur from '@/components/widgets/searchFieldOperateur/SearchFieldOperateur'
import { notificationLogout } from '../../../tools/authent'
export default {
    name: 'Operateurs',
    components: { SearchFieldOperateur },
    data() {
        return {
            operateurList: [],
            arrowNom: 'asc',
            arrowMailContact: 'none',
            arrowSiret: 'none',
            arrowTel: 'none',
            arrowBloquer: 'none',
            offset: 0,
            limit: 10,
            operateurTotal: 1,
            isOpaqueRight: false,
            isOpaqueLeft: false,
            nbrPageTotal: 0,
            numeroPage: 0,
        }
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_TABLE_OPERATEUR, (obj) => {
            this.isOpaqueRight = false
            this.isOpaqueLeft = false
            this.offset = obj.offset
            this.limit = obj.limit

            if (this.offset === 0) {
                this.isOpaqueLeft = true
            } else if (this.offset + this.limit >= this.operateurTotal) {
                this.isOpaqueRight = true
            }

            axios
                .get(
                    '/operateur?offset=' +
                        obj.offset +
                        '&limit=' +
                        obj.limit +
                        '&filter=' +
                        obj.search +
                        '&sort=' +
                        obj.sort +
                        '&sortorder=' +
                        obj.sortorder,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + obj.tokken,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            this.operateurList = response.data.data
                            this.operateurTotal = response.data.total
                            this.nbrPageTotal = Math.ceil(
                                this.operateurTotal / this.limit
                            )
                            this.numeroPage = this.offset / this.limit + 1
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas les droits nécessaires pour voir les différents opérateurs.",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        })
    },
    methods: {
        goto(id) {
            window.location.href = '#/operateurdetail?id=' + id
        },
        page(value) {
            if (value === 'next') {
                if (!this.isOpaqueRight) {
                    this.offset += 10
                }
            } else if (value === 'previous') {
                if (this.offset >= 10) {
                    this.offset -= 10
                }
            }
            EventBus.$emit(eventTypes.LOADED_TABLE_OPERATEUR, {
                offset: this.offset,
                limit: this.limit,
                search: this.$store.state.search,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
                tokken: this.$store.state.user.token,
            })
        },
        sort(value) {
            switch (value) {
                case 'nom':
                    if (this.arrowNom === 'asc') {
                        this.arrowNom = 'desc'
                    } else {
                        this.arrowNom = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowNom
                    this.arrowMailContact = 'none'
                    this.arrowSiret = 'none'
                    this.arrowTel = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'email_operateur':
                    if (this.arrowMailContact === 'asc') {
                        this.arrowMailContact = 'desc'
                    } else {
                        this.arrowMailContact = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowMailContact
                    this.arrowNom = 'none'
                    this.arrowSiret = 'none'
                    this.arrowTel = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'siret':
                    if (this.arrowSiret === 'asc') {
                        this.arrowSiret = 'desc'
                    } else {
                        this.arrowSiret = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowSiret
                    this.arrowNom = 'none'
                    this.arrowMailContact = 'none'
                    this.arrowTel = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'telephone':
                    if (this.arrowTel === 'asc') {
                        this.arrowTel = 'desc'
                    } else {
                        this.arrowTel = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowTel
                    this.arrowNom = 'none'
                    this.arrowMailContact = 'none'
                    this.arrowSiret = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'bloquer':
                    if (this.arrowBloquer === 'asc') {
                        this.arrowBloquer = 'desc'
                    } else {
                        this.arrowBloquer = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowBloquer
                    this.arrowNom = 'none'
                    this.arrowMailContact = 'none'
                    this.arrowSiret = 'none'
                    this.arrowTel = 'none'
                    break
            }
            this.$store.state.sort = value
            this.$store.commit('saveStore')
            EventBus.$emit(eventTypes.LOADED_TABLE_OPERATEUR, {
                offset: 0,
                limit: 10,
                search: this.$store.state.search,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
                tokken: this.$store.state.user.token,
            })
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_TABLE_OPERATEUR)
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colonneWidth {
    width: 230px;
}

.colonneMail {
    align-items: flex-start;
    width: 400px;
}
</style>

<template>
    <section>
        <div class="backgroundModal" v-show="this.modal">
            <div class="windowModal">
                <div class="headerModal">
                    <span>{{ this.titre }}</span>
                    <div
                        class="inline-flex pointer margin-left-auto"
                        @click="fermer()">
                        <img
                            :src="
                                require(`../../../assets/img/tnaau/Icon_x.svg`)
                            " />
                    </div>
                </div>
                <div class="contentModal">
                    <slot />
                </div>
                <div class="footerModal">
                    <div class="annulerModal pointer" @click="fermer()">
                        Annuler
                    </div>
                    <ButtonWidget
                        :text="this.boutonText"
                        :small-button="true"
                        bgcolor="#E1000F"
                        logo="Icon_cadenas_unlocked_blanc.svg"
                        @click.native="valider()"
                        :enable="true"
                        :width="200" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'

export default {
    name: 'WidgetModal',
    components: { ButtonWidget },
    data() {
        return {
            modal: false,
        }
    },
    props: {
        titre: {
            type: String,
            default: 'titre',
        },
        icon: {
            type: String,
            default: 'Icon_cadenas.svg',
        },
        modalId: {
            type: String,
            default: 'defaultId',
        },
        listes: {
            type: Array,
            default: () => [],
        },
        boutonText: {
            type: String,
            default: "Autoriser l'accès",
        },
    },
    mounted() {
        EventBus.$on(eventTypes.MODAL_VISIBLE, (args) => {
            if (args.modalId === this.modalId) {
                this.modal = args.opened
            }
        })
    },
    methods: {
        valider() {
            this.modal = false
            EventBus.$emit(eventTypes.BTN_ENABLE, {})
        },
        fermer() {
            this.modal = false
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.MODAL_VISIBLE)
    },
}
</script>

<style scoped>
.windowModal {
    min-height: 520px;
    min-width: 410px;
}
</style>

<template>
    <section>
        <form class="container" @submit="envoieMessage">
            <TitreReference
                :reference="'contacter'"
                :texte="'le CCED'"
                class="padding-bottom-40-px" />

            <div
                class="
                    flex
                    font-marianne-light
                    bold
                    padding-top-10-px padding-bottom-10-px
                ">
                <span class="message-label">Objet</span>
                <help-widget
                    :titre="'Format de l objet '"
                    :texte="'Doit contenir entre 10 et 200 caractères'" />
            </div>
            <input
                id="objet"
                class="inputForm"
                type="text"
                style="width: 550px"
                v-model="objet" />

            <div
                class="
                    flex
                    font-marianne-light
                    bold
                    padding-top-10-px padding-bottom-10-px
                ">
                <span class="message-label">Message</span>
                <help-widget
                    :titre="'Format du message'"
                    :texte="'Doit contenir entre 50 et 4000 caractères'" />
            </div>
            <textarea
                id="message"
                class="textAreaForm margin-bottom-20-px"
                placeholder="Entrez votre message ici"
                v-model="message"
                ref="message"
                cols="30"
                rows="10" />
            <input type="hidden" v-model="fakeField" />
            <button class="bouton background_bleu_fonce texte_blanc no-border">
                <img
                    src="../../assets/img/tnaau/Icon_publier.svg"
                    class="margin-right-10-px" />
                Envoyer
            </button>
        </form>
    </section>
</template>

<script>
import axios from 'axios'
import TitreReference from '../widgets/titreReference/TitreReference.vue'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import { notificationLogout } from '../../tools/authent'

export default {
    name: 'ContactCced',
    components: { TitreReference, HelpWidget },
    data() {
        return {
            objet: '',
            message: '',
            fakeField: '',
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        envoieMessage() {
            let formulaireValid = true

            if (this.objet.length < 10 || this.objet.length > 200) {
                formulaireValid = false
                this.$notify({
                    group: 'general-notification',
                    title: 'Format de l objet incorrect',
                    text: 'L objet doit contenir entre 10 et 200 caractères.',
                    type: 'error',
                    duration: 5000,
                })
                return
            }

            var refMessage = this.$refs.message
            if (this.message.length < 50 || this.message.length > 4000) {
                formulaireValid = false
                refMessage.classList.add('input_red')
                this.$notify({
                    group: 'general-notification',
                    title: 'Format du message incorrect',
                    text: 'Le message doit contenir entre 50 et 4000 caractères.',
                    type: 'error',
                    duration: 5000,
                })
            } else {
                refMessage.classList.remove('input_red')
            }

            if (formulaireValid) {
                if (
                    this.fakeField === '' &&
                    this.$route.name === 'ContactCced'
                ) {
                    axios
                        .post(
                            '/messageCced',
                            {
                                objet: this.objet,
                                contenu: this.message,
                                raison: this.fakeField,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        this.$store.state.user.token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                if (response.data != null) {
                                    this.$notify({
                                        group: 'general-notification',
                                        title: 'Message envoyé avec succès',
                                        text: '',
                                        type: 'success',
                                        duration: 5000,
                                    })
                                    this.goto('/#/')
                                }
                            }
                        })
                        .catch((e) => {
                            if (
                                e.response.data.message.includes(
                                    'Un des paramètres suivants est null'
                                )
                            ) {
                                this.$notify({
                                    group: 'general-notification',
                                    title: 'Erreur',
                                    text: "Erreur lors de l'envoi du message",
                                    type: 'error',
                                    duration: 5000,
                                })
                            } else {
                                if (e.response.status === 403) {
                                    this.$notify({
                                        group: 'general-notification',
                                        title: 'Interdiction',
                                        text: "Vous n'avez pas l'autorisation d'envoyer ce message.",
                                        type: 'error',
                                        duration: 5000,
                                    })
                                }
                                notificationLogout(this, e)
                            }
                        })
                } else {
                    this.goto('/#/')
                }
            }
        },
    },
}
</script>
<style scoped>
.message-textarea {
    width: 618px;
    height: 143px;
    resize: none;
    margin-bottom: 30px;
}
.message-label {
    margin-right: 5px;
}
</style>

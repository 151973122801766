import * as types from './mutation-types'

export const menuToggle = ({ commit }) => {
    commit(types.TOGGLE_MENU)
}

export const footerToggle = ({ commit }) => {
    commit(types.TOGGLE_FOOTER)
}

export const sidebarToggle = ({ commit }, open) => {
    commit(types.TOGGLE_SIDEBAR, open)
}

export const switchIgnLayer = ({ commit }, layer) => {
    commit(types.IGN_LAYER_SET_CURRENT, layer)
}

export const setQuestionnaire = ({ commit }, details) => {
    commit(types.QUESTIONNAIRE_SETDETAILS, details)
}

export const unsetQuestionnaire = ({ commit }) => {
    commit(types.QUESTIONNAIRE_UNSET)
}

<template>
    <section>
        <div class="searchFields">
            <form @submit.prevent>
                <autocomplete
                    class="inputSearch"
                    :input-attrs="{
                        autofocus: true,
                        placeholder: 'Recherche',
                    }"
                    @change="search" />
            </form>
            <help-widget
                class="help-margin"
                :texte="'Vous pouvez effectuer une recherche sur le nom de la commune, le code Insee, le numéro court ou long et identifiant CAAU'" />
        </div>
    </section>
</template>
<script>
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import { debounce } from '@/tools/inputCheck'

export default {
    name: 'SearchFieldPdaa',
    components: { Autocomplete, HelpWidget },
    data() {
        return {
            searchText: '',
            searchDebounce: debounce(() => this.triggerSearch(), 500),
        }
    },
    methods: {
        search(text) {
            this.searchText = text
            this.searchDebounce()
        },
        triggerSearch() {
            this.$store.state.search = this.searchText
            this.$store.commit('saveStore')

            EventBus.$emit(eventTypes.LOADED_TABLE_PDAA, {
                offset: 0,
                limit: 10,
                search: this.searchText,
            })
        },
    },
}
</script>
.
<style scoped>
.help-margin {
    display: flex;
}
.searchFields {
    display: flex;
    width: 220px;
}
</style>

<template>
    <div class="container overflow_scroll" ref="innerContainer">
        <div class="subContainer">
            <TitreReference
                :reference="'CAAU'"
                :texte="this.caauInfo.identifiant_caau" />
            <div class="inline-flex flex-right padding-top-35-px">
                <ButtonWidget
                    :small-button="true"
                    bgcolor="#E1000F"
                    txtcolor="#fff"
                    logo="Icon_telechargement.svg"
                    text="Fiche CAAU"
                    @click.native="ficheCaau()"
                    :enable="true"
                    v-show="
                        isAuthorizeModification && this.caauInfo.acces_caau
                    " />
                <ButtonWidget
                    :small-button="true"
                    bgcolor="transparent"
                    txtcolor="#000091"
                    logo="Icon_disable.svg"
                    :text="
                        (this.caauInfo.supprimable
                            ? 'Supprimer'
                            : 'Désactiver') + ' le centre'
                    "
                    @click.native="modalDesactiveCentre()"
                    :enable="true"
                    v-show="
                        isAuthorizeModification && this.caauInfo.acces_caau
                    " />
                <PopupInformation
                    :titre="'Veuillez contacter votre administrateur pour désactiver ce CAAU.'"
                    ref="popupDesactivation" />
            </div>
        </div>
        <FicheIdentiteCentre :idCaau="this.idCaau" ref="caauInfo" />
        <div class="subContainer margin-top-30-px">
            <div class="listeCommunesTools">
                <div class="titreListeCommunes uppercase">
                    Liste des Communes pour le CAAU
                </div>
                <ButtonWidget
                    :small-button="true"
                    :bgcolor="isAffecterCommune ? '#E1000F' : 'transparent'"
                    :txtcolor="isAffecterCommune ? '#fff' : '#E1000F'"
                    :logo="
                        isAffecterCommune
                            ? 'Icon_plus.svg'
                            : 'Icon_plus_rouge.svg'
                    "
                    text="Affecter des communes"
                    class="bouton--border--red"
                    @click.native="affecteCommune()"
                    :enable="true"
                    v-if="isAuthorizeAffectation" />
                <help-widget
                    v-if="isAuthorizeAffectation"
                    class="help-widget"
                    :texte="'Affecter des communes depuis un autre CAAU vers ce CAAU sur le même numéro court uniquement. A noter qu’il ne sera pas possible d’affecter des communes si ce CAAU contient déjà toutes les communes du département. '" />
                <ButtonWidget
                    :small-button="true"
                    :bgcolor="isTranfertCommune ? '#E1000F' : 'transparent'"
                    :txtcolor="isTranfertCommune ? '#fff' : '#E1000F'"
                    :logo="
                        isTranfertCommune
                            ? 'Icon_plus.svg'
                            : 'Icon_transfert.svg'
                    "
                    text="Transférer des communes"
                    class="bouton--border--red"
                    @click.native="tranfertCommune()"
                    :enable="true"
                    v-if="isAuthorizeAffectation" />
                <help-widget
                    v-if="isAuthorizeAffectation"
                    class="help-widget"
                    :titre="'Format des numéros de téléphone'"
                    :texte="'Transférer des communes depuis ce CAAU vers autre CAAU sur le même numéro court uniquement. A noter qu\'il ne sera pas possible de transférer des communes de ce CAAU s\'il n\'existe pas d\'autres CAAU sur le même numéro court.'" />
                <button
                    v-if="isAuthorizeAffectation"
                    class="bouton-help bouton no-border plain--green"
                    @click="openClosePopupAideAffectation">
                    <span>Aide</span>
                    <img
                        src="@/assets/img/tnaau/Icon_video-blanc.svg"
                        alt="Aide" />
                </button>
                <div
                    class="backgroundModal"
                    v-show="this.isAideAffectationOpen">
                    <div class="windowModal">
                        <div class="headerModal">
                            <div
                                class="close-button pointer"
                                @click="openClosePopupAideAffectation">
                                X
                            </div>
                        </div>
                        <video width="800" ref="videoAideAffectation" controls>
                            <source
                                :src="
                                    require(`@/assets/video/Video-3-Changer-affectation-commune.webm`)
                                "
                                type="video/webm" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="subContainer footer">
            <!-- Liste des communes affectées à un caau -->
            <div
                class="listeCommuneCommun listeCommuneCarto"
                v-show="!isAffecterCommune && !isTranfertCommune">
                <div class="listeContainer">
                    <div>
                        <p class="titreListeCommune">
                            Communes du département :
                        </p>
                        <div class="listeCommune1">
                            <div
                                class="listCommuneLine"
                                v-for="(item, index) in this
                                    .filteredListeCommunesOrigine"
                                :key="item.id"
                                :class="{
                                    listCommuneLineEven: index & (1 === 1),
                                }">
                                <div class="codePostalCommune">
                                    {{ item.codeInsee }}
                                </div>
                                <div class="nomCommune">{{ item.nom }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.filteredListeCommunesDifferent.length > 0">
                        <p class="titreListeCommune">
                            Communes d'autres départements :
                        </p>
                        <div class="listeCommune2">
                            <div
                                class="listCommuneLine"
                                v-for="(item, index) in this
                                    .filteredListeCommunesDifferent"
                                :key="item.id"
                                :class="{
                                    listCommuneLineEven: index & (1 === 1),
                                }">
                                <div class="codePostalCommune">
                                    {{ item.codeInsee }}
                                </div>
                                <div class="nomCommune">{{ item.nom }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <OlMapCommune
                    :idCaau="this.idCaau"
                    :caauInfo="this.caauInfo"
                    :type="'accueil'"
                    :dptDifferentsId="this.dptDifferentsId"
                    :dptConcernesId="this.dptConcernesId"
                    :dptDifferentsCode="this.dptDifferentsCode"
                    :dptConcernesCode="this.dptConcernesCode"
                    v-if="
                        this.caauInfo.id_num_urgence &&
                        !isAffecterCommune &&
                        !isTranfertCommune
                    " />
            </div>
            <!-- Affecter des communes à un Caau -->
            <div
                class="listeCommuneCommun listeCommuneTraitement"
                v-show="isAffecterCommune && !isTranfertCommune">
                <div class="titreCommune">
                    <div class="uppercase titreTraitement">
                        Affecter des communes à ce CAAU
                    </div>
                    <div class="textBlue" v-show="this.dateJusquAuAffecte">
                        Affectations actuelles valides jusqu'au
                        {{ this.dateJusquAuAffecte }}
                    </div>
                    <div class="textBlue pointer" @click="closeCommune()">
                        Fermer
                    </div>
                </div>
                <div class="traitementCartoCommune">
                    <div class="traitementCommune">
                        <div class="flex flex-column gap-05">
                            <label for="dateACompterAffecte"
                                >Nouvelles affectations à compter du</label
                            >
                            <input
                                id="dateACompterAffecte"
                                v-model="dateACompterAffecte"
                                type="date"
                                name="dateACompterAffecte"
                                ref="dateAffecte"
                                class="texte_noir"
                                @change="changeDate('affecter')" />
                        </div>
                        <div class="flex flex-column gap-05">
                            <label for="communeSelect" class="margin-top-10-px"
                                >Communes sélectionnées :</label
                            >
                            <div
                                class="listeCommuneAffecte texte_bleu_fonce"
                                v-show="this.pdauAffecte.length > 0">
                                <div
                                    class="listCommuneLine"
                                    v-for="(item, index) in this.pdauAffecte"
                                    :key="item.idCommune"
                                    :class="{
                                        listCommuneLineEven: index & (1 === 1),
                                    }">
                                    <div class="codePostalCommune">
                                        {{ item.codeInsee }}
                                    </div>
                                    <div class="nomCommune">{{ item.nom }}</div>
                                    <img
                                        src="../../assets/img/tnaau/Icon_suppression_bleu.svg"
                                        class="pointer"
                                        @click="suppressionCommune(index)" />
                                </div>
                            </div>
                            <div
                                class="sansCommune"
                                v-show="this.pdauAffecte.length === 0">
                                Aucune commune sélectionnée
                            </div>
                        </div>
                        <div
                            class="flex-1 flex flex-column gap-05 items-center">
                            <WidgetAccesCommune
                                ref="widgetAccesCommune"
                                class="seperate"
                                :communes="this.communes"
                                :search="true"
                                :isAllSelection="true"
                                :boutonModal="'Valider choix'" />
                            <div
                                class="
                                    texte_rouge
                                    font-marianne-light
                                    seperate
                                ">
                                Ou sélectionnez des communes en cliquant<br />sur
                                la carte
                            </div>
                            <ButtonWidget
                                :small-button="true"
                                bgcolor="#000091"
                                class="margin-top-auto"
                                logo="Icon_valider.svg"
                                text="Valider l'affectation"
                                @click.native="validerAffectation()"
                                :enable="true"
                                :width="210" />
                        </div>
                    </div>
                    <OlMapCommune
                        :idCaau="this.idCaau"
                        :caauInfo="this.caauInfo"
                        :pdauAffecte="this.pdauAffecte"
                        :type="'affecter'"
                        v-if="
                            this.caauInfo.id_num_urgence &&
                            isAffecterCommune &&
                            !isTranfertCommune
                        "
                        :dptDifferentsId="this.dptDifferentsId"
                        :dptConcernesId="this.dptConcernesId"
                        :dptDifferentsCode="this.dptDifferentsCode"
                        :dptConcernesCode="this.dptConcernesCode"
                        class="seperate" />
                </div>
            </div>
            <!-- Transférer des communes à un Caau -->
            <div
                class="listeCommuneCommun listeCommuneTraitement"
                v-show="!isAffecterCommune && isTranfertCommune">
                <div class="titreCommune">
                    <div class="uppercase titreTraitement">
                        Transférer des communes vers un autre CAAU
                    </div>
                    <div class="textBlue" v-show="this.dateJusquAuTransfert">
                        Affectations actuelles valides jusqu'au
                        {{ this.dateJusquAuTransfert }}
                    </div>
                    <div class="textBlue pointer" @click="closeCommune()">
                        Fermer
                    </div>
                </div>
                <div class="traitementCartoCommune">
                    <div class="traitementCommune">
                        <div class="flex flex-column gap-05">
                            <label for="centreAffecter"
                                >Affecter au centre</label
                            >
                            <select
                                v-model="centreAffecter"
                                class="selectForm"
                                ref="centreAffecte">
                                <option
                                    class="optionForm"
                                    v-for="option in this.listeCaauTransfere"
                                    :key="option.identifiantCaau"
                                    :value="option.identifiantCaau">
                                    {{ option.identifiantCaau }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-column gap-05">
                            <label for="dateACompterTransfert">
                                Nouvelles affectations à compter du
                            </label>
                            <input
                                id="dateACompterTransfert"
                                v-model="dateACompterTransfert"
                                type="date"
                                name="dateACompterTransfert"
                                ref="dateTransfert"
                                class="texte_noir"
                                @change="changeDate('transferer')" />
                        </div>
                        <div class="flex flex-column gap-05">
                            <label for="communeSelect" class="seperate">
                                Communes sélectionnées :
                            </label>
                            <autocomplete
                                class="inputSearch"
                                :input-attrs="{
                                    placeholder: 'Recherche des communes',
                                }"
                                @change="researchCommunes" />
                            <div class="listCommuneLine bold">
                                <input
                                    type="checkbox"
                                    id="checkboxAll"
                                    class="checkboxTransfo"
                                    @click="selectionAllCommune()" />
                                <div class="nomCommune">Tout sélectionner</div>
                            </div>
                            <div
                                class="listeCommuneAffecte texte_bleu_fonce"
                                v-show="this.communesFiltrer.length !== 0">
                                <div
                                    class="listCommuneLine"
                                    v-for="(item, index) in this
                                        .communesFiltrer"
                                    :key="item.idCommune"
                                    :class="{
                                        listCommuneLineEven: index & (1 === 1),
                                    }">
                                    <input
                                        type="checkbox"
                                        class="checkboxTransfo"
                                        :id="'deptNum' + item.idCommune"
                                        @click="selectCommune(item)"
                                        ref="chexboxCommune" />
                                    <div class="codePostalCommune">
                                        {{ item.codeInsee }}
                                    </div>
                                    <div class="nomCommune">{{ item.nom }}</div>
                                </div>
                            </div>
                            <div
                                class="sansCommune"
                                v-show="this.listCommunes.length === 0">
                                Aucune commune ne fait partie de ce caau
                            </div>
                        </div>

                        <div
                            class="flex-1 flex flex-column gap-05 items-center">
                            <div class="texte_rouge font-marianne-light">
                                Ou sélectionnez des communes en cliquant<br />sur
                                la carte
                            </div>

                            <ButtonWidget
                                :small-button="true"
                                bgcolor="#000091"
                                class="margin-top-auto"
                                logo="Icon_valider.svg"
                                text="Valider le transfert"
                                @click.native="validerTransfert()"
                                :enable="true"
                                :width="210" />
                        </div>
                    </div>
                    <OlMapCommune
                        :idCaau="this.idCaau"
                        :caauInfo="this.caauInfo"
                        ref="mapTransfert"
                        :pdauTransfert="this.pdauTransfert"
                        :type="'transferer'"
                        v-if="
                            this.caauInfo.id_num_urgence &&
                            !isAffecterCommune &&
                            isTranfertCommune
                        "
                        :dptDifferentsId="this.dptDifferentsId"
                        :dptConcernesId="this.dptConcernesId"
                        :dptDifferentsCode="this.dptDifferentsCode"
                        :dptConcernesCode="this.dptConcernesCode"
                        class="seperate" />
                </div>
            </div>
            <PopupConfirmation
                :titre="`Êtes-vous sûr de vouloir ${
                    this.caauInfo.supprimable ? 'supprimer' : 'désactiver'
                } le centre ?`"
                ref="popupDesactiverCentre" />
            <PopupInformation
                :titre="'Toutes les communes sont déjà affectées à ce CAAU.'"
                ref="popupAffecte" />
            <PopupInformation
                :titre="'Il n\'y a pas de CAAU vers lesquels transférer des communes.'"
                ref="popupTransfere" />
            <PopupInformation ref="popupAffecteAfterCreate"
                >Vous pouvez maintenant affecter des communes à ce nouveau CAAU
                de 2 manières :
                <ul>
                    <li>
                        Soit en cliquant sur les communes grises de la carte
                    </li>
                    <li>Soit en cliquant sur le bouton 'Afficher la liste'</li>
                </ul>
            </PopupInformation>
            <PopupInformation
                :titre="'Vos modifications ont bien été enregistrées.'"
                ref="popupCaau"
                >Vous pouvez les consulter dans l'onglet "Publication =>
                publier".<br />
                Les changements ne seront définitivement pris en compte qu'une
                fois le PDAAU publié.
            </PopupInformation>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as eventTypes from '../../bus/event-types.js'
import EventBus from '../../bus'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import OlMapCommune from '@/components/widgets/olMapCommune/OlMapCommune'
import FicheIdentiteCentre from '@/components/widgets/ficheIdentiteCentre/FicheIdentiteCentre'
import WidgetAccesCommune from '@/components/widgets/widgetAccesCommune/WidgetAccesCommune'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import { notificationLogout } from '../../tools/authent'
import { notNullCheck } from '../../tools/inputCheck'
import { checkInput } from '../../tools/inputCheck'
import moment from 'moment'
import * as Roles from '../../tools/Roles'
import PopupInformation from '@/components/widgets/popupInformation/PopupInformation'
import PopupConfirmation from '@/components/widgets/popupConfirmation/PopupConfirmation'
import download from 'downloadjs'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'

export default {
    name: 'CaauDetail',
    components: {
        ButtonWidget,
        TitreReference,
        FicheIdentiteCentre,
        WidgetAccesCommune,
        OlMapCommune,
        PopupInformation,
        PopupConfirmation,
        HelpWidget,
        Autocomplete,
    },
    data() {
        return {
            idCaau: '',
            dateJusquAuAffecte: '',
            dateACompterAffecte: '',
            dateJusquAuTransfert: '',
            dateACompterTransfert: '',
            centreAffecter: '',
            caauInfo: {},
            pdauAffecte: {},
            isAffecterCommune: false,
            isTranfertCommune: false,
            isAideAffectationOpen: false,
            listCommunes: [],
            pdauTransfert: {},
            listeCaauTransfere: [],
            communes: [],
            communesFiltrer: '',
            dptDifferentsId: [],
            dptConcernesId: [],
            dptDifferentsCode: [],
            dptConcernesCode: [],
            isDeactivation: false,
        }
    },
    created() {
        this.idCaau = this.$route.query.id
        // on choisi le département pour le CAAU
        this.$store.state.detailDptSelectId = this.$route.query.idDepartement
            ? this.$route.query.idDepartement
            : this.$store.state.dptSelect.idDepartement
        //Récupére les infos du CAAU
        this.recuperationInfoCaau()
    },
    mounted() {
        // Si l'utilisateur confirme la désactivation du centre
        EventBus.$on(eventTypes.MODAL_VALIDE_POPUP, (args) => {
            if (args && this.isDeactivation) this.desactiveCentre()
            this.isDeactivation = false
        })

        EventBus.$on(eventTypes.BTN_ANNULER, () => {
            //Récupére les infos du CAAU
            this.recuperationInfoCaau()
            //Récupére les communes du CAAU
            this.recuperationCommuneCaau()
        })
        // Permet de savoir si le département a été changé
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            window.location.href = '/#/caau'
        })

        this.$refs.widgetAccesCommune.idCaau = this.idCaau
        //Récupére les communes du CAAU
        this.recuperationCommuneCaau()
        this.recuperationpdauAffecte()
        this.recuperationpdauTransfert()
        this.recuperationListeCaauTransfert()
        this.recupereCommuneAffecte()

        this.extendHeightMenu()
        EventBus.$on(eventTypes.WINDOWS_RESIZE, this.extendHeightMenu)
        this.$refs.innerContainer.addEventListener('scroll', this.updateScroll)

        // Ajout de 16 jours pour respecter les délais
        if (!this.isAdmin) {
            document
                .getElementById('dateACompterAffecte')
                .setAttribute(
                    'min',
                    moment().add(16, 'days').toISOString().split('T')[0]
                )
            document
                .getElementById('dateACompterTransfert')
                .setAttribute(
                    'min',
                    moment().add(16, 'days').toISOString().split('T')[0]
                )
        }
        // Ajout de 16 jours pour respecter les délais
        this.dateACompterAffecte = moment().add(16, 'days').format('YYYY-MM-DD')
        //16 + 31 jours
        this.dateJusquAuAffecte = moment().add(47, 'days').format('DD/MM/YYYY')

        this.dateACompterTransfert = moment()
            .add(16, 'days')
            .format('YYYY-MM-DD')
        this.dateJusquAuTransfert = moment()
            .add(47, 'days')
            .format('DD/MM/YYYY')

        if (this.$route.query.newcaau !== undefined) {
            const container = this.$refs.innerContainer
            container.scrollTop = container.scrollHeight
            this.$refs.popupAffecteAfterCreate.modal = true
        }
    },
    beforeUpdate() {
        if (this.listCommunes.length > 0) {
            if (Object.keys(this.pdauTransfert).length > 0) {
                this.listCommunes.forEach((element) => {
                    if (
                        document.getElementById('deptNum' + element.idCommune)
                            .checked !== null
                    ) {
                        if (
                            this.pdauTransfert.some(
                                (o2) => element.idCommune === o2.idCommune
                            )
                        ) {
                            document.getElementById(
                                'deptNum' + element.idCommune
                            ).checked = true
                        } else {
                            document.getElementById(
                                'deptNum' + element.idCommune
                            ).checked = false
                        }
                    }
                })
            } else {
                this.listCommunes.forEach((element) => {
                    if (
                        document.getElementById(
                            'deptNum' + element.idCommune
                        ) !== null
                    ) {
                        document.getElementById(
                            'deptNum' + element.idCommune
                        ).checked = false
                    }
                })
            }
        }
    },
    methods: {
        openClosePopupAideAffectation() {
            this.$refs.videoAideAffectation.pause()
            this.$refs.videoAideAffectation.currentTime = 0
            this.isAideAffectationOpen = !this.isAideAffectationOpen
        },
        researchCommunes(texte) {
            // ne pas transférer les communes des autres départements
            // si france entière, on prend sans filtre, sinon on applique selon le filtre du dpt en cours
            if (this.$store.state.dptSelect.idDepartement === 0) {
                this.communesFiltrer = this.listCommunes.filter(
                    (str) =>
                        str.codeInsee
                            .toUpperCase()
                            .indexOf(texte.toUpperCase()) > -1 ||
                        str.nom.toUpperCase().indexOf(texte.toUpperCase()) > -1
                )
            } else {
                this.communesFiltrer = this.listCommunes
                    .filter(
                        (str) =>
                            str.idDepartement.toString() ===
                            this.$store.state.dptSelect.idDepartement.toString()
                    )
                    .filter(
                        (str) =>
                            str.codeInsee
                                .toUpperCase()
                                .indexOf(texte.toUpperCase()) > -1 ||
                            str.nom.toUpperCase().indexOf(texte.toUpperCase()) >
                                -1
                    )
            }
        },
        selectionAllCommune() {
            if (this.pdauTransfert.length === this.listCommunes.length) {
                this.pdauTransfert = []
            } else {
                this.pdauTransfert = this.listCommunes.map((item) => item)
            }
            this.$refs.mapTransfert.eventCommune(this.pdauTransfert)
        },
        //Renvoie la liste des communes sélectionnées pour le transfert
        selectionCommune(item) {
            let isPresent = false
            let indexCommunes = 0
            this.pdauTransfert.forEach((element) => {
                if (element.idCommune === item.idCommune) {
                    isPresent = true
                    indexCommunes = this.pdauTransfert.indexOf(element)
                }
            })

            if (isPresent) {
                this.pdauTransfert.splice(indexCommunes, 1)
            } else {
                this.pdauTransfert.push(item)
            }
        },
        selectCommune(item) {
            this.selectionCommune(item)
            this.$refs.mapTransfert.eventCommune(this.pdauTransfert)
        },
        changeDate(action) {
            if (action === 'affecter') {
                this.dateJusquAuAffecte = moment(this.dateACompterAffecte)
                    .add(31, 'days')
                    .format('DD/MM/YYYY')
            } else {
                this.dateJusquAuTransfert = moment(this.dateACompterTransfert)
                    .add(31, 'days')
                    .format('DD/MM/YYYY')
            }
        },
        validerAffectation() {
            let communeList = []
            this.pdauAffecte.forEach((pdau) => {
                communeList.push(pdau.idCommune)
            })
            //Contact adresse
            let inputDateAffecte = this.$refs['dateAffecte']
            let formulaireValid = checkInput(
                inputDateAffecte,
                notNullCheck(this.dateACompterAffecte)
            )

            if (formulaireValid) {
                this.$refs.popupCaau.modal = true
                axios
                    .put(
                        '/pdaau/affectation/' + this.idCaau,
                        {
                            date_ancien_jusqu_au: this.dateJusquAuAffecte,
                            date_nouveau_a_compter_du: moment(
                                this.dateACompterAffecte
                            ).format('DD/MM/YYYY'),
                            communeAcces: communeList,
                            caau_origine: this.idCaau,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title:
                                    'Le caau ' +
                                    this.caauInfo.identifiant_caau +
                                    ' a bien été modifié',
                                text: '',
                                type: 'success',
                                duration: 5000,
                            })
                            this.$refs.caauInfo.saveEnable = false
                            this.closeCommune()
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de modifier un caau",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        validerTransfert() {
            let communeList = []
            this.pdauTransfert.forEach((pdau) => {
                communeList.push(pdau.idCommune)
            })

            let inputDateTransfert = this.$refs.dateTransfert
            let formulaireValidDateTransfert = checkInput(
                inputDateTransfert,
                notNullCheck(this.dateACompterTransfert)
            )

            let inputCentre = this.$refs.centreAffecte
            let formulaireValidCentre = checkInput(
                inputCentre,
                notNullCheck(this.centreAffecter)
            )

            if (formulaireValidDateTransfert && formulaireValidCentre) {
                this.$refs.popupCaau.modal = true
                //met la date sous la forme dd/mm/yyyy au lieu de yyyy-mm-dd
                axios
                    .put(
                        '/pdaau/transfert/' + this.centreAffecter,
                        {
                            date_ancien_jusqu_au: this.dateJusquAuTransfert,
                            date_nouveau_a_compter_du: moment(
                                this.dateACompterTransfert
                            ).format('DD/MM/YYYY'),
                            communeAcces: communeList,
                            caau_origine: this.caauInfo.identifiant_caau,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title:
                                    'Demande de transfert enregistrée pour le caau ' +
                                    this.caauInfo.identifiant_caau,
                                text: '',
                                type: 'success',
                            })
                            this.$refs.caauInfo.saveEnable = false
                            this.closeCommune()
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de modifier un caau",
                                type: 'error',
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        suppressionCommune(index) {
            this.pdauAffecte.splice(index, 1)
            this.$refs.widgetAccesCommune.checkedCommunes = this.pdauAffecte
            EventBus.$emit(eventTypes.AFFECTE_COMMUNE, this.pdauAffecte)
        },
        updateScroll() {
            EventBus.$emit(eventTypes.MAIN_SCROLL_AREA, {
                y: this.$refs.innerContainer.scrollTop,
            })
        },
        modalDesactiveCentre() {
            this.isDeactivation = true
            this.$refs.popupDesactiverCentre.modal = true
        },
        desactiveCentre() {
            if (
                this.$store.state.user.profil === Roles.PREFECTURE_MODIFICATION
            ) {
                this.$refs.popupDesactivation.modal = true
            } else if (this.$store.state.user.profil === Roles.ADMINISTRATEUR) {
                axios
                    .get('/caau/desactiver/' + this.idCaau, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            let msg = ''
                            let typeMsg = ''
                            if (response.data.message === 'delete') {
                                msg =
                                    'Le caau ' +
                                    this.caauInfo.identifiant_caau +
                                    ' a bien été supprimé'
                                typeMsg = 'success'
                                window.location.href = '#/caau'
                            } else if (
                                response.data.message === 'desactivate'
                            ) {
                                msg =
                                    'Le caau ' +
                                    this.caauInfo.identifiant_caau +
                                    ' a bien été désactivé'
                                typeMsg = 'success'
                            } else {
                                msg =
                                    'Le caau ' +
                                    this.caauInfo.identifiant_caau +
                                    ' ne peut pas être désactivé car des communes lui sont encore affectées'
                                typeMsg = 'warn'
                            }
                            this.$notify({
                                group: 'general-notification',
                                text: msg,
                                type: typeMsg,
                                duration: 5000,
                            })
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        notificationLogout(this, e)
                    })
            }
        },
        affecteCommune() {
            this.isAffecterCommune = true
            this.isTranfertCommune = false
            if (this.communes.length === 0) {
                this.$refs.popupAffecte.modal = true
                this.closeCommune()
            }
        },
        tranfertCommune() {
            this.isAffecterCommune = false
            this.isTranfertCommune = true
            if (
                this.listeCaauTransfere.length === 0 ||
                this.listCommunes.length === 0
            ) {
                this.$refs.popupTransfere.modal = true
                this.closeCommune()
            }
        },
        closeCommune() {
            this.isAffecterCommune = false
            this.isTranfertCommune = false
        },
        recupereCommuneAffecte() {
            if (this.isAuthorizeAffectation) {
                //Récupère toutes les communes liées à un CAAU
                axios
                    .get(
                        '/commune/pdaa/' +
                            this.idCaau +
                            '?idDepartement=' +
                            this.$store.state.detailDptSelectId,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data) {
                            this.communes = response.data
                            if (this.$route.query.newcaau !== undefined) {
                                this.affecteCommune()
                            }
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        if (e.response.status === 404) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Inconnu',
                                text: "Aucune commune n'a été trouvée",
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        recuperationListeCaauTransfert() {
            if (this.isAuthorizeAffectation) {
                axios
                    .get(
                        '/caau/list_transfert/' +
                            this.idCaau +
                            '?idDepartement=' +
                            this.$store.state.detailDptSelectId,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.listeCaauTransfere = response.data
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 404) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Non trouvée',
                                text: "Le CAAU n'a pas été trouvé.",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        //Récupére les communes du CAAU
        recuperationCommuneCaau() {
            axios
                .get(
                    '/commune/caau/' +
                        this.idCaau +
                        '?idDepartement=' +
                        this.$store.state.detailDptSelectId,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        // on prend comme origine le département sélectionné, si c'est la france entière on prend le département du caau
                        var dptConcernes = response.data
                            .map((commune) => commune.idDepartement)
                            .filter(
                                (dpt) =>
                                    dpt.toString() ===
                                    this.$store.state.detailDptSelectId.toString()
                            )
                        var dptDifferents = response.data
                            .map((commune) => commune.idDepartement)
                            .filter(
                                (dpt) =>
                                    dpt.toString() !==
                                    this.$store.state.detailDptSelectId.toString()
                            )
                        var dptConcernesCode = response.data
                            .filter(
                                (dpt) =>
                                    dpt.idDepartement.toString() ===
                                    this.$store.state.detailDptSelectId.toString()
                            )
                            .map((commune) => commune.codeInsee.substring(0, 2))
                        var dptDifferentsCode = response.data
                            .filter(
                                (dpt) =>
                                    dpt.idDepartement.toString() !==
                                    this.$store.state.detailDptSelectId.toString()
                            )
                            .map((commune) => commune.codeInsee.substring(0, 2))
                        // il faut ajouter le département actuel et son code insee (car on y a pas accès après)
                        // et les départements voisins au Caau concernés par le même centre, sans doublon
                        this.dptDifferentsId = Array.from(
                            new Set(dptDifferents)
                        )
                        this.dptConcernesId = Array.from(new Set(dptConcernes))
                        this.dptDifferentsCode = Array.from(
                            new Set(dptDifferentsCode)
                        )
                        this.dptConcernesCode = Array.from(
                            new Set(dptConcernesCode)
                        )
                        this.listCommunes = response.data
                        this.researchCommunes('')
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Non trouvée',
                            text: "Le CAAU n'a pas été trouvé.",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        //Récupére les infos du CAAU
        recuperationInfoCaau() {
            axios
                .get('/caau/info/' + this.idCaau, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.caauInfo = response.data
                        this.caauInfo.date_notification = moment(
                            this.caauInfo.date_notification
                        ).format('DD/MM/YYYY')
                        this.$refs.caauInfo.centreEdit = this.caauInfo

                        //Mise à jour des accès à un département
                        this.$refs.caauInfo.$refs.widgetDemartement.checkedDepartement =
                            this.caauInfo.departement_access

                        this.getAllDepartement()

                        //Mise à jour des switch-button
                        this.$refs.caauInfo.$refs.isCentreNational.status =
                            this.caauInfo.est_centre_national
                        this.$refs.caauInfo.$refs.isConnexionPflau.status =
                            this.caauInfo.est_connexion_pflau

                        //Mise à jours des numéros longs
                        if (
                            null !== this.caauInfo.mea_numero_long_nouveau &&
                            this.caauInfo.mea_numero_long_nouveau
                        ) {
                            this.$refs.caauInfo.$refs.numLong.numLongActuel =
                                this.caauInfo.mea_numero_long_ancien
                            this.$refs.caauInfo.$refs.numLong.jusquau =
                                this.caauInfo.mea_date_ancien_jusqu_au
                            this.$refs.caauInfo.$refs.numLong.numLongNouveau =
                                this.caauInfo.mea_numero_long_nouveau
                            this.$refs.caauInfo.$refs.numLong.acompter =
                                this.caauInfo.mea_date_nouveau_acompter_du
                            this.$refs.caauInfo.$refs.numLong.acces_caau =
                                this.caauInfo.acces_caau
                        } else {
                            if (
                                null !== this.caauInfo.numero_long_ancien &&
                                this.caauInfo.numero_long_ancien !== ''
                            ) {
                                this.$refs.caauInfo.$refs.numLong.numLongActuel =
                                    this.caauInfo.numero_long_ancien
                                this.$refs.caauInfo.$refs.numLong.jusquau =
                                    this.caauInfo.date_jusqu_au
                                this.$refs.caauInfo.$refs.numLong.numLongNouveau =
                                    this.caauInfo.numero_long_actuel
                                this.$refs.caauInfo.$refs.numLong.acompter =
                                    this.caauInfo.date_acompter_du
                                this.$refs.caauInfo.$refs.numLong.acces_caau =
                                    this.caauInfo.acces_caau
                            } else {
                                this.$refs.caauInfo.$refs.numLong.numLongActuel =
                                    this.caauInfo.numero_long_actuel
                                this.$refs.caauInfo.$refs.numLong.jusquau =
                                    this.caauInfo.date_jusqu_au
                                this.$refs.caauInfo.$refs.numLong.numLongNouveau =
                                    ''
                                this.$refs.caauInfo.$refs.numLong.acompter = ''
                                this.$refs.caauInfo.$refs.numLong.acces_caau =
                                    this.caauInfo.acces_caau
                            }
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'CAAU non trouvée',
                            text: "Le CAAU n'a pas été trouvé.",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        getAllDepartement() {
            //Récupère tout les départements
            axios
                .get('/departement', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        EventBus.$emit(
                            eventTypes.AFFECTE_DEPARTEMENT,
                            this.caauInfo.departement_access,
                            response.data.data
                        )
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Inconnu',
                            text: "Le compte recherché n'existe pas",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        recuperationpdauAffecte() {
            axios
                .get(
                    '/commune/pdaaenattente/nouveau/' +
                        this.idCaau +
                        '?idDepartement=' +
                        this.$store.state.detailDptSelectId,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.pdauAffecte = response.data
                        this.$refs.widgetAccesCommune.checkedCommunes =
                            this.pdauAffecte
                        if (response.data[0]) {
                            this.dateJusquAuAffecte =
                                response.data[0].date_ancien_jusqu_au
                            //met la date sous la forme yyyy-mm-dd au lieu de dd/mm/yyyy
                            var dateParts =
                                response.data[0].date_nouveau_a_compter_du.split(
                                    '/'
                                )
                            let d = new Date(
                                +dateParts[2],
                                dateParts[1] - 1,
                                +dateParts[0]
                            )
                            this.dateACompterAffecte =
                                moment(d).format('YYYY-MM-DD')
                        } else {
                            this.dateACompterAffecte = moment()
                                .add(16, 'days')
                                .format('YYYY-MM-DD')
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Non trouvée',
                            text: "il n'y pas de commune trouvée pour ce caau.",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        recuperationpdauTransfert() {
            axios
                .get('/commune/pdaaenattente/ancien/' + this.idCaau, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.pdauTransfert = response.data
                        if (this.pdauTransfert.length > 0) {
                            this.centreAffecter =
                                this.pdauTransfert[0].identifiantCaauNouveau
                            this.dateJusquAuTransfert =
                                this.pdauTransfert[0].date_ancien_jusqu_au
                            var dateAffecte =
                                this.pdauTransfert[0].date_nouveau_a_compter_du.split(
                                    '/'
                                )
                            let d = new Date(
                                +dateAffecte[2],
                                dateAffecte[1] - 1,
                                +dateAffecte[0]
                            )
                            this.dateACompterTransfert =
                                moment(d).format('YYYY-MM-DD')
                        } else {
                            this.dateACompterTransfert = moment()
                                .add(16, 'days')
                                .format('YYYY-MM-DD')
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'warn',
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Non trouvée',
                            text: "il n'y pas de commune trouvée pour ce caau.",
                            type: 'error',
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        ficheCaau() {
            axios
                .get(
                    '/fichecaau/' +
                        this.idCaau +
                        '/' +
                        this.$store.state.detailDptSelectId,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        var filenameBrut =
                            '' +
                            response.headers['content-disposition'].match(
                                /filename="(.*)"$/g
                            )
                        filenameBrut = filenameBrut.replace('filename=', '')
                        var filename = filenameBrut.split('"').join('')
                        download(
                            new Blob([response.data]),
                            filename,
                            'application/octet-stream'
                        )
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: 'Un problème est survenu lors de la récupération du rapport',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
        extendHeightMenu() {
            if (this.$refs.innerContainer)
                this.$refs.innerContainer.style.height =
                    window.innerHeight -
                    this.$refs.innerContainer.getBoundingClientRect().top +
                    'px'
        },
    },
    beforeDestroy() {
        this.$refs.innerContainer.removeEventListener(
            'scroll',
            this.updateScroll
        )
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isAuthorizeModification() {
            return (
                this.$store.state.dptSelect.idDepartement !== 0 &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    (this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION &&
                        this.caauInfo.acces_caau))
            )
        },
        isAuthorizeAffectation() {
            return (
                this.$store.state.dptSelect.idDepartement !== 0 &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION)
            )
        },
        isAdmin() {
            return this.$store.state.user.profil === Roles.ADMINISTRATEUR
        },
        isCaauInSameDpt() {
            return (
                this.$store.state.detailDptSelectId.toString() ===
                this.$store.state.dptSelect.idDepartement.toString()
            )
        },
        filteredListeCommunesDifferent() {
            // on vérifie si le département est la france entière,
            // si oui, le département du centre devient le département "en cours"
            const dpt =
                this.$store.state.dptSelect.idDepartement === 0
                    ? this.$store.state.detailDptSelectId
                    : this.$store.state.dptSelect.idDepartement

            if (this.$store.state.dptSelect.idDepartement !== 0) {
                // si le Caau est dans un département voisin, on n'affichera pas les communes voisines
                if (!this.isCaauInSameDpt) {
                    return []
                }
                return this.listCommunes.filter(
                    (commune) =>
                        commune.idDepartement.toString() !== dpt.toString()
                )
            }

            return this.listCommunes.filter(
                (commune) => commune.idDepartement.toString() !== dpt.toString()
            )
        },
        filteredListeCommunesOrigine() {
            const dpt =
                this.$store.state.dptSelect.idDepartement === 0
                    ? this.$store.state.detailDptSelectId
                    : this.$store.state.dptSelect.idDepartement
            return this.listCommunes.filter(
                (commune) => commune.idDepartement.toString() === dpt.toString()
            )
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.BTN_ANNULER)
        EventBus.$off(eventTypes.WINDOWS_RESIZE)
        EventBus.$off(eventTypes.DEPT_CHANGE)
        EventBus.$off(eventTypes.MODAL_VALIDE_POPUP)
        EventBus.$emit(eventTypes.RESET_SHADOW_STYLE, {})
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
    padding-top: 10px;
    height: 40px;
}

.checkboxTransfo {
    width: 15px;
}

.overflow_scroll {
    overflow-y: scroll;
    width: 100%;
}

.titreCommune {
    display: flex;
    justify-content: space-between;
}

.titreTraitement {
    font-family: 'Marianne Light';
    font-size: 16px;
    color: #e1000f;
}

.formInput {
    padding-top: 10px;
    font-family: 'Marianne Regular';
    font-size: 14px;
}

input {
    border-radius: 5px;
    border: none;
    max-width: 250px;
    padding: 0.5rem;
    background: #e9e8f8;
    font-family: 'Marianne Medium';
    font-size: 14px;
}

.sansCommune {
    width: 100%;
    display: flex;
    font-family: 'Marianne Light';
    align-items: center;
    padding: 0.5em 1rem;
    font-size: 14px;
    background: #eeeffa;
}

.traitementCartoCommune {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
}

.traitementCommune {
    flex: 1;
    max-width: 420px;
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
}

.communeCarto {
    display: inline-flex;
    width: 671px;
    height: 483px;
    background: #f7ca9c;
    margin-right: 10px;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background: url('../../assets/img/tnaau/Icon_calendar.svg') no-repeat;
}

.buttonBlue {
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
    padding-left: 20px;
    margin-top: 20px;
    border: none;
    height: 40px;
    width: 214px;
    background: #000091;
    font-family: 'Marianne Medium';
    font-size: 14px;
    color: #ffffff;
}

.buttonBlue:hover {
    opacity: 0.8;
}

.textBlue {
    align-items: center;
    font-family: 'Marianne Regular';
    font-size: 14px;
    color: #000091;
}

.titreListeCommunes {
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    font-family: 'Marianne Medium';
    font-size: 18px;
}

.listeCommunesTools {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    margin-bottom: 1rem;
}

.listeCommuneCommun {
    width: 100%;
    display: flex;
    gap: 1rem 3rem;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.listeCommuneCarto {
    display: flex;
    justify-content: space-between;
}

.listeCommuneTraitement {
    display: flex;
    flex-direction: column;
}

.listeCommune1 {
    flex: 1;
    display: block;
    flex-direction: column;
    max-width: 420px;
    height: 400px;
    overflow-y: scroll;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.listeCommune2 {
    flex: 1;
    display: block;
    flex-direction: column;
    max-width: 420px;
    height: 80px;
    overflow-y: scroll;
    border-bottom: 1px solid #ccc;
}

.titreListeCommunes {
    font-family: 'Marianne Regular';
    font-size: 18px;
    color: #000091;
    margin-top: 0;
}

.listeCommuneAffecte {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    max-height: 215px;
    overflow-y: scroll;
    padding-right: 20px;
}

.listCommuneLine {
    display: inline-flex;
    align-items: center;
    font-family: 'Marianne Light';
    font-size: 14px;
    min-height: 40px;
    height: 40px;
    width: 100%;
}

.listCommuneLine > img {
    width: 13px;
}

.listCommuneLineEven {
    background: #eeeffa;
}

.codePostalCommune {
    display: inline-flex;
    align-items: center;
    width: 80px;
    padding-left: 20px;
}

.nomCommune {
    display: inline-flex;
    align-items: center;
    width: 260px;
    padding-left: 20px;
}

.input_red {
    border: 1px solid #ff0000 !important;
}

.seperate {
    padding-top: 15px;
}

.footer {
    padding-bottom: 25px;
}

.selectForm {
    width: 190px;
}

.inputSearch {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

@media (max-width: 1280px) {
    .listeCommuneCarto,
    .traitementCartoCommune {
        flex-direction: column;
    }

    .listeCommune,
    .traitementCommune {
        max-width: 100%;
        flex: 0 0 auto;
        overflow: auto;
    }
}
</style>

<template>
    <section class="flex">
        <div
            class="switchGlobal pointer no-select"
            :style="getStyle"
            @click="click">
            <div class="switchCursor" :style="getStyleCursor" />
        </div>
        <div class="switchText">{{ this.texte }}</div>
    </section>
</template>

<script>
import EventBus from '../../../bus'
import * as eventTypes from '../../../bus/event-types.js'

export default {
    name: 'SwitchButton',
    data() {
        return {
            status: false,
        }
    },
    props: {
        color: {
            type: String,
            default: '',
        },
        texte: {
            type: String,
            default: '',
        },
        isNotBlock: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        click() {
            if (this.isNotBlock) {
                this.status = !this.status
                EventBus.$emit(eventTypes.BTN_ENABLE, {})
            }
        },
    },
    computed: {
        getStyle() {
            if (this.status) {
                return {
                    'background-color': this.color,
                }
            } else {
                return {
                    'background-color': '#B2B2C5',
                }
            }
        },
        getStyleCursor() {
            if (this.status) {
                return {
                    left: '18px',
                    'transition-property': 'left',
                    'transition-duration': '200ms',
                }
            } else {
                return {
                    left: '2px',
                    'transition-property': 'left',
                    'transition-duration': '200ms',
                }
            }
        },
    },
}
</script>

<style scoped>
.switchGlobal {
    display: inline-flex;
    width: 36px;
    height: 20px;
    border-radius: 10px;
}

.switchCursor {
    position: relative;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #e9e8f8;
    border: none;
}

.switchText {
    display: inline-flex;
    font-family: 'Marianne Medium';
    font-size: 14px;
    height: 20px;
    align-items: center;
    margin-left: 15px;
}
</style>

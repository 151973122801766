<template>
    <div class="helpWidget">
        <div
            ref="helpButton"
            class="rondHelp"
            @mouseover="mouseover"
            @mouseleave="mouseleave">
            ?
        </div>
        <div
            ref="popup"
            class="popupHelp"
            v-show="this.popupVisible"
            :style="getStylePosition">
            <div class="popupTitle uppercase">{{ this.titre }}</div>
            <span class="popupText" v-html="this.texte"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpWidget',
    data() {
        return {
            popupVisible: false,
            x: 0,
            y: 0,
        }
    },
    props: {
        titre: {
            type: String,
            default: '',
        },
        texte: {
            type: String,
            default: '',
        },
    },
    methods: {
        mouseover: function () {
            this.popupVisible = true

            // On attend le prochain tick pour avoir accès aux dimensions de la popup
            this.$nextTick(() => {
                const popupBox = this.$refs.popup.getBoundingClientRect()
                // Avec un offset de 32 pour ne pas être aux bords de l'écran
                const windowBox = {
                    height: window.innerHeight - 32,
                    width: window.innerWidth - 32,
                }
                let rect = this.$refs.helpButton.getBoundingClientRect()

                // On centre la popup sur le bouton d'aide
                popupBox.x = rect.x - popupBox.width / 2

                // Si on dépasse l'écran à droite on se décale sur la gauche
                if (rect.x + popupBox.width / 2 > windowBox.width)
                    popupBox.x -= rect.x + popupBox.width / 2 - windowBox.width

                this.x = popupBox.x + rect.height / 2
                this.y = rect.y + 32
            })
        },
        mouseleave: function () {
            this.popupVisible = false
        },
    },
    computed: {
        getStylePosition: function () {
            return {
                top: this.y + 'px',
                left: this.x + 'px',
            }
        },
    },
}
</script>

<style scoped>
.rondHelp {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Marianne Regular';
    font-size: 12px;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid #000091;
    border-radius: 10px;
    cursor: help;
}

.popupHelp {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 308px;
    max-height: 150px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 5px;
    z-index: 10;
}

.popupTitle {
    display: inline-flex;
    font-family: 'Marianne Medium';
    font-size: 14px;
    margin-bottom: 10px;
}

.popupTitle:empty {
    display: none;
}

.popupText {
    display: inline-flex;
    font-family: 'Marianne Light';
    text-align: justify;
    font-size: 14px;
}
</style>

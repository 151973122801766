<template>
    <div class="container overflow_scroll no-select" ref="innerContainer">
        <div class="subContainer gap-1">
            <TitrePage
                class="inline-flex"
                :texte="'Table nationale d\'acheminement des appels d\'urgence'" />
            <DepartementSelector
                :smallSelector="false"
                :isHome="true"
                class="inline-flex"
                :background="'#EEEFFA'" />
        </div>
        <HomeOce v-if="isOperateur" />
        <HomeMinistere v-if="!isOperateur" />
        <WidgetAide v-if="!isOperateur" />
    </div>
</template>

<script>
import HomeOce from '@/components/home/HomeOce'
import HomeMinistere from '@/components/home/HomeMinistere'
import EventBus from '../../bus'
import * as eventTypes from '../../bus/event-types.js'
import TitrePage from '@/components/widgets/titrePage/TitrePage'
import DepartementSelector from '@/components/widgets/departementSelector/DepartementSelector'
import roles from '../../tools/Roles'
import WidgetAide from '../widgets/WidgetAide/widgetAide.vue'

export default {
    name: 'Home',
    components: {
        HomeOce,
        HomeMinistere,
        TitrePage,
        DepartementSelector,
        WidgetAide,
    },

    data() {
        return {}
    },
    mounted() {
        this.extendHeightMenu()
        EventBus.$on(eventTypes.WINDOWS_RESIZE, this.extendHeightMenu)
        this.$refs.innerContainer.addEventListener('scroll', this.updateScroll)
    },
    methods: {
        updateScroll() {
            EventBus.$emit(eventTypes.MAIN_SCROLL_AREA, {
                y: this.$refs.innerContainer.scrollTop,
            })
        },
        extendHeightMenu() {
            if (this.$refs.innerContainer)
                this.$refs.innerContainer.style.height =
                    window.innerHeight -
                    this.$refs.innerContainer.getBoundingClientRect().top +
                    'px'
        },
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isOperateur() {
            return this.$store.state.user.profil === roles.OPERATEUR
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.WINDOWS_RESIZE)
        EventBus.$emit(eventTypes.RESET_SHADOW_STYLE, {})
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overflow_scroll {
    overflow-y: scroll;
    width: 100%;
}
</style>

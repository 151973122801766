<template>
    <div class="container">
        <TitreReference :reference="'Cartographie'" :texte="'des centres'" />
        <div>
            <div>
                <select
                    v-model="numUrgenceSelect"
                    class="select-item texte_blanc background_bleu_tres_fonce"
                    @change="onChangeSelectNumUrgence()">
                    <option
                        v-for="item in this.listeNumCourt"
                        :value="item.idNumeroUrgence"
                        :key="item.idNumeroUrgence">
                        {{ item.numeroCourt }}
                    </option>
                </select>
                <select
                    v-model="identifiantCaauSelect"
                    class="
                        selectCaau
                        select-item
                        texte_bleu_clair
                        background_page
                    "
                    @change="onChangeSelectCaau()">
                    <option :value="-1"></option>
                    <option
                        v-for="item in this.listeIdentifiantCaau"
                        :value="item.idCaau"
                        :key="item.idCaau">
                        {{ item.identifiantCaau }}
                    </option>
                </select>
            </div>
            <div class="listeCarto">
                <div class="listeCommune">
                    <div
                        class="listCommuneLine"
                        v-for="(item, index) in this.listCommunes"
                        :key="item.id"
                        :class="{ listCommuneLineEven: index & (1 === 1) }">
                        <div class="codePostalCommune">
                            {{ item.codeInsee }}
                        </div>
                        <div class="nomCommune">{{ item.nom }}</div>
                    </div>
                </div>
                <OlMapCentre
                    class="flex-1"
                    ref="mapCentre"
                    :idNumUrgenceTemp="numUrgenceSelect"
                    v-if="this.numUrgenceSelect !== 0" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import { notificationLogout } from '../../tools/authent'
import OlMapCentre from '@/components/widgets/olMapCentre/OlMapCentre'
import TitreReference from '@/components/widgets/titreReference/TitreReference'

export default {
    name: 'Centre',
    components: { TitreReference, OlMapCentre },
    data() {
        return {
            listeNumCourt: {},
            numUrgenceSelect: 0,
            listeIdentifiantCaau: {},
            identifiantCaauSelect: '',
            listCommunes: {},
        }
    },
    mounted() {
        //Sélection du département via DepartementSelector
        EventBus.$on(eventTypes.DEPT_CHANGE, () => {
            if (this.$store.state.dptSelect.idDepartement === 0) return

            this.identifiantCaauSelect = ''
            this.initNumUrgence(false)
        })
        this.initNumUrgence(true)
    },
    methods: {
        initNumUrgence(isInit) {
            axios
                .get(
                    '/numerourgence/' +
                        this.$store.state.dptSelect.idDepartement,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            this.listeNumCourt = response.data.data
                            if (this.listeNumCourt.length > 0) {
                                this.numUrgenceSelect =
                                    this.listeNumCourt[0].idNumeroUrgence
                                this.initIdentifiantCaau()
                                this.initListeCommunes()
                                if (!isInit) {
                                    this.$refs.mapCentre.ajoutCouches(
                                        this.numUrgenceSelect,
                                        ''
                                    )
                                    this.$refs.mapCentre.centerMap()
                                    this.$refs.mapCentre.informationCommune(
                                        this.numUrgenceSelect
                                    )
                                    if (this.$refs.mapCentre) {
                                        this.$refs.mapCentre.idNumUrgence =
                                            this.numUrgenceSelect
                                    }
                                }
                            }
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Attention',
                                text: "Un problème est survenu lors de la récupération des numéros d'urgences",
                                type: 'warn',
                            })
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: "Un problème est survenu lors de la récupération des numéros d'urgences",
                            type: 'warn',
                        })
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        notificationLogout(this, e)
                    }
                })
        },
        initIdentifiantCaau() {
            axios
                .get(
                    '/caau/cartographie/' +
                        this.$store.state.dptSelect.idDepartement +
                        '?idNumUrgence=' +
                        this.numUrgenceSelect,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.listeIdentifiantCaau = response.data
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Attention',
                            text: "Un problème est survenu lors de la récupération des numéros d'urgences",
                            type: 'warn',
                        })
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
        initListeCommunes() {
            let path = ''
            if (
                this.identifiantCaauSelect &&
                this.identifiantCaauSelect !== -1
            ) {
                path =
                    '/commune/carto/caau/' +
                    this.$store.state.dptSelect.idDepartement +
                    '?idNumUrgence=' +
                    this.numUrgenceSelect +
                    '&idCaau=' +
                    this.identifiantCaauSelect
            } else {
                path =
                    '/commune/carto/caau/' +
                    this.$store.state.dptSelect.idDepartement +
                    '?idNumUrgence=' +
                    this.numUrgenceSelect
            }
            axios
                .get(path, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.listCommunes = response.data
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: response.status,
                            text: response.statusText,
                            type: 'warn',
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Non trouvée',
                            text: "Le CAAU n'a pas été trouvé.",
                            type: 'error',
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        onChangeSelectNumUrgence() {
            this.identifiantCaauSelect = ''
            this.$refs.mapCentre.idNumUrgence = this.numUrgenceSelect
            this.initIdentifiantCaau()
            this.initListeCommunes()
            this.$refs.mapCentre.ajoutCouches(this.numUrgenceSelect, '')
            this.$refs.mapCentre.informationCommune(this.numUrgenceSelect)
        },
        onChangeSelectCaau() {
            this.initIdentifiantCaau()
            this.initListeCommunes()
            this.$refs.mapCentre.ajoutCouches(
                this.numUrgenceSelect,
                this.identifiantCaauSelect
            )
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.select-item {
    width: 104px;
    height: 40px;
    border-radius: 20px;
    padding: 3px 0 5px 10px;
    margin-top: 20px;

    border: 0px;
    outline: 0px;

    font-family: 'Marianne Light';
    font-size: 15px;
}

.selectCaau {
    width: 135px;
    border: 1px solid #000091;
    margin-left: 20px;
    margin-bottom: 50px;
}

.listeCommune {
    display: inline-flex;
    flex-direction: column;
    height: 553px;
    width: 320px;
    overflow-y: scroll;
}

.listCommuneLine {
    display: inline-flex;
    align-items: center;
    font-family: 'Marianne Light';
    font-size: 14px;
    min-height: 40px;
    height: 40px;
    width: 100%;
}

.listCommuneLineEven {
    background: #eeeffa;
}

.codePostalCommune {
    display: inline-flex;
    align-items: center;
    width: 80px;
    padding-left: 20px;
}

.nomCommune {
    display: inline-flex;
    align-items: center;
    width: 150px;
    padding-left: 20px;
}

.listeCarto {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

@media (max-width: 1280px) {
    .listeCommune {
        height: 40vh;
        width: 100%;
    }

    .listeCarto {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}
</style>

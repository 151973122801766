import * as types from './mutation-types'

export const mutations = {
    initialiseStore(state) {
        // Check if the ID exists
        if (sessionStorage.getItem('tnaau-storage')) {
            // Replace the state object with the stored item
            this.replaceState(
                Object.assign(
                    state,
                    JSON.parse(sessionStorage.getItem('tnaau-storage'))
                )
            )
        }
    },
    saveStore(state) {
        // appeler this.$store.commit("saveStore") pour sauver le state dans le sessionStorage //localStorage
        sessionStorage.setItem('tnaau-storage', JSON.stringify(state))
    },

    [types.TOGGLE_MENU](state) {
        state.menu.active = !state.menu.active
    },

    [types.TOGGLE_FOOTER](state) {
        state.footer.active = !state.footer.active
    },

    [types.TOGGLE_SIDEBAR](state, open) {
        if (typeof open === 'boolean') {
            // Open / close
            state.sidebar.active = open
        } else {
            // Toggle
            state.sidebar.active = !state.sidebar.active
        }
    },

    [types.IGN_LAYER_SET_CURRENT](state, layer) {
        state.ign = layer
    },

    [types.QUESTIONNAIRE_SETDETAILS](state, details) {
        state.questionnaire = details
    },

    [types.QUESTIONNAIRE_UNSET](state) {
        state.questionnaire = null
    },
}

<template>
    <div class="templateItem">
        <b>{{ item.nom }}</b>
    </div>
</template>

<script>
export default {
    props: {
        item: { required: true },
    },
}
</script>
<style scoped>
.templateItem {
    background: #dfe0ed;
    width: 240px;
    padding: 7px 0 7px 5px;
    font-family: 'Marianne Light';
    font-size: 14px;
}

.templateItem:hover {
    background: #cccfea;
    opacity: 1;
    color: #4143df;
    font-family: 'Marianne Light';
    font-size: 14px;
}
</style>

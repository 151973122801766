<template>
    <div>
        <div
            class="texte32 inline-flex-center uppercase padding-top-100-px"
            v-if="isAuthorizeModification">
            Je crée ou je modifie un CAAU
        </div>
        <div
            class="texte32 inline-flex-center uppercase padding-top-100-px"
            v-if="!isAuthorizeModification">
            Je consulte
        </div>
        <div class="flex-row-center flex-wrap padding-top-35-px gap-1">
            <ButtonCreateCenter
                :smallButton="false"
                v-if="isAuthorizeModification" />
            <SearchField :dataArray="caauList" :smallButton="false" class="" />
        </div>
        <div class="flex gap-1">
            <div class="flex-1"></div>
            <div class="flex-1">
                <a href="/#/caau" class="list_caau">
                    <img
                        src="../../assets/img/tnaau/Icon_liste_bleue.svg"
                        class="margin-right-10-px" />
                    <span>Rechercher un CAAU dans la liste</span>
                </a>
            </div>
        </div>
        <div class="texte32 inline-flex-center uppercase padding-top-35-px">
            Je consulte le PDAAU
        </div>
        <div class="flex-row-center padding-top-35-px">
            <ButtonWidget
                v-if="!isEntireCountry"
                :smallButton="false"
                :logo="'Icon_carto.svg'"
                :text="'Cartographie'"
                :bgcolor="'#1A1A9F'"
                :width="180"
                :url="'/#/centre'" />
            <ButtonWidget
                :smallButton="false"
                :logo="'Icon_tableau_accueil.svg'"
                :text="'Tableau'"
                :bgcolor="'#1A1A9F'"
                :width="170"
                :url="'/#/pdaa'"
                class="margin-left-30-px" />
        </div>
    </div>
</template>

<script>
import ButtonCreateCenter from '@/components/widgets/buttonCreateCenter/ButtonCreateCenter'
import SearchField from '@/components/widgets/searchField/SearchField'
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import axios from 'axios'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import { notificationLogout } from '../../tools/authent'
import * as Roles from '../../tools/Roles'

export default {
    name: 'HomeMinistere',
    components: {
        ButtonCreateCenter,
        SearchField,
        ButtonWidget,
    },
    data() {
        return {
            caauList: [],
            caauTotal: '',
        }
    },
    computed: {
        isEntireCountry() {
            return this.$store.state.dptSelect.idDepartement === 0
        },
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isAuthorizeModification() {
            return (
                this.$store.state.dptSelect.idDepartement !== 0 &&
                (this.$store.state.user.profil === Roles.ADMINISTRATEUR ||
                    this.$store.state.user.profil ===
                        Roles.PREFECTURE_MODIFICATION)
            )
        },
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_TABLE_CAAU, (obj) => {
            this.dept = obj.departement
            //Recherche liste CAAU par département
            if (obj.filtre.length >= 2) {
                axios
                    .get(
                        '/caau/?id=' +
                            this.dept +
                            '&filtre=' +
                            obj.filtre +
                            '&sort=' +
                            obj.sort +
                            '&sortorder=' +
                            obj.sortorder,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data) {
                            // On vide le tableau de résultats
                            this.caauList = response.data.data
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation d'accéder à ce département",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            } else {
                this.caauList = []
            }
        }),
            axios
                .get('/config/url', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.$store.state.urlApi = response.data.baseUrlApi
                        this.$store.commit('saveStore')
                    }
                })
                .then()
                .catch((e) => {
                    notificationLogout(this, e)
                })
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_TABLE_CAAU)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.texte32 {
    font-family: 'Marianne Medium';
    font-size: 32px;
    width: 100%;
}

.list_caau {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-right: auto;
    color: rgb(26, 26, 159);
    font-family: 'Marianne Medium';
}

.list_caau:hover {
    color: rgb(19, 19, 133);
}
</style>

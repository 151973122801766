<template>
    <section>
        <div class="searchFields">
            <form @submit.prevent>
                <autocomplete
                    class="inputSearch"
                    :input-attrs="{
                        autofocus: true,
                        placeholder: 'Nom, E-mail, Siret, Arcep',
                    }"
                    @change="search" />
            </form>
        </div>
    </section>
</template>
<script>
import * as eventTypes from '@/bus/event-types.js'
import EventBus from '@/bus'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'

export default {
    name: 'SearchFieldOperateur',
    components: { Autocomplete },
    data() {
        return {}
    },
    methods: {
        search(text) {
            this.$store.state.search = text
            this.$store.commit('saveStore')
            let searchFinal = ''
            if (text.length >= 3) {
                searchFinal = text
            } else {
                searchFinal = ''
            }
            if (text.length >= 3 || text.length === 0) {
                EventBus.$emit(eventTypes.LOADED_TABLE_OPERATEUR, {
                    offset: 0,
                    limit: 10,
                    search: searchFinal,
                    sort: this.$store.state.sort,
                    sortorder: this.$store.state.sortorder,
                    tokken: this.$store.state.user.token,
                })
            }
        },
    },
}
</script>
<style scoped></style>

import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/home/Home'
import CreationCompte from '@/components/pages/CreationCompte'
import Historique from '@/components/pages/Historique'
import Publier from '@/components/pages/Publier'
import Comptes from '@/components/pages/Comptes'
import CompteDetail from '@/components/pages/CompteDetail'
import CreationOperateur from '@/components/pages/CreationOperateur'
import Operateurs from '@/components/pages/Operateurs'
import PrefectureModif from '@/components/pages/PrefectureModif'
import OperateurDetail from '@/components/pages/OperateurDetail'
import Tableau from '@/components/pages/Tableau'
import Pdaa from '@/components/pages/Pdaa'
import Nac from '@/components/pages/Nac'
import Caau from '@/components/pages/Caau'
import Centre from '@/components/pages/Centre'
import CaauDetail from '@/components/pages/CaauDetail'
import CaauCreation from '@/components/pages/CaauCreation'
import ContactPrefecture from '@/components/pages/ContactPrefecture'
import ContactCced from '@/components/pages/ContactCced'
import store from '../store'
import roles from '../tools/Roles'
import LostPassword from '@/components/authent/LostPassword'
import Register from '@/components/authent/Register'
import Integration from '@/components/pages/Integration'
import Aide from '@/components/pages/Aide'
import { getLogin } from '@/tools/authent'
import AdministrationAide from '@/components/pages/AdministrationAide'
import AideOce from '@/components/pages/AideOce'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/creationcompte',
            name: 'CreationCompte',
            component: CreationCompte,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.PREFECTURE_MODIFICATION,
                        roles.DIRECTION_METIER,
                        roles.GESTIONNAIRE_OCE,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/historique',
            name: 'Historique',
            component: Historique,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé ou s'il a sélectionné la france entière
                if (
                    [roles.OPERATEUR, roles.DIRECTION_METIER].includes(
                        store.state.user.profil
                    ) ||
                    store.state.dptSelect.idDepartement === 0
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/publier',
            name: 'Publier',
            component: Publier,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé ou s'il a sélectionné la france entière
                if (
                    [roles.OPERATEUR, roles.DIRECTION_METIER].includes(
                        store.state.user.profil
                    ) ||
                    store.state.dptSelect.idDepartement === 0
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/comptes',
            name: 'Comptes',
            component: Comptes,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.PREFECTURE_MODIFICATION,
                        roles.DIRECTION_METIER,
                        roles.GESTIONNAIRE_OCE,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/administration-aide',
            name: 'AdministrationAide',
            component: AdministrationAide,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.PREFECTURE_MODIFICATION,
                        roles.DIRECTION_METIER,
                        roles.GESTIONNAIRE_OCE,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/comptedetail',
            name: 'CompteDetail',
            component: CompteDetail,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.PREFECTURE_MODIFICATION,
                        roles.DIRECTION_METIER,
                        roles.GESTIONNAIRE_OCE,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/operateurs',
            name: 'Operateurs',
            component: Operateurs,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/prefectureModif',
            name: 'PrefectureModif',
            component: PrefectureModif,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/operateurdetail',
            name: 'OperateurDetail',
            component: OperateurDetail,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/creationoperateur',
            name: 'CreationOperateur',
            component: CreationOperateur,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.PREFECTURE_MODIFICATION,
                        roles.DIRECTION_METIER,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/tableau',
            name: 'Tableau',
            component: Tableau,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/pdaa',
            name: 'Pdaa',
            component: Pdaa,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/centre',
            name: 'Centre',
            component: Centre,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé ou s'il a sélectionné la france entière
                if (
                    [roles.OPERATEUR].includes(store.state.user.profil) ||
                    store.state.dptSelect.idDepartement === 0
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/nac',
            name: 'Nac',
            component: Nac,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (
                    [roles.OPERATEUR].includes(store.state.user.profil) ||
                    store.state.dptSelect.idDepartement === 0
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/caau',
            name: 'Caau',
            component: Caau,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/caaudetail',
            name: 'CaauDetail',
            component: CaauDetail,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/caauCreation',
            name: 'CaauCreation',
            component: CaauCreation,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil si c'est un opérateur ou s'il a sélectionné la france entière
                if (
                    [
                        roles.OPERATEUR,
                        roles.PREFECTURE_CONSULTATION,
                        roles.DIRECTION_METIER,
                        roles.GESTIONNAIRE_OCE,
                    ].includes(store.state.user.profil) ||
                    store.state.dptSelect.idDepartement === 0
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/operateur/contactprefecture',
            name: 'ContactPrefecture',
            component: ContactPrefecture,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil si ce n'est pas un opérateur
                if (
                    [
                        roles.ADMINISTRATEUR,
                        roles.PREFECTURE_MODIFICATION,
                        roles.PREFECTURE_CONSULTATION,
                        roles.GESTIONNAIRE_OCE,
                        roles.DIRECTION_METIER,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/operateur/contactcced',
            name: 'ContactCced',
            component: ContactCced,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil si ce n'est pas un opérateur
                if (
                    [
                        roles.ADMINISTRATEUR,
                        roles.PREFECTURE_MODIFICATION,
                        roles.PREFECTURE_CONSULTATION,
                        roles.GESTIONNAIRE_OCE,
                        roles.DIRECTION_METIER,
                    ].includes(store.state.user.profil)
                ) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/operateur/aide',
            name: 'AideOce',
            component: AideOce,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if (![roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/aide',
            name: 'Aide',
            component: Aide,
            beforeEnter: (to, from, next) => {
                // Redirige l'utilisateur vers la page d'acceuil s'il n'est pas autorisé
                if ([roles.OPERATEUR].includes(store.state.user.profil)) {
                    window.location.href = '/'
                } else {
                    next()
                }
            },
        },
        {
            path: '/lostpassword',
            name: 'LostPassword',
            component: LostPassword,
        },
        {
            path: '/register/validation',
            name: 'Register',
            component: Register,
        },
        {
            path: '/integration',
            name: 'Integration',
            component: Integration,
        },
        {
            path: '/logged',
            name: 'HomeLogged',
            component: Home,
            beforeEnter: (to) => {
                // Redirige l'utilisateur vers la page d'acceuil si ce n'est pas un opérateur
                if (to.query.token) {
                    store.state.user.token = to.query.token
                    store.state.user.profil = to.query.profil
                    store.state.user.id = to.query.id
                    store.state.user.login = to.query.email
                    store.state.user.fullLogged = true
                    store.commit('saveStore')
                }
                window.location.href = '/'
            },
        },
        {
            path: '*',
            name: 'Home',
            component: Home,
        },
    ],
})

router.beforeResolve((to, from, next) => {
    const pathWithoutLogin = ['Register', 'Home']
    if (pathWithoutLogin.includes(to.name)) {
        next()
    }
    if (store.state.user.token) {
        getLogin(store.state.user.token).then((response) => {
            store.state.user.profil = response.profil
            store.state.user.id = response.idUtilisateur
            store.state.user.login = response.email
            store.commit('saveStore')
        })
        next()
    }
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import { mutations } from './mutations'
import * as actions from './actions'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    key: 'tnaau-storage', // The key to store the state on in the storage provider.
    storage: window.sessionStorage, //, // or window.sessionStorage or localForage
    // modules: ["todo", "user"], // store all variables in module in store
})

const state = {
    user: {
        id: '',
        login: '',
        token: '',
        profil: '',
        fullLogged: false,
        timedOut: false,
    },
    // Département sélectionné dans le sélecteur de départements
    dptSelect: [],
    // Département sélectionné pour un affichage détaillé
    detailDptSelectId: [],
    search: '',
    sortorder: '',
    sort: '',
    urlApi: '',
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    plugins: [vuexLocal.plugin],
})

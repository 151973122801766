var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container width-100-percent"},[_c('div',{staticClass:"flex justify-end items-center width-100-percent"},[_c('TitreReference',{attrs:{"reference":'Publier',"texte":'le PDAAU'}}),(_vm.isAuthorize)?_c('button',{staticClass:"bouton-help margin-left-auto bouton no-border plain--green",on:{"click":_vm.openClosePopupAidePublication}},[_c('span',[_vm._v("Aide")]),_c('img',{attrs:{"src":require("@/assets/img/tnaau/Icon_video-blanc.svg"),"alt":"Aide"}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.isAidePublicationOpen),expression:"this.isAidePublicationOpen"}],staticClass:"backgroundModal"},[_c('div',{staticClass:"windowModal"},[_c('div',{staticClass:"headerModal"},[_c('div',{staticClass:"close-button pointer",on:{"click":_vm.openClosePopupAidePublication}},[_vm._v(" X ")])]),_c('video',{ref:"videoAidePublication",attrs:{"width":"800","controls":""}},[_c('source',{attrs:{"src":require(`@/assets/video/Video-4-Publier-et-envoyer-un-PDAU.webm`),"type":"video/webm"}})])])])],1),_c('div',{staticClass:"flex justify-end gap-1 items-center width-100-percent"},[(
                _vm.isAuthorize &&
                this.nombreModificationsAttentesCAAU +
                    this.nombreModificationsAttentesPDAA >
                    0
            )?_c('button',{staticClass:"bouton largeBouton background_rouge texte_blanc no-border",on:{"click":_vm.confirmationPublierPdaau}},[_c('img',{staticClass:"margin-right-10-px",attrs:{"src":require("../../assets/img/tnaau/Icon_publier.svg")}}),_vm._v(" Publier le PDAAU en attente ")]):_vm._e(),(
                _vm.isAdmin &&
                this.nombreModificationsAttentesCAAU +
                    this.nombreModificationsAttentesPDAA ==
                    0
            )?_c('button',{staticClass:"bouton largeBouton background_rouge texte_blanc no-border",on:{"click":_vm.confirmationPublierPdaauSansModification}},[_c('img',{staticClass:"margin-right-10-px",attrs:{"src":require("../../assets/img/tnaau/Icon_publier.svg")}}),_vm._v(" Publier le PDAAU ")]):_vm._e()]),_c('div',{staticClass:"uppercase font-titre-tableau sous-titre"},[_vm._v(" Modification avant publication ")]),_c('div',{staticClass:"uppercase titre-tableau font-titre-tableau"},[_vm._v("CAAU")]),(this.listeCaau.length > 0)?_c('div',{staticClass:"flex justify-center no-select margin-bottom-50-px"},[_c('table',[_c('tr',[_c('td',{staticClass:"headerId"},[_vm._v("ID CAAU")]),_c('td',{staticClass:"headerdata cornerLeft"},[_vm._v("N° court")]),_c('td',{staticClass:"headerdata"},[_vm._v("Nouveau n° long")]),_c('td',{staticClass:"headerdata"},[_vm._v("À compter du")]),_c('td',{staticClass:"headerdata"},[_vm._v("Ancien numéro long")]),_c('td',{staticClass:"headerdata cornerRight"},[_vm._v("Jusqu'au")]),_c('td',[(
                            _vm.isAuthorize &&
                            this.nombreModificationsAttentesCAAU > 0
                        )?_c('span',{staticClass:"flex align-center gap-05 texte_rouge pointer font-marianne-light",on:{"click":function($event){return _vm.openPopupAnnulerAllModifsCaau()}}},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_annuler.svg")}}),_vm._v("Annuler les modifications")]):_vm._e()])]),_vm._l((this.listeCaau),function(item,index){return _c('tr',{key:item.id},[_c('td',{staticClass:"subHeader pointer",class:{
                        cornerLeft: index === 0,
                        shadowBlueTop: index === 0,
                        shadowBlueLeft: index !== 0,
                        line: item.type === 'suppression',
                    },on:{"click":function($event){return _vm.goto('/#/caaudetail?id=' + item.idCaau)}}},[_vm._v(" "+_vm._s(item.identifiantcaau)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.numeroCourt)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.numeroLongNouveau)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.dateNouveauAcompterDu)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.numeroLongAncien)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.dateAncienJusquAu)+" ")]),_c('td',[(_vm.isAuthorize && item.type === '')?_c('span',{staticClass:"flex align-center gap-05 texte_rouge pointer font-marianne-light",on:{"click":function($event){return _vm.openPopupAnnulerModifsCaau(
                                item.idCaau,
                                item.identifiantcaau
                            )}}},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_annuler.svg")}}),_vm._v(" Annuler ")]):_vm._e()])])})],2)]):_vm._e(),(this.listeCaau.length == 0)?_c('div',{staticClass:"font-titre-tableau text-align-center"},[_vm._v(" Aucune modification en attente ")]):_vm._e(),_c('div',{staticClass:"uppercase titre-tableau font-titre-tableau"},[_vm._v("PDAA")]),(this.listePdaau.length > 0)?_c('div',{staticClass:"flex justify-center font-titre-tableau text-align-center no-select"},[_c('table',[_c('tr',[_c('td',{staticClass:"headerId"},[_vm._v("Commune")]),_c('td',{staticClass:"headerdata cornerLeft"},[_vm._v("N° court")]),_c('td',{staticClass:"headerdata"},[_vm._v("Nouveau CAAU")]),_c('td',{staticClass:"headerdata"},[_vm._v("À compter du")]),_c('td',{staticClass:"headerdata"},[_vm._v("Ancien CAAU")]),_c('td',{staticClass:"headerdata cornerRight"},[_vm._v("Jusqu'au")]),_c('td',[(
                            _vm.isAuthorize &&
                            this.nombreModificationsAttentesPDAA > 0
                        )?_c('span',{staticClass:"flex align-center gap-05 texte_rouge pointer font-marianne-light",on:{"click":function($event){return _vm.openPopupAnnulerAllModifsPdaa()}}},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_annuler.svg")}}),_vm._v("Annuler les modifications")]):_vm._e()])]),_vm._l((this.listePdaau),function(item,index){return _c('tr',{key:item.id},[_c('td',{staticClass:"subHeader alignLeft",class:{
                        cornerLeft: index === 0,
                        shadowBlueTop: index === 0,
                        shadowBlueLeft: index !== 0,
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.commune)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.numeroCourt)+" ")]),_c('td',{staticClass:"subHeader pointer",class:{
                        line: item.type === 'suppression',
                    },on:{"click":function($event){return _vm.goto('/#/caaudetail?id=' + item.idCaauNouveau)}}},[_vm._v(" "+_vm._s(item.identifiantCaauNouveau)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.dateNouveauACompterDu)+" ")]),_c('td',{staticClass:"subHeader pointer",class:{
                        line: item.type === 'suppression',
                    },on:{"click":function($event){return _vm.goto('/#/caaudetail?id=' + item.idCaauAncien)}}},[_vm._v(" "+_vm._s(item.identifiantCaauAncien)+" ")]),_c('td',{staticClass:"subHeader",class:{
                        line: item.type === 'suppression',
                    }},[_vm._v(" "+_vm._s(item.dateAncienJusquAu)+" ")]),_c('td',[(_vm.isAuthorize && item.type === '')?_c('span',{staticClass:"flex align-center gap-05 texte_rouge pointer font-marianne-light",on:{"click":function($event){return _vm.openPopupAnnulerModifsPdaa(
                                item.idNumeroUrgence,
                                item.idCommune
                            )}}},[_c('img',{attrs:{"src":require("../../assets/img/tnaau/Icon_annuler.svg")}}),_vm._v(" Annuler")]):_vm._e()])])})],2)]):_vm._e(),(this.listePdaau.length == 0)?_c('div',{staticClass:"font-titre-tableau text-align-center"},[_vm._v(" Aucune modification en attente ")]):_vm._e(),_c('PopupConfirmation',{ref:"popupAnnulerModifCaau",attrs:{"titre":'Êtes-vous sûr de vouloir annuler les modifications de ce caau ?'}}),_c('PopupConfirmation',{ref:"popupAnnulerModifPdaa",attrs:{"titre":'Êtes-vous sûr de vouloir annuler les modifications de ce pdaa ?'}}),_c('PopupConfirmation',{ref:"popupAllAnnulerModifCaau",attrs:{"titre":'Êtes-vous sûr de vouloir annuler les modifications de tous les caau ?'}}),_c('PopupConfirmation',{ref:"popupAllAnnulerModifPdaa",attrs:{"titre":'Êtes-vous sûr de vouloir annuler les modifications de tous les pdaa ?'}}),_c('PopupConfirmation',{ref:"popupPublier",attrs:{"titre":'Êtes-vous sûr de vouloir publier les modifications en attente du pdaau ?'}}),_c('PopupAdmin',{ref:"popupPublierAdmin",attrs:{"titre":'Êtes-vous sûr de vouloir publier les modifications en attente du pdaau ?'}}),_c('PopupConfirmation',{ref:"popupPublierSansModification",attrs:{"titre":'Êtes-vous sûr de vouloir publier le pdaau ?'}}),_c('PopupInformation',{ref:"popupInfoPublier",attrs:{"titre":'Le PDAAU a bien été envoyé aux opérateurs.',"message":'Vous pouvez assurer le suivi relatif à sa récupération et intégration par les opérateurs dans la page historique des publications.'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
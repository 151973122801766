export function passwordCheck(password) {
    // XXX: normalise en forme "composée" pour matcher correctement les diacritiques (éèàçù)
    return password
        .normalize('NFKC')
        .match(
            /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*"'()\-_[\]{}§µ£<>,;.:?/\\éèàçù~])(?=\S+$)(?=\P{Cn}+$)(?=\P{Cc}+$).{12,}/u
        )
}

// Renvoie vrai si le résultat ne contient pas d'html
export function isNotHtml(value) {
    const re = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/
    return !re.test(value)
}

// Retourne vrai si le résultat est bien une adresse email
export function emailCheck(email) {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return re.test(email)
}

// Retourne vrai si le résultat n'est pas null ou vide avec des espaces (si string)
export function notNullCheck(value) {
    return (
        value &&
        value !== null &&
        (typeof value !== 'string' ||
            (value.trim().length > 0 && isNotHtml(value)))
    )
}

export function codePostalCheck(code) {
    // Retourne un résultat si le code postal contient entre 3 et 5 chiffres
    // (Permet d'inclure les DOM/TOM)
    return code.match(/^[0-9]{3,5}$/g)
}

export function checkNumeroTel(numero, codeDepartement) {
    if (numero && codeDepartement) {
        switch (codeDepartement) {
            case '971':
                // Guadeloupe
                return /^\+590\d{9}$/.test(numero)
            case '972':
                // Martinique
                return /^\+596\d{9}$/.test(numero)
            case '973':
                // Guyane
                return /^\+594\d{9}$/.test(numero)
            case '974':
                // La réunion
                return /^\+262\d{9}$/.test(numero)
            case '975':
                // Saint-Pierre-et-Miquelon
                return /^\+508\d{6}$/.test(numero)
            case '976':
                // Mayotte
                return /^\+262\d{9}$/.test(numero)
            case '98':
                //Monaco
                return /^(?:\+377|00377)?[0-9]{8}$/.test(numero)
            default:
                return (
                    // France métropolitaine
                    /^\+33\d{9}$/.test(numero) ||
                    // SMS
                    numero === '114'
                )
        }
    } else {
        return false
    }
}

export function checkInput(input, isValide) {
    if (isValide) {
        input.classList.remove('input_red')
        return true
    } else {
        input.classList.add('input_red')
        return false
    }
}

export function debounce(func, timeout = 500) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

<template>
    <!-- CONTENT -->
    <div class="container">
        <TitreReference :reference="'Nouveau'" :texte="'Compte'" />
        <div class="flex-column padding-top-100-px">
            <div class="formInputField">
                <div>
                    <div class="font-label-input padding-bottom-10-px">Nom</div>
                    <input
                        class="inputForm"
                        type="texte"
                        v-model="nom"
                        ref="inputNom" />
                </div>
            </div>
            <div class="formInputField">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Email de connexion
                    </div>
                    <input
                        class="inputForm"
                        type="email"
                        v-model="email"
                        ref="inputEmail" />
                </div>
            </div>
            <ProfilSelector class="flex-column formfield" ref="profil" />
            <StructureSelector
                class="flex-column formfield"
                ref="structure"
                v-show="isDirectionMetier" />
            <div class="formInputField" v-show="isPrefectureModif && isAdmin">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Contact téléphonique
                    </div>
                    <input
                        class="inputForm"
                        type="text"
                        v-model="contactTel"
                        ref="contactTel" />
                </div>
            </div>
            <div class="formInputField" v-show="isPrefectureModif && isAdmin">
                <div>
                    <div class="font-label-input padding-bottom-10-px">
                        Commentaire
                    </div>
                    <textarea
                        class="textAreaForm"
                        type="text"
                        v-model="commentaire"
                        ref="commentaire"
                        cols="50"
                        rows="5" />
                </div>
            </div>
        </div>
        <WidgetAccesDepartement
            ref="widgetDepartement"
            :isAllSelection="true" />
        <switch-button
            :texte="'Afficher dans la liste des départements : France entière'"
            :color="'#00ACA8'"
            ref="switchFranceEnable"
            class="ficheSwitchSpacer formfield"
            v-show="this.btnDroitFranceEntiereVisible" />
        <div class="flex">
            <button
                class="
                    bouton
                    background_page
                    texte_bleu_fonce
                    no-border
                    margin-bottom-10-px margin-left-30-px
                "
                @click="annuler">
                Annuler
            </button>
            <button
                class="
                    bouton
                    background_bleu_tres_fonce
                    texte_blanc
                    no-border
                    margin-left-20-px
                "
                @click="creer">
                <img
                    src="../../assets/img/tnaau/Icon_check_mark.svg"
                    class="margin-right-10-px" />
                Valider la création
            </button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import {
    emailCheck,
    notNullCheck,
    checkInput,
    isNotHtml,
} from '@/tools/inputCheck'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import ProfilSelector from '@/components/widgets/profilSelector/ProfilSelector'
import StructureSelector from '@/components/widgets/structureSelector/StructureSelector'
import SwitchButton from '@/components/widgets/switchButton/SwitchButton'
import WidgetAccesDepartement from '@/components/widgets/widgetAccesDepartement/WidgetAccesDepartement'
import { notificationLogout } from '../../tools/authent'
import Roles from '../../tools/Roles'

export default {
    name: 'CreationCompte',
    components: {
        TitreReference,
        ProfilSelector,
        StructureSelector,
        WidgetAccesDepartement,
        SwitchButton,
    },
    data() {
        return {
            nom: '',
            email: '',
            structure: 0,
            commentaire: '',
            contactTel: '',
            profil: 0,
            departementsUtilisateur: [],
            isDirectionMetier: false,
            isPrefectureModif: false,
            btnDroitFranceEntiereVisible: false,
        }
    },
    methods: {
        creer() {
            //Vérification des champs
            let formulaireValid = true
            this.departementsUtilisateur =
                this.$refs.widgetDepartement.checkedDepartement
            this.profil = this.$refs.profil.selected
            this.structure = this.$refs.structure.selected

            if (this.departementsUtilisateur.length == 0) {
                formulaireValid = false
                this.$notify({
                    group: 'general-notification',
                    title: 'Pas de département sélectionné',
                    text: "Merci d'autoriser au moins un département",
                    type: 'warn',
                    duration: 5000,
                })
            }
            let checkEmail = checkInput(
                this.$refs.inputEmail,
                emailCheck(this.email)
            )
            let checkNom = checkInput(
                this.$refs.inputNom,
                notNullCheck(this.nom)
            )

            let checkPhone = checkInput(
                this.$refs.contactTel,
                !this.contactTel || isNotHtml(this.contactTel)
            )
            let checkCommentaire = checkInput(
                this.$refs.commentaire,
                isNotHtml(this.commentaire)
            )

            let checkStructure = this.isDirectionMetier
                ? checkInput(
                      this.$refs.structure.$el.lastChild,
                      notNullCheck(this.structure)
                  )
                : true
            let checkProfil = checkInput(
                this.$refs.profil.$el.lastChild,
                notNullCheck(this.profil)
            )

            if (
                formulaireValid &&
                checkEmail &&
                checkNom &&
                checkCommentaire &&
                checkPhone &&
                checkStructure &&
                checkProfil
            ) {
                axios
                    .post(
                        '/utilisateur',
                        {
                            nom: this.nom,
                            email: this.email,
                            idStructure: this.structure,
                            commentaire: this.commentaire,
                            contactTel: this.contactTel,
                            idProfil: this.profil,
                            droitFranceEntiere:
                                this.$refs.switchFranceEnable.status,
                            departementsAcces: this.departementsUtilisateur,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization:
                                    'Bearer ' + this.$store.state.user.token,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                group: 'general-notification',
                                title:
                                    'Le compte de ' +
                                    this.nom +
                                    ' a bien été créé',
                                text:
                                    'Un email lui a été adressé à ' +
                                    this.email,
                                type: 'success',
                                duration: 5000,
                            })
                            window.location.href = '/#/comptes'
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: response.status,
                                text: response.statusText,
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (
                            e.response.status === 403 ||
                            e.response.status === 400
                        ) {
                            var msgError =
                                "Vous n'avez pas l'autorisation de créer un utilisateur"
                            if (e.response.data !== undefined) {
                                msgError = e.response.data.message
                            }
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: msgError,
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        annuler() {
            window.location.href = '/#/comptes'
        },
    },
    mounted() {
        EventBus.$on(eventTypes.SHOW_STRUCTURE, (args) => {
            this.isDirectionMetier = args
            this.structure = null
            this.$refs.structure.selected = this.structure
        })
        EventBus.$on(eventTypes.SHOW_PREFECTURE_MODIF, (args) => {
            this.isPrefectureModif = args
            this.commentaire = null
            this.contactTel = null
        })
        EventBus.$on(eventTypes.UPDATE_BTN_FRANCE_ENTIERE, (args) => {
            if (
                args === Roles.GESTIONNAIRE_OCE ||
                args === Roles.DIRECTION_METIER ||
                args === Roles.ADMINISTRATEUR
            ) {
                this.$refs.switchFranceEnable.status = true
                this.btnDroitFranceEntiereVisible = true
            } else {
                this.$refs.switchFranceEnable.status = false
                this.btnDroitFranceEntiereVisible = false
            }
        })
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isAdmin() {
            return this.$store.state.user.profil === Roles.ADMINISTRATEUR
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.SHOW_STRUCTURE)
        EventBus.$off(eventTypes.SHOW_PREFECTURE_MODIF)
        EventBus.$off(eventTypes.UPDATE_BTN_FRANCE_ENTIERE)
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.formInputField {
    margin-left: 15px;
    padding-bottom: 20px;
}
.formfield {
    padding-left: 15px;
    padding-bottom: 20px;
}

.annuler {
    font-family: 'Marianne Regular';
    font-size: 14px;
    color: #000091;
    cursor: pointer;
}

.bouton {
    margin-left: 10px;
    width: 200px;
}

.formfield {
    padding-left: 15px;
    padding-bottom: 20px;
}

.ficheSwitchSpacer {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>

<template>
    <section class="section flex-column">
        <div class="inline-flex searchFlex padding-bottom-30-px">
            <SearchFieldUtilisateur
                class="searchField"
                background="#EEF0F6"
                width="315px" />
            <button
                class="
                    arrowLimit
                    inline-flex-center
                    no-select no-border
                    margin-left-20-px
                    pointer
                "
                :class="{ rotate180: true, opaque: this.isOpaqueLeft }"
                v-show="this.usersTotal > this.limit"
                @click="page('previous')">
                <img src="../../../assets/img/tnaau/Icon_circle_button.svg" />
            </button>
            <div
                class="font-marianne-thin inline-flex-center"
                v-show="this.usersTotal > this.limit">
                {{ this.numeroPage }}/{{ this.nbrPageTotal }}
            </div>
            <button
                class="
                    arrowLimit
                    inline-flex-center
                    no-select no-border
                    pointer
                "
                :class="{ rotate180: false, opaque: this.isOpaqueRight }"
                v-show="this.usersTotal > this.limit"
                @click="page('next')">
                <img src="../../../assets/img/tnaau/Icon_circle_button.svg" />
            </button>
        </div>
        <table>
            <thead>
                <tr>
                    <td
                        class="headerId uppercase pointer colonneWidth"
                        @click="sort('nom')">
                        Nom
                        <img
                            src="../../../assets/img/tnaau/Icon_up_blue.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowNom === 'desc' }"
                            v-if="arrowNom != 'none'" />
                    </td>
                    <td
                        class="
                            headerdata
                            uppercase
                            cornerLeft
                            pointer
                            colonneMail
                        "
                        @click="sort('email')">
                        Email
                        <img
                            src="../../../assets/img/tnaau/Icon_up.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowEmail === 'desc' }"
                            v-if="arrowEmail != 'none'" />
                    </td>
                    <td
                        class="headerdata uppercase pointer colonneWidth"
                        @click="sort('profil')">
                        Profil
                        <img
                            src="../../../assets/img/tnaau/Icon_up.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowProfil === 'desc' }"
                            v-if="arrowProfil != 'none'" />
                    </td>
                    <td
                        class="
                            headerdata
                            uppercase
                            cornerRight
                            pointer
                            colonneWidth
                        "
                        @click="sort('bloquer')">
                        Compte bloqué ?
                        <img
                            src="../../../assets/img/tnaau/Icon_up.svg"
                            class="line_arrow"
                            :class="{ rotate180: arrowBloquer === 'desc' }"
                            v-if="arrowBloquer != 'none'" />
                    </td>
                </tr>
            </thead>
            <tbody class="shadowBlue cornerLeft">
                <tr
                    v-for="(item, index) in this.userslist"
                    :key="item.idUtilisateur">
                    <td
                        class="subHeader colonneWidth pointer"
                        :class="{
                            cornerLeft: index === 0,
                        }"
                        @click="goto(item.idUtilisateur)">
                        {{ item.email }}
                    </td>
                    <td class="subHeader colonneMail">
                        {{ item.nom }}
                    </td>
                    <td class="subHeader colonneWidth">
                        {{ item.profil }}
                    </td>
                    <td class="subHeader colonneWidth">
                        <span v-if="item.bloquer" style="color: red">OUI</span>
                        <span v-else style="color: #8ebf42">NON</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>
<script>
import axios from 'axios'
import * as eventTypes from '../../../bus/event-types.js'
import EventBus from '../../../bus'
import SearchFieldUtilisateur from '@/components/widgets/searchFieldUtilisateur/SearchFieldUtilisateur'
import { notificationLogout } from '../../../tools/authent'
export default {
    name: 'UtilisateurGrid',
    components: { SearchFieldUtilisateur },
    data() {
        return {
            arrowNom: 'asc',
            arrowEmail: 'none',
            arrowProfil: 'none',
            arrowBloquer: 'none',
            userslist: [],
            offset: 0,
            limit: 10,
            usersTotal: 1,
            isOpaqueRight: false,
            isOpaqueLeft: false,
            nbrPageTotal: 0,
            numeroPage: 0,
        }
    },
    mounted() {
        EventBus.$on(eventTypes.LOADED_TABLE_UTILISATEUR, (obj) => {
            this.isOpaqueRight = false
            this.isOpaqueLeft = false
            this.offset = obj.offset
            this.limit = obj.limit

            if (this.offset === 0) {
                this.isOpaqueLeft = true
            } else if (this.offset + this.limit >= this.usersTotal) {
                this.isOpaqueRight = true
            }

            axios
                .get(
                    '/utilisateur?offset=' +
                        obj.offset +
                        '&limit=' +
                        obj.limit +
                        '&filter=' +
                        obj.search +
                        '&sort=' +
                        obj.sort +
                        '&sortorder=' +
                        obj.sortorder,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + obj.tokken,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            this.userslist = response.data.data
                            this.usersTotal = response.data.total
                            this.nbrPageTotal = Math.ceil(
                                this.usersTotal / this.limit
                            )
                            this.numeroPage = this.offset / this.limit + 1
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas les droits nécessaires pour voir les différents comptes.",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        })
    },
    methods: {
        goto(id) {
            window.location.href = '#/comptedetail?id=' + id
        },
        page(value) {
            if (value === 'next') {
                if (!this.isOpaqueRight) {
                    this.offset += 10
                }
            } else if (value === 'previous') {
                if (this.offset >= 10) {
                    this.offset -= 10
                }
            }
            EventBus.$emit(eventTypes.LOADED_TABLE_UTILISATEUR, {
                offset: this.offset,
                limit: this.limit,
                search: this.$store.state.search,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
                tokken: this.$store.state.user.token,
            })
        },
        sort(value) {
            switch (value) {
                case 'nom':
                    if (this.arrowNom === 'asc') {
                        this.arrowNom = 'desc'
                    } else {
                        this.arrowNom = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowNom
                    this.arrowEmail = 'none'
                    this.arrowProfil = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'email':
                    if (this.arrowEmail === 'asc') {
                        this.arrowEmail = 'desc'
                    } else {
                        this.arrowEmail = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowEmail
                    this.arrowNom = 'none'
                    this.arrowProfil = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'profil':
                    if (this.arrowProfil === 'asc') {
                        this.arrowProfil = 'desc'
                    } else {
                        this.arrowProfil = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowProfil
                    this.arrowNom = 'none'
                    this.arrowEmail = 'none'
                    this.arrowBloquer = 'none'
                    break
                case 'bloquer':
                    if (this.arrowBloquer === 'asc') {
                        this.arrowBloquer = 'desc'
                    } else {
                        this.arrowBloquer = 'asc'
                    }
                    this.$store.state.sortorder = this.arrowBloquer
                    this.arrowNom = 'none'
                    this.arrowEmail = 'none'
                    this.arrowProfil = 'none'
                    break
            }
            this.$store.state.sort = value
            this.$store.commit('saveStore')
            EventBus.$emit(eventTypes.LOADED_TABLE_UTILISATEUR, {
                offset: 0,
                limit: 10,
                search: this.$store.state.search,
                sort: this.$store.state.sort,
                sortorder: this.$store.state.sortorder,
                tokken: this.$store.state.user.token,
            })
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.LOADED_TABLE_UTILISATEUR)
    },
}
</script>
<style scoped>
.colonneWidth {
    width: 250px;
}

.colonneMail {
    align-items: flex-start;
    width: 400px;
}
</style>

<template>
    <div id="container">
        <div>
            <select v-model="codeAide" class="selectForm" @change="getAideText">
                <option class="optionForm" value="gouv">Gouvernementale</option>
                <option class="optionForm" value="oce">OCE</option>
            </select>
        </div>
        <wysiwyg v-model="texteAide" />
        <button
            class="
                bouton
                background_rouge
                texte_blanc
                no-border
                exportBouton
                margin-top-20-px
            "
            @click="save">
            Sauvegarder
        </button>
    </div>
</template>

<script>
import wysiwyg from 'vue-wysiwyg'
import Vue from 'vue'
import axios from 'axios'
import { notificationLogout } from '@/tools/authent'

Vue.use(wysiwyg, {
    // { [module]: boolean (set true to hide) }
    hideModules: { code: true, image: true, table: true },
    // limit content height if you wish. If not set, editor size will grow with content.
    maxHeight: '70vh',
})

export default {
    name: 'Aide',
    data() {
        return {
            codeAide: 'gouv',
            texteAide: '',
        }
    },
    mounted() {
        this.getAideText()
    },
    methods: {
        getAideText() {
            axios
                .get('/aides?code=' + this.codeAide, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.texteAide = response.data.texte
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
        save() {
            axios
                .put(
                    '/aides',
                    {
                        code: this.codeAide,
                        texte: this.texteAide,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Modification effectuée avec succés',
                            text: '',
                            type: 'success',
                        })
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
    },
}
</script>

<style scoped>
@import '~vue-wysiwyg/dist/vueWysiwyg.css';

#container {
    margin: 2em;
}

.selectForm {
    margin-bottom: 2em;
    margin-right: 1.5em;
}
</style>

<template>
    <section class="flex">
        <div class="flex" v-show="this.listAcces.length === 0">
            <ButtonWidget
                :small-button="true"
                bgcolor="transparent"
                txtcolor="#000091"
                logo="Icon_cadenas_locked.svg"
                text="Autorisation d'accès aux départements"
                class="bouton--border"
                @click.native="openSelectionDepartement()"
                :enable="true" />
        </div>
        <div class="flex flex-column" v-show="this.listAcces.length > 0">
            <div class="inline-flex">
                <div class="titreAutorisation">
                    <img
                        src="../../../assets/img/tnaau/Icon_cadenas_unlocked.svg" />
                </div>
                <div class="titreAutorisation uppercase">
                    Autorisation d'accès aux départements
                </div>
            </div>
            <div class="inline-flex">
                <div
                    class="etiquetteDepartement"
                    v-for="item in checkedDepartement"
                    :key="item.id">
                    {{ item.codeInsee }}
                </div>
                <div
                    class="titreModifier uppercase pointer"
                    @click="openSelectionDepartement()">
                    <img
                        src="../../../assets/img/tnaau/Icon_cadenas_locked.svg" />Modifier
                    les accès
                </div>
            </div>
        </div>
        <WidgetModal
            :titre="'Autorisation d\'accès aux départements'"
            :icon="'Icon_cadenas_locked_noir.svg'"
            :modalId="'modalId'"
            :listes="this.checkedDepartement">
            <div class="deptListScrollable">
                <div v-if="isAllSelection">
                    <input
                        type="checkbox"
                        id="checkboxAll"
                        class="checkboxTransfo margin-left-25-px"
                        @click="selectionAllDepartements()" />
                    <div class="deptListNom bold padding-left-10-px">
                        Tout sélectionner
                    </div>
                </div>
                <div
                    v-for="(item, index) in this.departements"
                    :key="item.code"
                    class="deptListOption pointer"
                    :style="getBackground"
                    :class="{ deptListOptionEven: (index & 1) === 0 }">
                    <div class="deptListCheck">
                        <input
                            type="checkbox"
                            :id="'deptNum' + item.id"
                            :checked="inListAcces(item.idDepartement)"
                            @click="selectionDepartement(item.idDepartement)" />
                    </div>
                    <div class="deptListCode">{{ item.codeInsee }}</div>
                    <div class="deptListNom">{{ item.nom }}</div>
                </div>
            </div>
        </WidgetModal>
    </section>
</template>

<script>
import axios from 'axios'
import ButtonWidget from '@/components/widgets/buttonWidget/ButtonWidget'
import WidgetModal from '@/components/widgets/widgetModal/WidgetModal'
import * as eventTypes from '../../../bus/event-types.js'
import EventBus from '../../../bus'
import { notificationLogout } from '../../../tools/authent'
import _ from 'lodash'

export default {
    name: 'WidgetAccesDepartement',
    components: { WidgetModal, ButtonWidget },
    data() {
        return {
            listAcces: [],
            visibleModal: false,
            checkedDepartement: [],
            departements: [],
        }
    },
    props: {
        dptCaau: {
            type: Object,
            default: () => {},
        },
        isAllSelection: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.recupereDepartement(false)

        EventBus.$on(eventTypes.SHOW_PROFIL_ADMIN, (args) => {
            this.recupereDepartement(args)
        })
    },
    methods: {
        selectionAllDepartements() {
            if (this.checkedDepartement.length === this.departements.length) {
                this.checkedDepartement = []
            } else {
                this.checkedDepartement = this.departements.map(
                    (item) => item.idDepartement
                )
            }
            EventBus.$emit(
                eventTypes.AFFECTE_DEPARTEMENT,
                this.checkedDepartement,
                this.departements
            )
        },
        recupereDepartement(isAdmin) {
            //Récupère tout les départements
            axios
                .get('/departement', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.departements = response.data.data
                        if (
                            this.dptCaau &&
                            Object.keys(this.dptCaau).length !== 0
                        ) {
                            if (
                                this.departements.some(
                                    (dpt) =>
                                        dpt.idDepartement ===
                                        this.dptCaau.idDepartement
                                )
                            ) {
                                this.departements.splice(
                                    this.departements.findIndex(
                                        (dpt) =>
                                            dpt.idDepartement ===
                                            this.dptCaau.idDepartement
                                    ),
                                    1
                                )
                            }
                        }
                        if (!isAdmin) {
                            if (
                                this.departements.some(
                                    (dpt) => dpt.nom === 'Monaco'
                                )
                            ) {
                                _.remove(
                                    this.departements,
                                    (dpt) => dpt.nom === 'Monaco'
                                )
                                _.remove(
                                    this.checkedDepartement,
                                    (dpt) => dpt.nom === 'Monaco'
                                )
                            }
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 404) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Inconnu',
                            text: "Le compte recherché n'existe pas",
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        //Renvoie la liste des départements sélectionnés
        selectionDepartement(id) {
            if (this.checkedDepartement.includes(id)) {
                let indexDepartements = this.checkedDepartement.indexOf(id)
                this.checkedDepartement.splice(indexDepartements, 1)
            } else {
                this.checkedDepartement.push(id)
            }
            EventBus.$emit(
                eventTypes.AFFECTE_DEPARTEMENT,
                this.checkedDepartement,
                this.departements
            )
        },
        openSelectionDepartement() {
            EventBus.$emit(eventTypes.MODAL_VISIBLE, {
                modalId: 'modalId',
                opened: true,
            })
        },
        inListAcces(num) {
            return this.checkedDepartement.includes(num)
        },
    },
    computed: {
        getBackground() {
            return {
                'background-color': '#FFFFFF',
            }
        },
        getBackgroundPopup() {
            return {
                'background-color': this.background,
            }
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.SHOW_PROFIL_ADMIN)
    },
}
</script>

<style scoped>
.titreAutorisation {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    font-family: 'Marianne Medium';
    font-size: 14px;
    height: 25px;
    margin-right: 5px;
}

.etiquetteDepartement {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Marianne Light';
    font-size: 14px;
    width: 41px;
    height: 41px;
    background: #dfe0ed;
    border-radius: 5px;
    margin: 5px;
}

.cadenasLocked,
.titreModifier {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Marianne Regular';
    color: #000091;
    font-size: 14px;
    height: 41px;
    margin: 5px;
}

.deptListScrollable {
    display: inline-flex;
    flex-direction: column;
    font-family: 'Marianne Light';
    font-size: 14px;
    width: 350px;
    height: 350px;
    overflow-y: scroll;
    padding-right: 20px;
}

.deptListOption {
    display: inline-flex;
    flex-direction: row;
    min-height: 40px;
    font-family: 'Marianne Light';
    font-size: 14px;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding-left: 5px;
}

.deptListOption:hover {
    background: #e1e2ed !important;
}

.deptListOptionEven {
    background: #eeeffa !important;
}

.deptListCheck {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
}

.deptListCode {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    font-family: 'Marianne Light';
    font-size: 14px;
}

.deptListNom {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Marianne Light';
    font-size: 14px;
}
</style>

<template>
    <section>
        <div class="presentation pointer" @click="openClosePopup">
            <img :src="require(`@/assets/img/tnaau/${image}`)" />
            <h2 class="text-align-center">{{ titre }}</h2>
        </div>
        <div class="backgroundModal" v-show="this.modal">
            <div class="windowModal">
                <div class="headerModal">
                    <div class="close-button pointer" @click="openClosePopup">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-' + this.video"
                    preload="auto"
                    controls>
                    <source
                        :src="require(`@/assets/video/${video}.webm`)"
                        type="video/webm" />
                </video>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WidgetVideo',
    data() {
        return {
            modal: false,
        }
    },
    props: {
        image: {
            type: String,
            default: '',
        },
        titre: {
            type: String,
            default: '',
        },
        video: {
            type: String,
            default: '',
        },
    },
    methods: {
        openClosePopup() {
            // Fermeture de la vidéo
            const video = document.querySelector('#id-' + this.video)
            video.pause()
            video.currentTime = 0
            // Fermeture de la fenêtre
            this.modal = !this.modal
        },
    },
}
</script>

<style scoped>
h2 {
    font-family: 'Marianne Thin';
    font-size: 16px;
    color: #14153c;
}

.presentation img {
    width: 212px;
    margin: 0 auto !important;
    display: block !important;
    float: none !important;
}

.presentation h2 {
    width: 250px;
}
.close-button {
    margin-left: auto;
    margin-right: 0;
}
</style>

// eslint-disable-next-line vue/valid-template-root
<template>
    <div class="helpBar">
        <a href="#">
            <img src="@/assets/img/tnaau/Icon_aide_vert.svg" alt="" />
            <span>AIDE</span>
        </a>

        <a href="#" class="bouton--border--vert" @click="openClosePopupAide1">
            <span>Présentation générale de l'application</span>
            <img src="@/assets/img/tnaau/Icon_video.svg" alt="" />
        </a>
        <div class="backgroundModal" v-show="this.modalAide1">
            <div class="windowModal">
                <div class="headerModal">
                    <div
                        class="close-button pointer"
                        @click="openClosePopupAide1">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-Video-1-Presentation-generale'"
                    preload="auto"
                    controls>
                    <source
                        :src="
                            require(`@/assets/video/Video-1-Presentation-generale.webm`)
                        "
                        type="video/webm" />
                </video>
            </div>
        </div>
        <a href="#" class="bouton--border--vert" @click="openClosePopupAide2">
            <span>Comment changer un numéro long ?</span>
            <img src="@/assets/img/tnaau/Icon_video.svg" alt="" />
        </a>
        <div class="backgroundModal" v-show="this.modalAide2">
            <div class="windowModal">
                <div class="headerModal">
                    <div
                        class="close-button pointer"
                        @click="openClosePopupAide2">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-Video-2-Changer-numero-long'"
                    preload="auto"
                    controls>
                    <source
                        :src="
                            require(`@/assets/video/Video-2-Changer-numero-long.webm`)
                        "
                        type="video/webm" />
                </video>
            </div>
        </div>

        <a href="#" class="bouton--border--vert" @click="openClosePopupAide3">
            <span>Comment changer les affectations d'une commune ?</span>
            <img src="@/assets/img/tnaau/Icon_video.svg" alt="" />
        </a>
        <div class="backgroundModal" v-show="this.modalAide3">
            <div class="windowModal">
                <div class="headerModal">
                    <div
                        class="close-button pointer"
                        @click="openClosePopupAide3">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-Video-3-Changer-affectation-commune'"
                    preload="auto"
                    controls>
                    <source
                        :src="
                            require(`@/assets/video/Video-3-Changer-affectation-commune.webm`)
                        "
                        type="video/webm" />
                </video>
            </div>
        </div>

        <a href="#" class="bouton--border--vert" @click="openClosePopupAide4">
            <span>Comment publier et envoyer un PDAU ?</span>
            <img src="@/assets/img/tnaau/Icon_video.svg" alt="" />
        </a>
        <div class="backgroundModal" v-show="this.modalAide4">
            <div class="windowModal">
                <div class="headerModal">
                    <div
                        class="close-button pointer"
                        @click="openClosePopupAide4">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-Video-4-Publier-et-envoyer-un-PDAU'"
                    preload="auto"
                    controls>
                    <source
                        :src="
                            require(`@/assets/video/Video-4-Publier-et-envoyer-un-PDAU.webm`)
                        "
                        type="video/webm" />
                </video>
            </div>
        </div>

        <a href="#" class="bouton--border--vert" @click="openClosePopupAide5">
            <span>Le suivi des publications (mécanisme de relance)</span>
            <img src="@/assets/img/tnaau/Icon_video.svg" alt="" />
        </a>
        <div class="backgroundModal" v-show="this.modalAide5">
            <div class="windowModal">
                <div class="headerModal">
                    <div
                        class="close-button pointer"
                        @click="openClosePopupAide5">
                        X
                    </div>
                </div>
                <video
                    width="800"
                    v-bind:id="'id-Video-5-Suivi-des-publications'"
                    preload="auto"
                    controls>
                    <source
                        :src="
                            require(`@/assets/video/Video-5-Suivi-des-publications.webm`)
                        "
                        type="video/webm" />
                </video>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'widgetAide',
    data() {
        return {
            modalAide1: false,
            modalAide2: false,
            modalAide3: false,
            modalAide4: false,
            modalAide5: false,
        }
    },
    methods: {
        openClosePopupAide1() {
            // Fermeture de la vidéo
            const video = document.querySelector(
                '#id-Video-1-Presentation-generale'
            )
            video.pause()
            video.currentTime = 0
            // Fermeture de la fenêtre
            this.modalAide1 = !this.modalAide1
        },
        openClosePopupAide2() {
            // Fermeture de la vidéo
            const video = document.querySelector(
                '#id-Video-2-Changer-numero-long'
            )
            video.pause()
            video.currentTime = 0
            // Fermeture de la fenêtre
            this.modalAide2 = !this.modalAide2
        },
        openClosePopupAide3() {
            const video = document.querySelector(
                '#id-Video-3-Changer-affectation-commune'
            )
            video.pause()
            video.currentTime = 0
            this.modalAide3 = !this.modalAide3
        },
        openClosePopupAide4() {
            const video = document.querySelector(
                '#id-Video-4-Publier-et-envoyer-un-PDAU'
            )
            video.pause()
            video.currentTime = 0
            this.modalAide4 = !this.modalAide4
        },
        openClosePopupAide5() {
            const video = document.querySelector(
                '#id-Video-5-Suivi-des-publications'
            )
            video.pause()
            video.currentTime = 0
            this.modalAide5 = !this.modalAide5
        },
    },
}
</script>
<style scoped>
.close-button {
    margin-left: auto;
    margin-right: 0;
    color: #000;
}
</style>

<template>
    <section class="sectionColumn">
        <select
            v-model="vmodel"
            :style="selectWidth"
            class="selectForm"
            @change="onChangeSelect($event.target.value)">
            <option
                v-for="option in this.listeAffiche"
                :key="option.id"
                :value="option.id">
                {{ option.nom }}
            </option>
        </select>
    </section>
</template>

<script>
import * as eventTypes from '@/bus/event-types'
import EventBus from '@/bus'
export default {
    name: 'SelectForm',
    data() {
        return {
            vmodel: '',
        }
    },
    props: {
        nomVarSelect: {
            type: String,
            default: '',
        },
        width: {
            type: Number,
            default: 20,
        },
        listeAffiche: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onChangeSelect(idItem) {
            EventBus.$emit(eventTypes.CREATION_IDENTIFIANT_CAAU, {
                nomSelect: this.nomVarSelect,
                idItem: idItem,
            })
        },
    },
    computed: {
        selectWidth() {
            return {
                width: this.width + 'px',
            }
        },
    },
}
</script>

<style scoped></style>

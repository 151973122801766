<template>
    <section>
        <button
            class="bouton background_rouge texte_blanc no-border"
            @click="goto">
            <img
                src="../../../assets/img/tnaau/Icon_plus.svg"
                class="margin-right-10-px" />
            {{ this.nom }}
        </button>
    </section>
</template>
<script>
export default {
    name: 'ButtonCreate',
    data() {
        return {}
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        nom: {
            type: String,
            default: '',
        },
    },
    methods: {
        goto() {
            window.location.href = this.url
        },
    },
}
</script>
<style scoped>
.bouton {
    width: 220px;
}
</style>

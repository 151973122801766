var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-400px flex flex-column gap-1"},[(this.smallButton)?_c('div',{staticClass:"searchFields"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('autocomplete',{staticClass:"inputSearch",attrs:{"input-attrs":{
                    autofocus: true,
                    placeholder: 'Recherche',
                }},on:{"change":_vm.search}})],1),(_vm.isHelp)?_c('help-widget',{staticClass:"help-margin",attrs:{"texte":'Vous pouvez effectuer une recherche sur l\'identifiant CAAU, le numéro court, <br/> le numéro long actuel ou ancien'}}):_vm._e()],1):_vm._e(),(!this.smallButton)?_c('div',{staticClass:"searchFields big"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('autocomplete',{staticClass:"inputSearchBig",attrs:{"items":this.dataArray,"item-text":"identifiantCaau","item-value":"idCaau","return-object":"","input-attrs":{
                    autofocus: true,
                    placeholder: 'ID du centre, N° court ou N° long',
                },"component-item":_vm.template,"auto-select-one-item":false},on:{"item-clicked":function($event){return _vm.goto()},"change":_vm.search},model:{value:(_vm.selectedCaau),callback:function ($$v) {_vm.selectedCaau=$$v},expression:"selectedCaau"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }